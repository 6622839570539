import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { i18n } from './i18nReducers'
import { alerts } from './alertReducers'
import { portal, feedbackModal } from './portalReducers'
import { dealer, dealerSkin } from './dealerReducers'
import { captcha } from './captchaReducers'
import { confirm } from './confirmReducers'
import { oidc, oidcUser, oidcAuthStatus } from './oidcReducers'
import { subscriptionInfo } from './subscriptionReducers'
import { credentials, newCredential, credentialDetails } from './credentialReducers'
import { actionTokens, setPasswordTokenForm } from './tokenReducers'
import { readers, readerDetails, readerEnrollmentForm } from './readersReducers'
import { organizations, createOrg, orgDetails } from './organizationsReducers'
import { admins, createAdmin, adminDetails, inviteDetails } from './adminReducers'
import { authenticatedUser, loginForm, forgotForm, identity } from './authenticationReducers'
import { adminRegisterForm, dealerRegisterForm, verifyEmailForm } from './registerReducers'
import { changePasswordForm, addPasswordModal } from './userReducers'
import { portalActions } from '../actions';

export const createRootReducer = (history: History) => {
  const allReducers = combineReducers({
    i18n,
    oidc,
    alerts,
    portal,
    admins,
    dealer,
    readers,
    confirm,
    captcha,
    identity,
    oidcUser,
    createOrg,
    loginForm,
    dealerSkin,
    orgDetails,
    forgotForm,
    credentials,
    createAdmin,
    actionTokens,
    adminDetails,
    readerDetails,
    organizations,
    newCredential,
    feedbackModal,
    inviteDetails,
    oidcAuthStatus,
    verifyEmailForm,
    subscriptionInfo,
    addPasswordModal,
    authenticatedUser,
    credentialDetails,
    adminRegisterForm,
    dealerRegisterForm,
    changePasswordForm,
    setPasswordTokenForm,
    readerEnrollmentForm,
    router: connectRouter(history)
  });

  // Returning this root reducer allows us to intercept actions
  // before they get into the individual reducers/
  // We use this so we can reset the entire redux state on logout 
  // form a single place without needing to reset in each reducer 
  return (state: any, action: any) => {
    if (action.type === portalActions.CLEAR_APPLICATION_STATE) {
      state = undefined;
    }
    return allReducers(state, action);
  }
}
