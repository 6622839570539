import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  decodeJwt,
  EMessageType,
  IUniToast_Alert,
  IdentityAccountT,
} from '@unikey/unikey-commons/release/identity';

import {
  portalRedirect,
  requestRefreshToken,
  oidcRefreshSuccess,
  logoutKey,
  addAlert,
  IConfirmationActionParams,
  SplashContainer,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig, ENavPages,
  checkJWTExpired,
  redirectToLogin,
  appInsights,
  attemptVerifyAccountWithToken,
} from '../internal';

enum EActionTokenTypes {
  Unknown,
  PasswordReset = 'PasswordReset',
  PasswordCreate = 'PasswordCreate',
  PasswordChange = 'PasswordChange',
  AccountVerification = 'AccountVerification'
}

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  accessTokenExists?: boolean,
  refreshSuccess(refreshedUserToken: any): void,
  addAlert(config: IUniToast_Alert): void,
  redirectToLoginWithContinue(): void,
  attemptVerifyEmail(params: IConfirmationActionParams ): Promise<void>
}

class ActionTokenContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // here also for the refresh case.
    return this._checkToken();
  }

  async _checkToken() {
    const token = this.props.match?.params?.actionToken;
    if (token) {
      const decoded: any = decodeJwt(token);
      // if decode returned null, then the token is not a valid jwt
      if (decoded != null) {
        if (!checkJWTExpired(decoded, this.props.addAlert)) {
          // Depending on the token type, we'll need to handle it differently 
          // and make the appropriate calls depending on the desired token-action
          switch (decoded.pur) {
            case EActionTokenTypes.PasswordCreate:
            case EActionTokenTypes.PasswordChange:
            case EActionTokenTypes.PasswordReset:
              portalRedirect(navConfig.get(ENavPages.setPassword)!.linkTo([token]));
              break;
            case EActionTokenTypes.AccountVerification:
              await this.props.attemptVerifyEmail({ token });
              // after verify, refresh token and fetch user for setting new permissions
              await requestRefreshToken(true, this.props.refreshSuccess);

              // if we're still on this page after the refresh logic,
              // then send the user to the portal landing page
              if (/\/#\/token/.test(window.location.href)) {
                portalRedirect(navConfig.get(ENavPages.portalLanding)!.linkTo([]));
              }
              
              break;
            default:
              this.props.addAlert({
                id: Date.now(),
                type: EMessageType.warn,
                titleKey: 'unsupportedAction',
                messageKeys: [decoded.pur]
              })

              appInsights.trackEvent({ name: 'unsupported_jwt' }, { data: token });
              break;
          }
        }
      } 
    } else {
        appInsights.trackEvent({ name: 'could_not_decode' }, { data: token });
        portalRedirect(logoutKey);
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
        <section className='access-container'>
          <SplashContainer 
            match={this.props.match}
            history={this.props.history} />
        </section>
    )
  }
}

function mapStateToProps(state: any) {
  // current user here may be either UserT or the parsed jwt vals
  const authUser = state.authenticatedUser?.currentUser;
  return {
    accessTokenExists: !!authUser,
    confirmationLoading: state.actionTokens.loading,
    confirmationData: state.actionTokens.data,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  addAlert,
  redirectToLoginWithContinue: redirectToLogin,
  attemptVerifyEmail: attemptVerifyAccountWithToken,
  refreshSuccess: oidcRefreshSuccess,
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(ActionTokenContainer)
), { componentName: 'ActionToken', unauthenticated: true })
