export * from './devLogger'
export * from './jwtHelpers'
export * from './oidcHelpers'
export * from './tableHelpers'
export * from './reduxHelpers'
export * from './redirectHelpers'
export * from './reduxApiActions'
export * from './countriesHelpers'
export * from './permissionHelpers'
export * from './subscriptionHelpers'

export const noop = (...arg: any): void => { return; };

export const calcLoaderPercentageComplete = (outstanding: number, total: number) => {
    // (remaining / total) * 100
    // make sure that we're not dividing by zero
    return ((total - outstanding) / (total + 0.0001)) * 100;
}

export const chunk = (arrayToChunk: any[], chunkSize: number = 10): any[][] => {
    const chunked: any[][] = [];
    let index = 0;
    while (index < arrayToChunk.length) {
        chunked.push(arrayToChunk.slice(index, chunkSize + index));
        index += chunkSize;
    }
    return chunked;
}

export const getExtensionFromFileName = (fileName :string) => {
    return fileName?.split('.')?.pop() ?? 'unknown';
}

// regex for serial number validation -- empty is ok -- any number of serial numbers separated by spaces or commas is ok too 
export const serialNumberV10n = new Map([['regex', { regCheck: /(^$|^[\d\w]{10}([,?\s*]+([\d\w]{10})*)*$)/, messageKey: 'Must be one or more 10-digit serial numbers, each separated by a space or comma' }]]);

export const firstTruthyOf = (...args: any[]): any => {
    var firstTruthy;
    args.some((val: any) => {
        if (val) {
            firstTruthy = val;
            return true;
        }
        return false;
    });
    return firstTruthy;
}