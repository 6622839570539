import { Dispatch } from 'redux'

import {
  DealerC,
  IUniToast_Alert,
  IMultiInputUpdate
} from '@unikey/unikey-commons/release/comm'

import {
  mockAuth,
  loginKey,
  logoutKey,
  portalRedirect,
  api,
  attemptRetrieveDealerDetails,
} from '../../internal'

export enum portalActions {

  ADD_ALERT = 'CREATE_ALERT',
  DISMISS_ALERT = 'DISMISS_ALERT',
  DISMISS_ALL_ALERTS = 'DISMISS_ALL_ALERT',
  SHOW_ALL_ALERTS = 'SHOW_ALL_ALERTS',
  HIDE_ALL_ALERTS = 'HIDE_ALL_ALERTS',
  WIPE_ALL_ALERTS = 'WIPE_ALL_ALERTS',
  CAPTCHA_CHANGE = 'CAPTCHA_CHANGE',
  TOGGLE_AVATAR_MENU = 'TOGGLE_AVATAR_MENU',
  TOGGLE_DEALER_SWITCHER = 'TOGGLE_DEALER_SWITCHER',
  UPDATE_LOCALE = 'UPDATE_LOCALE',
  UPDATE_LOCALE_INPUT = 'UPDATE_LOCALE_INPUT',
  NAVIGATION_CHANGE = 'NAVIGATION_CHANGE',
  CLEAR_APPLICATION_STATE = 'CLEAR_APPLICATION_STATE',
  SET_DARK_MODE_THEME = 'SET_DARK_MODE_THEME',
  TOGGLE_FEEDBACK_MODAL = 'TOGGLE_FEEDBACK_MODAL',
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  RESET_FEEDBACK = 'RESET_FEEDBACK',
  DEALER_CONTEXT_SET = 'DEALER_CONTEXT_SET'
  
}

export function setDarkModeTheme(theme: string) {
  return {
    type: portalActions.SET_DARK_MODE_THEME,
    theme
  }
}

export function addAlert(alertInfo: IUniToast_Alert) {
  return {
    type: portalActions.ADD_ALERT,
    alert: alertInfo,
  }
}

export function showAllAlerts() {
  return {
    type: portalActions.SHOW_ALL_ALERTS
  }
}

export function hideAllAlerts() {
  return {
    type: portalActions.HIDE_ALL_ALERTS
  }
}

export function dismissAllAlerts() {
  return {
    type: portalActions.DISMISS_ALL_ALERTS,
  }
}

export function dismissAlert(clearFromCache: boolean, alertId: number) {
  return {
    type: portalActions.DISMISS_ALERT,
    alertId,
    clearFromCache
  }
}

export function wipeAllAlerts() {
  return {
    type: portalActions.WIPE_ALL_ALERTS
  }
}

export function toggleFeedbackModal() {
  return {
    type: portalActions.TOGGLE_FEEDBACK_MODAL
  }
}

export function updateFeedback(feedback: IMultiInputUpdate) {
  return {
    type: portalActions.UPDATE_FEEDBACK,
    feedback
  }
}

export function resetFeedbackForm() {
  return {
    type: portalActions.RESET_FEEDBACK,
  }
}


export function updateCaptcha(captchaValue: string | null) {
  return {
    type: portalActions.CAPTCHA_CHANGE,
    value: captchaValue
  }
}

export function languageChange(localeCode: string) {
  return {
    type: portalActions.UPDATE_LOCALE,
    localeCode
  }
}

export function localeInputChange(localeInput: string) {
  return {
    type: portalActions.UPDATE_LOCALE_INPUT,
    localeInput
  }
}

export function navChange(newPath: string) {
  return {
    type: portalActions.NAVIGATION_CHANGE,
    newPath
  }
}

export function toggleAvatarMenu(open?: boolean) {
  return {
    type: portalActions.TOGGLE_AVATAR_MENU,
    open
  }
}

export function toggleDealerSwitcher(open?: boolean) {
  return {
    type: portalActions.TOGGLE_DEALER_SWITCHER,
    open
  }
}

export function setActivePortalDealer(dealer: DealerC, changingDealers: boolean = false) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    api.setDealer(dealer);
    dispatch(attemptRetrieveDealerDetails({ dealerId: dealer.id }));
    dispatch({
      type: portalActions.DEALER_CONTEXT_SET,
      dealer
    })
    if (changingDealers) {
      portalRedirect({
        pathname: '/splash'
      });
    }
  };
}


export function clearApplicationState() {
  return {
    type: portalActions.CLEAR_APPLICATION_STATE
  }
}

interface IRedirectOptions {
  preventContinue: boolean
}

export function redirectToLogin(options?: IRedirectOptions) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    if (!mockAuth) {
      // encode the desired path (not the leading #)
      const continuePath = encodeURIComponent(location.hash.slice(1));
      if (continuePath.indexOf('login') === -1 && continuePath.indexOf('logout') === -1) {
        api.clearAuthValues();
        dispatch(clearApplicationState());
        // prevent in instance where we dont want to use, ex: redirect to login after verify email
        if (options?.preventContinue) {
          portalRedirect({
            pathname: loginKey
          });
        } else {
          portalRedirect({
            pathname: loginKey,
            search: `?continue=${continuePath}`
          });
        }
      } else {
        portalRedirect({
          pathname: loginKey,
          search: ''
        });
      }
    }
  }
}


export function redirectToLogout(preventContinue: boolean = false) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    // encode the desired path (not the leading #)
    const continuePath = encodeURIComponent(location.hash.slice(1));
    if (continuePath.indexOf('login') === -1 && continuePath.indexOf('logout') === -1) {
      dispatch(clearApplicationState());
      portalRedirect({
        pathname: logoutKey,
        search: preventContinue ? '' : `?continue=${continuePath}`
      });
    }
  }
}