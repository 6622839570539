import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import {
  InviteC,
} from '@unikey/unikey-commons/release/comm';

import {
  attemptRetrieveAdminInviteByToken,
  PartnerCustomizations, IPartnerCustomizations,
  portalRedirect,
  ENavPages, navConfig,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  getInvitationDetails(token: string): Promise<InviteC>,
}

class AdminInvitationTokenParserContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    return this.props.getInvitationDetails(decodeURIComponent(this.props.match.params.inviteToken))
      .then((inviteDetails: InviteC) => {
        // newer invites are marked with `usernameRestricted: false` and can be redeemed by any account.
        if (inviteDetails.usernameRestricted) {
          portalRedirect(navConfig.get(ENavPages.adminInviteRegister)!.linkTo([this.props.match.params.inviteToken]));
        } else {
          portalRedirect(navConfig.get(ENavPages.adminInviteAccept)!.linkTo([this.props.match.params.inviteToken]));
        }
      })
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    return (
      <section className='access-container' />
    )
  }
}

function mapStateToProps(state: any) {
  return {

  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getInvitationDetails: attemptRetrieveAdminInviteByToken,
}, dispatch);


export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(AdminInvitationTokenParserContainer, { forwardRef: true })
), { componentName: 'AdminInvitationTokenParser', unauthenticated: true })