import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classNames from 'classnames'
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  darkThemeName,
  UniImg,
  UniInput,
  UniSteps,
  UniWorkflow,
  UniFileUpload,
  UniColorPicker,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniSelect,
  UniConditionalRender,
  InviteC,
  AuthUserC,
  EInviteStatusC,
  IUniSteps_StepConfig,
  IUniSelect_Option,
  Editable,
  IMultiInputUpdate,
  S10nModelC,
  ES10nModelType,
  emailV10n, notBlankV10n, commPasswordRequirementsV10n, passwordMatchV10n,
} from '@unikey/unikey-commons/release/comm';

import {
  FooterContainer,
  countriesList,

  updateCaptcha,
  changeRegistrationStepIndex,
  updateDealerRegistrationForm,
  attemptRegisterDealerAsAuthenticatedOwner,
  attemptRetrieveAvailableSubscriptionModels,
  PartnerCustomizations,
  IPartnerCustomizations,
  captchaSiteKey, 
  environment, partnerKey,
  portalRedirect, ENavPages, navConfig,
} from '../internal';

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  authUser: AuthUserC,
  canUserCreateDealer: boolean,

  dealerName: Editable,
  country: Editable,
  administrativeAreaName: Editable,
  city: Editable,
  streetAddress1: Editable,
  streetAddress2: Editable,
  zipCode: Editable,
  phone: Editable,
  
  dealerSkinningOffered: boolean,
  logo: any,
  logoOnDark: any,
  primaryColor: Editable,
  secondaryColor: Editable,

  subscriptionType: Editable<number>,
  availableSubscriptonModels: S10nModelC[]
  defaultSubscription: ES10nModelType,
  temporaryDisallowedSubscriptionModels?: ES10nModelType[],

  loading: boolean,
  inviteData: InviteC,
  validCaptcha: boolean,
  currentStepIndex: number,
  darkModeTheme: string,
  handleCaptchaChange(token: string | null): void,
  handleCreateClick(): Promise<void>,
  handleDealerFormChange(changes: IMultiInputUpdate): void,

  changeWorkflowStep(stepTo: number): void,
  retrieveAvailableSubscriptionModels(): void,
}

class DealerCreateContainer extends Component<IProps> {
  steps: IUniSteps_StepConfig[];
  subscriptionOptions: IUniSelect_Option[];

  constructor(props: IProps) {
    super(props);

    this.steps = [
      { nameKey: 'managementCompany' },
      { nameKey: 'subscriptionModel' },
      { nameKey: 'review' }
    ];

    this.subscriptionOptions = [];
  }

  componentDidMount() {
    this.props.retrieveAvailableSubscriptionModels();

  }

  componentDidUpdate(prevProps: IProps) {
    // if the default subscription changed or
    // if we have the subscription response,
    // but we never set the options, set them here
    if (
      (!prevProps.defaultSubscription && this.props.defaultSubscription) ||
      (this.subscriptionOptions.length === 0 && this.props.defaultSubscription)
    ) {
      this._handleSubscriptionChange(new Editable({ value: this.props.defaultSubscription as number, valid: true }));
      this.subscriptionOptions = this.props.availableSubscriptonModels
        .map((subOpt) => ({ nameKey: S10nModelC.getNameKeyFromModelType(subOpt.type), value: subOpt.type }));
      if (Array.isArray(this.props.temporaryDisallowedSubscriptionModels)) {
        // TODO: this allows us to filter out supportedbackend subscription models. 
        // remove once shared environment brand-specific subcscription models is implemented on the backend
        this.subscriptionOptions = this.subscriptionOptions
          .filter((option: IUniSelect_Option) => {
            // if this option is not in the list of disallowed options, then (return true to) keep it 
            return (this.props.temporaryDisallowedSubscriptionModels ?? []).indexOf(option.value) === -1;
          })
      }
    }
  }

  _isFirstStepValid = (): boolean => {
    return !!this.props.dealerName.valid &&
      !!this.props.country.valid &&
      !!this.props.administrativeAreaName.valid &&
      !!this.props.city.valid &&
      !!this.props.streetAddress1.valid &&
      // !!this.props.streetAddress2.valid &&
      !!this.props.zipCode.valid &&
      !!this.props.phone.valid &&
      !!(this.props.logo.valid || this.props.logo.value === '') &&
      !!(this.props.logoOnDark.valid || this.props.logoOnDark.value === '')  &&
      true;
  }

  _isSecondStepValid = (): boolean => {
    return !!this.props.subscriptionType.valid;
  }

  // step 1
  _handleDealerNameChange = (change: Editable) => this.props.handleDealerFormChange({ dealerName: change });
  _handleCountryChange = (change: Editable) => {
    if (change.value !== '_selectOne') {
      return this.props.handleDealerFormChange({ country: change })
    }
  }
  _handleAdministrativeAreaNameChange = (change: Editable) => this.props.handleDealerFormChange({ administrativeAreaName: change })
  _handleCityChange = (change: Editable) => this.props.handleDealerFormChange({ city: change })
  _handleStreet1Change = (change: Editable) => this.props.handleDealerFormChange({ streetAddress1: change })
  _handleStreet2Change = (change: Editable) => this.props.handleDealerFormChange({ streetAddress2: change })
  _handleZipCodeChange = (change: Editable) => this.props.handleDealerFormChange({ zipCode: change })
  _handlePhoneChange = (change: Editable) => this.props.handleDealerFormChange({ phone: change })
  _handlePrimaryColorChange = (change: Editable) => this.props.handleDealerFormChange({ colorPrimary: change })
  _handleSecondaryColorChange = (change: Editable) => this.props.handleDealerFormChange({ colorSecondary: change })
  _handleLogoChange = (change?: Editable<File | string>) => this.props.handleDealerFormChange({ logo: change })
  _handleDarkModeLogoChange = (change?: Editable<File | string>) => this.props.handleDealerFormChange({ darkModeLogo: change })

  // step 2
  _handleSubscriptionChange = (change: Editable<number>) => {
    return this.props.handleDealerFormChange({ subscriptionType: change });
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const withoutCaptcha = environment === 'development' || environment === 'test';
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    const countryOptions: IUniSelect_Option[] = countriesList.map((c: string): IUniSelect_Option => ({ value: c }));
    // const formValid: boolean = this.props.inviteData.status < 3 && this.props.firstNameValid && this.props.lastNameValid && this.props.passwordValid && this.props.passwordConfirmValid;
    const firstStepValid: boolean = this._isFirstStepValid();
    const secondStepValid: boolean = this._isSecondStepValid();

    return (
      <ScreenClassRender render={(screenClass: string) => (
        <section className='access-container'>
          <section className='dealer-register-container'>

            <UniImg 
              className={imgClassName}
              textKey="partner logo"
              src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />

            <h2 className="welcome-header"><UniLocalize translate="welcome" /> {this.props.authUser?.getFullName?.()}</h2>

            {/* User not allowed to create a dealer */}
            <UniConditionalRender visible={!this.props.canUserCreateDealer}>
              <UniWorkflow
                titleKey="myDealer"
                titleIcon="accountCircle"
                size="wider">
                  <h3><UniLocalize translate="contactAdministrator"/></h3>
                  <p><UniLocalize translate="noDealerAccess"/></p>
                  <p><UniLocalize translate="contactAdminToBeAddedToDealer"/></p>
              </UniWorkflow>
            </UniConditionalRender>

            {/* create dealer section */}
            <UniConditionalRender visible={this.props.canUserCreateDealer}>

              <UniWorkflow
                titleKey="registerNewDealer"
                titleIcon="accountCircle"
                size="wider">

                <UniSteps
                  steps={this.steps}
                  activeStepIndex={this.props.currentStepIndex}
                  allStepsUnlocked={false}
                  handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

                {/* First Step - Management Company */}
                <UniConditionalRender visible={this.props.currentStepIndex === 0}>

                  <UniInput
                    editable={this.props.dealerName}
                    labelKey="managementCompany"
                    placeholderKey="_managementCompanyPlaceholder"
                    handleUpdate={this._handleDealerNameChange}
                    focusOnInitialRender={true}
                    validations={new Map([notBlankV10n])} />
                  <UniSelect
                    value={this.props.country.value}
                    labelKey="country"
                    name="countries-list"
                    options={countryOptions}
                    translateValues={false}
                    handleUpdate={this._handleCountryChange} />
                  <UniInput
                    editable={this.props.administrativeAreaName}
                    labelKey="administrativeArea"
                    placeholderKey="administrativeArea"
                    allowAutocomplete={true}
                    handleUpdate={this._handleAdministrativeAreaNameChange}
                    validations={new Map([notBlankV10n])} />
                  <UniInput
                    editable={this.props.city}
                    labelKey="city"
                    placeholderKey="city"
                    allowAutocomplete={true}
                    handleUpdate={this._handleCityChange}
                    validations={new Map([notBlankV10n])} />
                  <UniInput
                    value={this.props.streetAddress1.value}
                    labelKey="streetAddressL1"
                    placeholderKey="streetAddressL1"
                    allowAutocomplete={true}
                    handleUpdate={this._handleStreet1Change}
                    validations={new Map([notBlankV10n])} />
                  <UniInput
                    editable={this.props.streetAddress2}
                    labelKey="streetAddressL2"
                    placeholderKey="streetAddressL2"
                    allowAutocomplete={true}
                    handleUpdate={this._handleStreet2Change} />
                  <UniInput
                    value={this.props.zipCode.value}
                    labelKey="zipCode"
                    placeholderKey="zipCode"
                    allowAutocomplete={true}
                    handleUpdate={this._handleZipCodeChange}
                    validations={new Map([notBlankV10n])} />
                  <UniInput
                    editable={this.props.phone}
                    labelKey="phoneNumber"
                    placeholderKey="phoneNumber"
                    allowAutocomplete={true}
                    handleUpdate={this._handlePhoneChange}
                    validations={new Map([notBlankV10n])} />


                  {/* only show this section for providing dealer logo and color overrides 
                    * if the partner supports dealer skinning */}
                  <UniConditionalRender visible={this.props.dealerSkinningOffered}>
                    
                    {/* primary color */}
                    <UniColorPicker
                      color={this.props.primaryColor?.value || ''}
                      labelKey="chooseAPrimaryColor"
                      handleChange={this._handlePrimaryColorChange} />
                    
                    {/* secodary color */}
                    <UniColorPicker
                      color={this.props.secondaryColor?.value || ''}
                      labelKey="chooseASecondaryColor"
                      handleChange={this._handleSecondaryColorChange} />
                    
                    {/* logo */}
                    <section className="image-container">
                      <UniFileUpload
                        labelKey="logo"
                        placeholderKey="requiredImage"
                        handleUpdate={this._handleLogoChange} 
                        allowedExtensions={['png', 'jpg', 'jpeg']} />
                      <UniImg
                        src={this.props.logo?.value ? URL.createObjectURL(this.props.logo?.value) : partnerConfig.assets.logoOnBackground} 
                        className="logo-preview" 
                        handleClick={this._handleLogoChange.bind(null, new Editable({ value: '', valid: true }))}
                        textKey="logoPreview" />
                    </section>

                    {/* dark mode logo */}
                    <section className="image-container theme-dark">
                      <UniFileUpload
                        labelKey="darkModeLogo"
                        placeholderKey="optionalImage"
                        allowedExtensions={['png', 'jpg', 'jpeg']} 
                        handleUpdate={this._handleDarkModeLogoChange} />
                      <UniImg
                        src={this.props.logoOnDark?.value ? URL.createObjectURL(this.props.logoOnDark?.value) : partnerConfig.assets.logoOnDark} 
                        className="logo-preview"
                        handleClick={this._handleDarkModeLogoChange.bind(null, new Editable({ value: '', valid: true }))}
                        textKey="darkModeLogoPreview" />
                    </section>

                  </UniConditionalRender>

                  {/* step actions */}
                  <UniOverlapGroup foldEarly={true}>
                    <UniOverlapButton
                      handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                      textKey="goNext"
                      icon="navigateNext"
                      disabled={!firstStepValid}
                      tooltipPosition="right" />
                    <UniOverlapButton
                      handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                      textKey="goPrevious"
                      icon="navigateBefore"
                      secondary={true}
                      disabled={this.props.currentStepIndex === 0}
                      tooltipPosition="right" />
                  </UniOverlapGroup>
                </UniConditionalRender>
                {/* End First Step */}

                {/* Second Step - Subscription Model Setup */}
                <UniConditionalRender visible={this.props.currentStepIndex === 1}>

                  <UniConditionalRender visible={this.subscriptionOptions.length === 1}>
                    <p><UniLocalize translate="_preChosenSubscriptionModelExplination" /></p>
                  </UniConditionalRender>

                  <UniConditionalRender visible={this.subscriptionOptions.length > 1}>
                    <p><UniLocalize translate="_selectSubscriptionModelExplination" /></p>

                    <UniSelect
                      value={this.props.subscriptionType.value as number}
                      labelKey="subscriptionModel"
                      name="subscriptionModel"
                      options={this.subscriptionOptions}
                      disabled={this.subscriptionOptions.length === 1}
                      translateValues={true}
                      handleUpdate={this._handleSubscriptionChange} />
                  </UniConditionalRender>

                  <UniConditionalRender visible={Number(this.props.subscriptionType.value) === ES10nModelType.credential}>
                    <p><strong><UniLocalize translate="credentialS10nName" /></strong> - <UniLocalize translate="_credentialS10nNameExplanation" /></p>
                  </UniConditionalRender>

                  <UniConditionalRender visible={Number(this.props.subscriptionType.value) === ES10nModelType.credAndUser}>
                    <p><strong><UniLocalize translate="credAndUserS10nName" /></strong> - <UniLocalize translate="_credAndUserS10nNameExplanation" /></p>
                  </UniConditionalRender>

                  <UniConditionalRender visible={Number(this.props.subscriptionType.value) === ES10nModelType.reader}>
                    <p><strong><UniLocalize translate="readerS10nName" /></strong> - <UniLocalize translate="_readerS10nNameExplanation" /></p>
                  </UniConditionalRender>

                  {/* step actions */}
                  <UniOverlapGroup foldEarly={true}>
                    <UniOverlapButton
                      handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                      textKey="goNext"
                      icon="navigateNext"
                      disabled={!secondStepValid}
                      tooltipPosition="right" />
                    <UniOverlapButton
                      handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                      textKey="goPrevious"
                      icon="navigateBefore"
                      secondary={true}
                      disabled={this.props.currentStepIndex === 0}
                      tooltipPosition="right" />
                  </UniOverlapGroup>
                </UniConditionalRender>
                {/* End Second Step */}

                {/* Third Step - Review Setup */}
                <UniConditionalRender visible={this.props.currentStepIndex === 2}>
                  <Container>
                    <br/>
                    <Row nogutter>
                      <Col md={4}><strong><UniLocalize translate="dealerName" /></strong></Col>
                      <Col>{this.props.dealerName.value}</Col>
                    </Row>
                    <Row nogutter>
                      <Col md={4}><strong><UniLocalize translate="address" /></strong></Col>
                      <Col>{`${this.props.streetAddress1.value}${this.props.streetAddress2.value ? ' | ' + this.props.streetAddress2.value : ''}`}</Col>
                    </Row>
                    <Row nogutter>
                      <Col md={4}><strong><UniLocalize translate="zipCode" /></strong></Col>
                      <Col>{this.props.zipCode.value}</Col>
                    </Row>
                    <Row nogutter>
                      <Col md={4}><strong><UniLocalize translate="phoneNumber" /></strong></Col>
                      <Col>{this.props.phone.value}</Col>
                    </Row>
                    <br/>
                    <Row nogutter>
                      <Col md={4}><strong><UniLocalize translate="subscriptionModel" /></strong></Col>
                      <Col><UniLocalize translate={S10nModelC.getNameKeyFromModelType(this.props.subscriptionType.value)} /></Col>
                    </Row>
                    <br/>
                    <Row nogutter>
                      <Col md={4}><strong><UniLocalize translate="administrator" /></strong></Col>
                      <Col>{this.props.authUser?.getFullName?.()}</Col>
                    </Row>
                  </Container>

                  <UniConditionalRender visible={!withoutCaptcha}>
                    <div className="recaptcha">
                      <ReCAPTCHA
                        sitekey={captchaSiteKey}
                        onChange={this.props.handleCaptchaChange}
                        size={screenClass === 'xs' ? 'compact' : 'normal'} />
                    </div>
                  </UniConditionalRender>

                  {/* step actions */}
                  <UniOverlapGroup foldEarly={true}>
                    <UniOverlapButton
                      handleClick={this.props.handleCreateClick}
                      textKey="register"
                      icon="forward"
                      disabled={!firstStepValid || !secondStepValid || !withoutCaptcha ? !this.props.validCaptcha : false}
                      tooltipPosition="right"
                      showLoader={this.props.loading} />
                    <UniOverlapButton
                      handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                      textKey="goPrevious"
                      icon="navigateBefore"
                      secondary={true}
                      disabled={this.props.currentStepIndex === 0}
                      tooltipPosition="right" />
                  </UniOverlapGroup>
                </UniConditionalRender>
                {/* End Third Step */}

                <div className="below-workflow">
                  <a className="link-left" href="/#/login"><UniLocalize translate="goToLogin" /></a>
                </div>
              </UniWorkflow>
            </UniConditionalRender>
          </section>
          <FooterContainer />
        </section>
      )} />
    )
  }
}

function mapStateToProps(state: any) {
  return {
    // is the user allowed to Create Dealer?
    canUserCreateDealer: state.oidcUser.canUserCreateDealer,
    // dealer creation
    validCaptcha: state.captcha.valid,
    loading: state.dealerRegisterForm.loading,
    authUser: state.authenticatedUser?.currentUser,
    currentStepIndex: state.dealerRegisterForm.currentStepIndex,
    // step 1
    dealerName: state.dealerRegisterForm.dealerName,
    country: state.dealerRegisterForm.country,
    administrativeAreaName: state.dealerRegisterForm.administrativeAreaName,
    city: state.dealerRegisterForm.city,
    streetAddress1: state.dealerRegisterForm.streetAddress1,
    streetAddress2: state.dealerRegisterForm.streetAddress2,
    zipCode: state.dealerRegisterForm.zipCode,
    phone: state.dealerRegisterForm.phone,
    // step 1 - dealer skinning stuff
    logo: state.dealerRegisterForm.logo,
    logoOnDark: state.dealerRegisterForm.darkModeLogo,
    primaryColor: state.dealerRegisterForm.colorPrimary,
    secondaryColor: state.dealerRegisterForm.colorSecondary,
    // step 2
    subscriptionType: state.dealerRegisterForm.subscriptionType,
    availableSubscriptonModels: state.subscriptionInfo.availableModels,
    defaultSubscription: state.subscriptionInfo.defaultType,
    darkModeTheme: state.portal.darkModeTheme,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  handleCreateClick: attemptRegisterDealerAsAuthenticatedOwner,
  handleDealerFormChange: updateDealerRegistrationForm,

  changeWorkflowStep: changeRegistrationStepIndex,
  handleCaptchaChange: updateCaptcha,
  retrieveAvailableSubscriptionModels: attemptRetrieveAvailableSubscriptionModels
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(DealerCreateContainer)
  ), { componentName: 'DealerCreate', unauthenticated: false, allowWithoutDealer: true })