import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  AuthUserC,
  UniKeyVal,
  UniButton,
  UniLocalize,
  UniOverflowActions,
  UserC,
  IUniConfirm_Config,
  IUniMenu_ItemConfig,
  UniMessage,
  EMessageType,
  UniConditionalRender,
} from '@unikey/unikey-commons/release/comm';

import {
  AccountID
} from '@unikey/unikey-commons/release/identity'

import {
  ChangePasswordContainer,
  AddPasswordEmailRequestContainer,
  attemptGetIdentityAccount,
  attemptUpdateCurrentUser,
  attemptRetrieveAuthUser,
  attemptPasswordReset,
  handleModifyAuthUser,
  toggleAddPasswordWorkflow,
  toggleChangePasswordWorkflow,
  openConfirmModal,
  closeConfirmModal,
  PartnerCustomizations, IPartnerCustomizations, MyLinkedAccountsContainer
} from '../internal'


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  currentUser: AuthUserC,
  loading: boolean,
  requestedResetSuccessfully: boolean,
  authUserHasPassword: boolean,
  emailEditModalOpen?: boolean,
  addPasswordModalOpen?: boolean,
  passwordEditModalOpen?: boolean,
  getCurrentUser(ignoreCache: boolean): void,
  closeConfirmModal(): void,
  getIdentityAccount(): Promise<AccountID>,
  handleModifyAuthUser(user: AuthUserC): void,
  updateUserData(): any,
  toggleChangePasswordWorkflow(): void,
  toggleAddPasswordWorkflow(): void,
}

class MyAccountContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getCurrentUser(true);
    this.props.getIdentityAccount();
  }


  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('changeMyPassword', {
        textKey: 'changeMyPassword',
        handleClick: () => this.props.toggleChangePasswordWorkflow(),
        hidden: !this.props.authUserHasPassword,
        theme: 'secondary',
      })
      .set('setPassword', {
        textKey: 'setPassword',
        handleClick: () => this.props.toggleAddPasswordWorkflow(),
        hidden: this.props.authUserHasPassword,
        theme: 'secondary',
      });

    return (
      <section className='myAccount-container'>

        <Row justify="between">
          <Col>
            <h3>
              <UniLocalize translate="myAccount" />
            </h3>
          </Col>
          <Col>
            <UniOverflowActions
              className="title-actions"
              nameKey="accountActions"
              icon="arrowDropDownCircle"
              actions={actionsMenuContents}
              theme="primary" />
          </Col>
        </Row>

        <Row>
          <Col>
            <UniKeyVal
              label="myAccount"
              showLoader={!this.props.currentUser.username}
              preventEdit={true}
              fields={[
                {
                  keyName: 'firstName',
                  value: '' + this.props.currentUser.firstName,
                  type: 'string',
                  preventTranslate: true
                },
                {
                  keyName: 'lastName',
                  value: '' + this.props.currentUser.lastName,
                  type: 'string',
                  preventTranslate: true
                },
                {
                  keyName: 'email',
                  value: '' + this.props.currentUser.username,
                  type: 'string',
                  preventTranslate: true
                }
              ]} />
          </Col>
        </Row>

        <UniConditionalRender visible={!this.props.currentUser?.getDealer()?.id}>
          <Row>
            <Col>
            <UniMessage
              titleKey={'noDealerAssociation'}
              messageKeys={['_explainNoDealerAssociationRequirement']}
              type={EMessageType.warn} />
            </Col>
          </Row>
        </UniConditionalRender>

        <MyLinkedAccountsContainer />
        
        <UniConditionalRender visible={this.props.passwordEditModalOpen}>
          <ChangePasswordContainer />
        </UniConditionalRender>

        <UniConditionalRender visible={this.props.addPasswordModalOpen}>
          <AddPasswordEmailRequestContainer />
        </UniConditionalRender>


      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    currentUser: state.authenticatedUser.currentUser,
    loading: state.authenticatedUser.loading,
    requestedResetSuccessfully: state.forgotForm.requestSuccess,
    passwordEditModalOpen: state.changePasswordForm.open,
    authUserHasPassword: state.identity?.authIdentity.acct?.hasPassword,
    addPasswordModalOpen: state.addPasswordModal?.open,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCurrentUser: attemptRetrieveAuthUser,
  openConfirmSendPasswordResetDialog: openConfirmModal,
  // resetPassword: attemptPasswordReset,
  getIdentityAccount: attemptGetIdentityAccount,
  updateUserData: attemptUpdateCurrentUser,
  handleModifyAuthUser,
  toggleAddPasswordWorkflow,
  toggleChangePasswordWorkflow,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(MyAccountContainer)
  ), { componentName: 'MyAccount' })
