import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import partnerConfig from '@alias-current-partner-customizations';
import classNames from 'classnames'
import { RouteComponentProps } from 'react-router-dom'

import {
  IUniToast_Alert,
  UniImg,
  UniLoader,
  darkThemeName,
  UniConditionalRender,
  EMessageType
} from '@unikey/unikey-commons/release/comm';

import {
  addAlert,
  mockAuth,
  partnerKey,
  loginKey,
  portalRedirect,
  attemptLogoutRequest,
  navConfig, ENavPages,
} from '../internal';


interface IProps extends RouteComponentProps {
  darkModeTheme: string,
  tokenRefreshAttemptCompleted?: boolean,
  withLoader?: boolean,
  hideLogo?: boolean,
  logout(): void,
  addAlert(config: IUniToast_Alert): void,
}

class SplashContainer extends Component<IProps> {
  splashHangRedirectRef?: NodeJS.Timeout; 
  splashHangRedirectImminentRef?: NodeJS.Timeout;
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // as long as we are not expecting the redirect to happen from another source, 
    // then go ahead and make request to redirect appropriately
    if (!mockAuth) {
      if (this.props.match.path === '/') {
        portalRedirect(navConfig.get(ENavPages.portalLanding)!.linkTo([]));
      }
    }


    // TODO: there is a "UserManager.getUser() user not found" message logged 
    // to console sometimes. When this happens, it causes this spash screen to hang.
    // Nothing is exposed to hook into that currently, but we need to investigate 
    // a way to redirect directly when the oidc library's user is not found vs 
    // using the fallback below to redirect after an extended wait.

    // fallback to not hang on splash screen for too long. 
    // force back to login if waiting for > 10 seconds
    this.splashHangRedirectRef = setTimeout(() => {
      console.warn("Splash duration longer than expected.");
      
      // display redirecting now message to the user.
      this.props.addAlert({
        id: Date.now(),
        type: EMessageType.warn,
        titleKey: 'onSplashTooLogin',
        messageKeys: ['sendingToLogin']
      })

      // after 2 more seconds redirect the user to login
      this.splashHangRedirectImminentRef = setTimeout(() => {
        portalRedirect(loginKey);
      }, 2500);
    }, 8000);
  }

  componentWillUnmount() {
    if (this.splashHangRedirectRef) {
      clearTimeout(this.splashHangRedirectRef);
    }
    if (this.splashHangRedirectImminentRef) {
      clearTimeout(this.splashHangRedirectImminentRef);
    }
  }
  
  render() {
    return (
      <>
        <UniConditionalRender visible={!this.props.hideLogo}>
          <UniImg textKey="partner logo"
            className={classNames('partner-logo', 'splash', partnerKey)}
            src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
        </UniConditionalRender>
        <UniConditionalRender visible={this.props.withLoader}>
          <div className="splash-loader splash">
            <UniLoader type="radial" />
          </div>
        </UniConditionalRender>
      </>
    )
  }
}

function mapStateToProps(state: any, props: IProps) {
  return {
    tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted,
    darkModeTheme: state.portal.darkModeTheme,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  addAlert,
  logout: attemptLogoutRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SplashContainer)