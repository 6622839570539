import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniWorkflow,
  UniInput,
  UniToggle,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  Editable,
  IMultiInputUpdate,
  ES10nSettings,
  UniConditionalRender
} from '@unikey/unikey-commons/release/comm';

import {
  toggleCreateOrgModal,
  attemptCreateNewOrg,
  handleCreateOrgChange,
  attemptRetrieveDealerDetails,
  attemptRetrieveOrganizations,
  s10n,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  // history: any,
  modalOpen: boolean,
  newOrgName: Editable<string>,
  newOrgCreditAllocation: Editable<number>,
  isAllocationUnimited: boolean,
  numDealerCreditsAvailable: number,
  loading?: boolean,

  s10nDisplayOrgCreditAllocationSelection: boolean,

  updateNewOrg(changes: IMultiInputUpdate, isUnlimited?: boolean): void
  toggleModal(): void,
  attemptCreateNewOrg(name: string): Promise<void>,
  getDealerAccountDetails(): Promise<void>,
  getOrgs(): Promise<void>,
}

class OrganizationCreateContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getDealerAccountDetails();
  }

  _saveOrgAndReloadList(preventSave: boolean) {
    if (!preventSave) {
      this.props.attemptCreateNewOrg(this.props.newOrgName.value).then(() => {
        this.props.getOrgs();
      })
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }
    return (
      <section className='orgCreate-container'>
        <UniWorkflow 
          titleKey="addNewOrganization"
          titleIcon="business"
          inModal
          handleClose={this.props.toggleModal}>

          <p><UniLocalize translate="_chooseOrganizationName" /></p>
          <UniInput
            editable={this.props.newOrgName}
            labelKey="organizationName"
            placeholderKey="organizationName"
            handleUpdate={(name: Editable<string>) => this.props.updateNewOrg({ name })}
            handleEnter={() => this._saveOrgAndReloadList(!this.props.newOrgName.value)}
            focusOnInitialRender={true} />

          <UniConditionalRender visible={this.props.s10nDisplayOrgCreditAllocationSelection}>
            <h5><UniLocalize translate="credentialAllocationType" /></h5>
            <UniToggle
              mini={false}
              options={[
                { value: true, nameKey: 'unlimited' },
                { value: false, nameKey: 'limited' }
              ]}
              value={this.props.isAllocationUnimited}
              handleUpdate={(isUnlimited: boolean) => this.props.updateNewOrg({}, isUnlimited)} />

            <p>
              <UniLocalize translate={this.props.isAllocationUnimited ? '_unlimitedOrgAllocationTypeExplanation' : '_limitedOrgAllocationTypeExplanation'} />
            </p>

          </UniConditionalRender>

          <UniConditionalRender visible={this.props.isAllocationUnimited === false}>
            <UniInput
              editable={this.props.newOrgCreditAllocation}
              type="number"
              min={0}
              max={this.props.numDealerCreditsAvailable}
              placeholderKey="creditsAllocated"
              labelKey="creditsAllocated"
              handleUpdate={(creditAllocation: Editable<number>) => this.props.updateNewOrg({ creditAllocation })}
              focusOnInitialRender={false}
              validations={new Map([
                ['min', {
                  numCheck: 0,
                  messageKey: 'cannotBeLessThanZero'
                }],
                ['max', {
                  numCheck: this.props.numDealerCreditsAvailable,
                  messageKey: 'mustBeLessThanDealerMax'
                }]
              ])} />
          </UniConditionalRender>

          {/* step actions */}
          <UniOverlapGroup>
            <UniOverlapButton
              handleClick={() => this._saveOrgAndReloadList(!this.props.newOrgName.value)}
              textKey="save"
              icon="save"
              disabled={!this.props.newOrgName.value}
              showLoader={!!this.props.loading} />
            <UniOverlapButton
              handleClick={this.props.toggleModal}
              textKey="cancel"
              icon="close"
              secondary={true} />
          </UniOverlapGroup>
        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    modalOpen: state.createOrg.modalOpen,
    newOrgName: state.createOrg.newOrg.name,
    newOrgCreditAllocation: state.createOrg.newOrg.creditAllocation,
    isAllocationUnimited: state.createOrg.newOrg.unlimitedCredits,
    numDealerCreditsAvailable: state.dealer.dealerData.availableCredentials - state.dealer.dealerData.unclaimedAllocatedCredentials,
    loading: state.createOrg.loading,
    // subscription
    s10nDisplayOrgCreditAllocationSelection: s10n(ES10nSettings.OrgCreditAllocation),
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  attemptCreateNewOrg,
  toggleModal: toggleCreateOrgModal,
  updateNewOrg: handleCreateOrgChange,
  getDealerAccountDetails: attemptRetrieveDealerDetails,
  getOrgs: attemptRetrieveOrganizations,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(OrganizationCreateContainer)
  ), { componentName: 'OrganizationCreate' })
