import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'
import classNames from 'classnames';

import {
  darkThemeName,
  UniWorkflow,
  UniImg,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';

import { deeplinkDomain } from '@alias-environment';

import {
  FooterContainer,
  partnerKey,
  iosAppStoreLink,
  googlePlayStoreLink,
  navConfig,
} from '../internal';

interface IProps extends WrappedComponentProps {
  history: any,
  darkModeTheme: string,
}

class RedeemContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // attempt an automatic redirect if the url contains a redeem token 
    // to force lazy mobile browsers to trigger the deep link
    const manualRedeemButtonLink = this._getRedeemLocationRedirect();
    if (manualRedeemButtonLink && this.props.history.location.search.indexOf('redirectAttempted') === -1) {
      setTimeout(() => {
        window.open(`${deeplinkDomain}/redeem?token=${manualRedeemButtonLink}&redirectAttempted=true`, "_self");
      }, 1000);
    }
  }

  _getRedeemLocationRedirect = () => {
    const queryParams = this.props.history.location.search;
    var manualRedeemButtonLink = '';
    if (queryParams?.indexOf?.('token=') >= 0) {
      // if a continue is specified, then decode it and set it as the
      // token parameter for the login action to handle
      manualRedeemButtonLink = queryParams.match(/token=(.*)$/)[1];
      // remove the $deep_link stuff
      manualRedeemButtonLink = manualRedeemButtonLink.split('&')[0];
    }
    return manualRedeemButtonLink;
  }

  render() {
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    const manualRedeemButtonLink = this._getRedeemLocationRedirect();

    return (
      <section className='access-container'>

        <section className='redeem-container'>
          {/* access flow should conform to max width restrictions for massive screens */}
          <Container>
            <UniImg textKey="partner logo" className={imgClassName} src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
            <UniWorkflow
              titleKey="redeemCredential"
              titleIcon="getApp"
              size="wider" >

              <p><UniLocalize translate='mustInstallApp' /></p>

              <p>
                <span>
                  <strong><UniLocalize translate='_redeem_step1' /></strong>
                </span>
                &nbsp;
                <span>
                  <UniLocalize translate='_redeem_step1Action' />
                </span>
              </p>

              <Row>
                <UniConditionalRender visible={!!iosAppStoreLink}>
                  <Col xs={8} sm={6} offset={{ xs: 2, sm: 0 }}>
                    <a href={iosAppStoreLink}>
                      <UniImg textKey="downloadAppInAppStore" className="app-badge-image iOS" src="https://unikeystatic.blob.core.windows.net/assets/commercial-app/shared/app-store-badge.png" />
                    </a>
                  </Col>
                </UniConditionalRender>

                <UniConditionalRender visible={!!googlePlayStoreLink}>
                  <Col xs={8} sm={6} offset={{ xs: 2, sm: 0 }}>
                    <a href={googlePlayStoreLink}>
                      <UniImg textKey="downloadAppInGooglePlayStore" className="app-badge-image android" src="https://unikeystatic.blob.core.windows.net/assets/commercial-app/shared/google-play-badge.png" />
                    </a>
                  </Col>
                </UniConditionalRender>
              </Row>

              <p>
                <span>
                  <strong><UniLocalize translate='_redeem_step2' /></strong>
                </span>
                &nbsp;
                <span>
                  <UniConditionalRender visible={!manualRedeemButtonLink}>
                    <UniLocalize translate='_redeem_step2ActionNoToken' />
                  </UniConditionalRender>
                  <UniConditionalRender visible={!!manualRedeemButtonLink}>
                    <a href={`${deeplinkDomain}/redeem?token=${manualRedeemButtonLink}`}><UniLocalize translate='_redeem_step2ActionWithRedeemToken' /></a>
                  </UniConditionalRender>
                </span>
              </p>


            </UniWorkflow>
          </Container>
        </section>
        <FooterContainer />
      </ section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    darkModeTheme: state.portal.darkModeTheme
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RedeemContainer, { forwardRef: true }))
