import * as reduxLib from 'redux-oidc';


import {
  oidcActions,
  devLogger,
  decodeJWT,
  getJWTExpiryString,
  checkJWTExpired,
  authActions
} from '../../internal'

const initialOidcUser = {
  loading: false,
  user: undefined,
  canUserCreateDealer: undefined,
};

export const oidcUser = (state = initialOidcUser, action: any) => {
  switch (action.type) {
    case oidcActions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case oidcActions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        canUserCreateDealer: action.canUserCreateDealer
      }
    case oidcActions.LOGIN_FAILURE:
      return {
        ...state,
        loading: false
      }
    case authActions.UPDATE_CAN_USER_CREATE_DELAER: 
      return {
        ...state,
        canUserCreateDealer: action.canCreateDealer
      }
    case oidcActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case oidcActions.LOGOUT_SUCCESS:
      return {
        ...state,
        user: undefined,
        loading: false
      }
    default:
      return state
  }
};


const initialOidcAuthStatusState = {
  refreshAttempted: false,
  // initiall set to true so that we dont show authenticated
  // pages until we are sure we have a good token
  isExpired: true, 
  currentExpiry: '',
  updater: 0
};


// export const USER_EXPIRED: string;
// export const REDIRECT_SUCCESS: string;
// export const USER_LOADED: string;
// export const SILENT_RENEW_ERROR: string;
// export const SESSION_TERMINATED: string;
// export const USER_EXPIRING: string;
// export const USER_FOUND: string;
// export const LOADING_USER: string;
// export const LOAD_USER_ERROR: string;
// export const USER_SIGNED_OUT: string;

export const oidcAuthStatus = (state = initialOidcAuthStatusState, action: any) => {
  switch (action.type) {
    case reduxLib.SESSION_TERMINATED:
      devLogger(`oidc token action:${state.updater}: session terminated`)
      return state;
    case reduxLib.USER_SIGNED_OUT:
        devLogger(`oidc token action:${state.updater}: user signed out`)
        return state;
    case reduxLib.USER_EXPIRED: 
      devLogger(`oidc token action:${state.updater}: userExpired`);
      return state;
    case reduxLib.REDIRECT_SUCCESS:
      devLogger(`oidc token action:${state.updater}: redirect success`);
      return state;
    case reduxLib.USER_FOUND: 
      devLogger(`oidc token action:${state.updater}: user found`)
      const userFoundUpdates = {
        ...state,
        updater: state.updater + 1,
        refreshAttempted: true,
      };
      const accessDecoded = decodeJWT(action?.payload?.access_token);
      if (accessDecoded) {
        userFoundUpdates.currentExpiry = getJWTExpiryString(accessDecoded);
        userFoundUpdates.isExpired = checkJWTExpired(accessDecoded);
      }
      devLogger(`end:oidc token action:${userFoundUpdates.updater}: user found`)
      return userFoundUpdates;
    case reduxLib.USER_LOADED: 
      devLogger(`oidc token action:${state.updater}: user loaded`)
      const userLoadedStateUpdates = {
        ...state,
        updater: state.updater + 1,
        refreshAttempted: true,
      };
      const accessDec = decodeJWT(action?.payload?.access_token);
      if (accessDecoded) {
        userLoadedStateUpdates.currentExpiry = getJWTExpiryString(accessDec);
        userLoadedStateUpdates.isExpired = checkJWTExpired(accessDec);
      }
      return userLoadedStateUpdates;
    case reduxLib.LOADING_USER: 
      devLogger(`oidc token action:${state.updater}: user loading`)
      return state;
    case reduxLib.LOAD_USER_ERROR: 
      devLogger(`oidc token action:${state.updater}: user load error`)
      return {
        ...state,
        updater: state.updater + 1,
        refreshAttempted: true,
      };
    case reduxLib.SILENT_RENEW_ERROR: 
      devLogger(`oidc token action:${state.updater}: silent renew failed`)
      return {
        ...state,
        updater: state.updater + 1,
        refreshAttempted: true
      };
    case reduxLib.USER_EXPIRING: 
      devLogger(`oidc token action:${state.updater}: user expiring`)
      return state;
    default:
      return state;
  }
}

export const oidc = reduxLib.reducer;