import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { Route, Switch, Router } from 'react-router'
import { IntlProvider } from 'react-intl';

import {
  navConfig,
  verifyLandingKey,
  languageChange,
  dismissAlert,
  hideAllAlerts,
  showAllAlerts,
  localeMessages,
  storeHistory,
  PortalContainer,
  LoginContainer,
  SplashContainer,
  setDarkModeTheme,
  FeedbackContainer,
  ActionTokenContainer,
  AdminRegisterContainer,
  DealerRegisterContainer,
  VerifyEmailContainer,
  VerifyResendContainer,
  DealerCreateContainer,
  RedeemContainer,
  LegacyChangePasswordContainer,
  SetPasswordTokenContainer,
  AdminInvitationTokenParserContainer,
  AdminInvitationAcceptContainer,
  LegalContainer,
  OopsContainer,
  OidcContainer,
  OidcRenewContainer,
  OidcLogoutContainer,
  AuthExpiryContainer,
  ENavPages,
  loginKey,
  logoutKey
  
} from '../internal'

import {
  environment,
  buildId,
  cdpVersion,
  commonsVersion,
  releaseId
} from '@alias-environment';

import {
  keepUiTheme,
  UniConditionalRender,
  UniBanner,
  UniMultiToast,
  IUniToast_Alert
} from '@unikey/unikey-commons/release/comm';

interface IProps {
  i18nConfig: any,
  alerts: Map<number, IUniToast_Alert>,
  alertReview: boolean,
  alertUpdate: number,
  oidcUpdater: number,
  feedbackModalOpen: boolean,
  handleLanguageChange(localeCode: string): void,
  dismissAlert(alertId: number): void
  deleteAlert(alertId: number): void,
  hideAllAlerts(): void,
  setThemeMode(theme: string): void
}

class AppContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    const initialTheme = keepUiTheme();
    props.setThemeMode(initialTheme);
  }

  _closeAlertHistory = () => {
    this.props.hideAllAlerts();
  }

  _handleLocaleErrors = (errMsg: string) => {
    if (environment === 'development') {
      console.warn('problem loading locale messages: ' + errMsg);
    }
  }

  render() {
    return (
      <IntlProvider
        key={this.props.i18nConfig.localeCode}
        locale={this.props.i18nConfig.localeCode}
        defaultLocale={this.props.i18nConfig.localeCode}
        messages={localeMessages.get(this.props.i18nConfig.localeCode)}
        onError={this._handleLocaleErrors}>
        <>
          <ConnectedRouter history={storeHistory}>
            <>
              <Switch> 
                <Route path="/portal/:page" children={(routeProps: any) => (
                  <PortalContainer
                    {...routeProps}
                    navigationConfig={navConfig} />
                )} />

                <Route exact path={`/${ENavPages.oidcToken}`} component={OidcContainer} />
                <Route exact path={`/${ENavPages.oidcRenew}`} component={OidcRenewContainer} />
                <Route exact path={loginKey} component={LoginContainer} />
                <Route exact path={logoutKey} component={OidcLogoutContainer} />
                <Route exact path="/" component={OidcContainer} />
                <Route exact path="/change/:passwordToken" component={LegacyChangePasswordContainer} />
                <Route exact path="/invites/:inviteToken/accept" component={AdminInvitationTokenParserContainer} />
                <Route exact path="/invites/admins/:inviteToken/accept" component={AdminInvitationAcceptContainer} />
                <Route exact path="/invites/admins/:inviteToken/register" component={AdminRegisterContainer} />
                <Route exact path="/register" component={DealerRegisterContainer} />
                <Route exact path={verifyLandingKey} component={VerifyResendContainer} />
                <Route exact path="/createdealer" component={DealerCreateContainer} />
                <Route exact path="/verify/:verifyToken" component={VerifyEmailContainer} />
                <Route exact path="/token/:actionToken" component={ActionTokenContainer} />
                <Route exact path={navConfig.get(ENavPages.setPassword)!.linkTo()} component={SetPasswordTokenContainer} />
                <Route exact path="/redeem" component={RedeemContainer} />
                <Route exact path="/legal" component={LegalContainer} />
                <Route exact path="/oops/:reason?" component={OopsContainer} />
                <Route component={SplashContainer} />
              </Switch>
            </>
          </ConnectedRouter>

          <UniConditionalRender visible={environment === 'development'}>
            <AuthExpiryContainer key={this.props.oidcUpdater} />
          </UniConditionalRender>

          <UniConditionalRender visible={this.props.feedbackModalOpen}>
            <FeedbackContainer />
          </UniConditionalRender>

          <UniConditionalRender visible={environment === 'staging' || environment === 'development'}>
            <UniBanner
              type={environment}
              messageKey={`${environment}Environment`}
              secondaryMessageKey={`RELEASE[${releaseId.split('-').pop()}] / C[${commonsVersion}] / CDP[${cdpVersion}]`} />
          </UniConditionalRender>

          <section className="toasts">
            <UniMultiToast
              alerts={this.props.alerts}
              dismissAlert={this.props.dismissAlert}
              deleteAlert={this.props.deleteAlert}
              inReview={this.props.alertReview}
              backdropClick={this._closeAlertHistory} />
          </section>
        </>
      </IntlProvider>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    i18nConfig: state.i18n,
    alerts: state.alerts.showingAlerts,
    alertReview: state.alerts.review,
    // We arent using numAlerts in this component, but changes to the alerts Map does not trigger a re-render here. We are forcing a re-render here by listening to the numAlerts variable
    alertUpdate: state.alerts.alertUpdate,
    oidcUpdater: state.oidcAuthStatus.updater,
    feedbackModalOpen: state.feedbackModal.modalOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  handleLanguageChange: languageChange,
  dismissAlert: dismissAlert.bind(null, false),
  deleteAlert: dismissAlert.bind(null, true),
  setThemeMode: setDarkModeTheme,
  hideAllAlerts,
  showAllAlerts,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
