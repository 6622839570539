import { desiredLocales } from '@alias-environment';

const productName = 'Honeywell';
const partnerTranslations = { en: {} };

// add the desired language translations
desiredLocales.split(',').forEach((localeCode: string) => {
    const currLocaleTranslations = require(`./translations/${localeCode}.json`);
    (partnerTranslations as any)[localeCode] = currLocaleTranslations;
    // add in any translations across languages ex: company, product name, etc.
    (partnerTranslations as any)[localeCode].productName = productName;
});

// NOTE: Honeywell has replaced the "Organization" word with "Customers"
export default partnerTranslations;