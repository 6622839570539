import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classNames from 'classnames'
import {
  AuthUserC,
  UniWorkflow,
  UniInput,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  IInputChange,
  IMultiInputUpdate,
  Editable,
  UniConditionalRender,
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';

import {
  toggleAddPasswordWorkflow,
  attemptRequestAddPasswordEmail,
  PartnerCustomizations, IPartnerCustomizations,
  noop,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  currentUser: AuthUserC,
  loading?: boolean,
  alreadySent?: boolean,
  requestPasswordEmail(): Promise<void>,
  toggleModal(): void,
}

class AddPasswordEmailRequestContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);

  }

  _toggleAndResetForm() {
    this.props.toggleModal();
  }

  _submitEmailRequest = () => {
    this.props.requestPasswordEmail().then(() => {
      this._toggleAndResetForm();
    });
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }
    return (
      <section>
        <UniWorkflow
          inModal
          titleKey="setNewPassword"
          titleIcon="mail"
          handleClose={this._toggleAndResetForm}>
          
          <UniConditionalRender visible={!this.props.alreadySent}>
            <p>
              <UniLocalize translate="_setNewPasswordInstructions" />
              <p><UniLocalize translate="_anEmailWillBeSentToColon" /></p>
              <p><strong>{this.props.currentUser.username}</strong></p>
            </p>
          </UniConditionalRender>

          <UniConditionalRender visible={this.props.alreadySent}>
            <p><UniLocalize translate="_emailHasBeenSentToColon" /></p>
            <p><strong>{this.props.currentUser.username}</strong></p>
            <p><UniLocalize translate="_setPasswordNextSteps" /></p>
          </UniConditionalRender>

          <UniOverlapGroup>
            <UniOverlapButton
              handleClick={() => this._submitEmailRequest()}
              textKey="send"
              icon="send"
              showLoader={this.props.loading}
              disabled={this.props.alreadySent}
              tooltipPosition="right" />
            <UniOverlapButton
              handleClick={() => { this._toggleAndResetForm(); }}
              textKey="cancel"
              icon="close"
              secondary={true}
              tooltipPosition="right" />
          </UniOverlapGroup>

        </UniWorkflow>
      </section >
    )
  }
}

function mapStateToProps(state: any) {
  const currentUser = state.authenticatedUser.currentUser;
  return {
    currentUser,
    userUpdater: currentUser.updater,
    loading: state.addPasswordModal.loading,
    alreadySent: state.addPasswordModal.alreadySent
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  requestPasswordEmail: attemptRequestAddPasswordEmail,
  toggleModal: toggleAddPasswordWorkflow,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AddPasswordEmailRequestContainer)
  ), { componentName: 'AddPasswordEmailRequest' })
