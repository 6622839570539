import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  attemptVerifyEmailLegacy,
  portalRedirect,
  SplashContainer,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  loading: boolean,
  failed: boolean,
  attemptVerifyEmailLegacy(token: string): void
}

class VerifyEmailContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);

  }

  componentDidMount() {
    const verifyToken = this.props.match.params.verifyToken ?? '';
    if (verifyToken.startsWith('ey') && verifyToken.length > 50) {
      portalRedirect(`/token/${verifyToken}`);
    }
    else {
      // this can be removed once we no longer have active legacy style email verifications
      this.props.attemptVerifyEmailLegacy(this.props.match.params.verifyToken);
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <section className='email-confirm-container'>
        <SplashContainer
          match={this.props.match}
          history={this.props.history} />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    username: state.loginForm.username,
    password: state.loginForm.password,
    loading: state.authenticatedUser.loading,
    validCaptcha: state.captcha.valid
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  attemptVerifyEmailLegacy
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(VerifyEmailContainer, { forwardRef: true })
), { componentName: 'VerifyEmail', unauthenticated: true })