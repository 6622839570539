import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl';
import classNames from 'classnames';

import {
  SplashContainer,
  api,
  partnerKey,
  userManager,
  PartnerCustomizations, IPartnerCustomizations,
  oidcLoginRequest
} from '../internal'


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  loading: boolean,
  darkModeTheme: string,
  attemptLogin(): void,
}

class LoginContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    const queryParams = this.props.history.location.search;
    if (queryParams?.indexOf?.('continue=') >= 0) {
      // store the continue in the cache since the oidc
      // authentication will take us away from the react app
      api.cacheImpl.set('continueTo', queryParams.match(/continue=(.*)$/)[1]);
    }
    return userManager.signinRedirect();
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    return (
      <section className='access-container'>
        <section className='login-container'>

          <SplashContainer
            match={this.props.match}
            history={this.props.history} />

        </section>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    loading: state.authenticatedUser.loading,
    darkModeTheme: state.portal.darkModeTheme,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  attemptLogin: oidcLoginRequest
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(LoginContainer, { forwardRef: true })
  ), { componentName: 'Login', unauthenticated: true })