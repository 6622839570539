import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  ENavPages,
  navConfig,
  portalRedirect
} from '../internal';

interface IProps {
  history: any
}

class ForwardToAccountDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

  }

  componentDidMount() {
    portalRedirect(navConfig.get(ENavPages.myProfile)!.linkTo());
  }

  render() {
    return (
      <>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {

  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ForwardToAccountDetailsContainer)
