import {
  portalActions
} from '../../internal'

const initialCaptchaState = {
  valid: false,
  value: null
};

export function captcha(state = initialCaptchaState, action: any) {
  switch (action.type) {
    case portalActions.CAPTCHA_CHANGE:
      return {
        ...state,
        valid: action.value !== null,
        value: action.value
      }
    default:
      return state
  }
}