import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { SignoutCallbackComponent } from 'redux-oidc';
import { RouteComponentProps } from 'react-router-dom'

import {
  attemptLogoutRequest,
  SplashContainer,
  userManager,
} from '../internal';

interface IProps extends RouteComponentProps {
  logout(): void
}

class OidcLogoutContainer extends Component<IProps> { 
  constructor(props: IProps) {
    super(props);
  }

  successCallback = (user?: any) => {
    setTimeout(() => {
      this.props.logout();
    }, 1000);
  };

  errorCallback = (error: any) => {
    console.warn(error);
    this.props.logout();
  };

  render() {
    return (
      <SignoutCallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}>
        <SplashContainer
          match={this.props.match}
          history={this.props.history} />
      </SignoutCallbackComponent>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  logout: attemptLogoutRequest,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OidcLogoutContainer)
