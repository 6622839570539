import { Editable } from '@unikey/unikey-commons/release/comm'

import {
  environment
} from '@alias-environment';

import {
  api,
  registerActions,
  inputInitializer,
} from '../../internal'

const initialAdminRegistrationsState: any = {
  loading: false,
  ...inputInitializer('', ['firstName', 'lastName', 'pass', 'passConfirm'])
};

export const adminRegisterForm = (state = initialAdminRegistrationsState, action: any) => {
  switch (action.type) {
    case registerActions.ADMIN_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case registerActions.ADMIN_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: api.pendingReqs.getNum(registerActions.ADMIN_REGISTRATION_REQUEST) > 0,
      }
    case registerActions.ADMIN_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: api.pendingReqs.getNum(registerActions.ADMIN_REGISTRATION_REQUEST) > 0,
      }
    case registerActions.CLEAR_ADMIN_REGISTRATION_FORM:
      return {
        ...state,
        ...initialAdminRegistrationsState,
      }
    case registerActions.UPDATE_ADMIN_REGISTRATION_FORM:
      return {
        ...state,
        firstName: action.formParts.firstName ? action.formParts.firstName : state.firstName,
        lastName: action.formParts.lastName ? action.formParts.lastName : state.lastName,
        pass: action.formParts.pass ? action.formParts.pass : state.pass,
        passConfirm: action.formParts.passConfirm ? action.formParts.passConfirm : state.passConfirm,
      }
    default:
      return state
  }
}


// 
var initialRegisterFields = inputInitializer('',
  [
    'username',
    'firstName',
    'lastName',
    'pass',
    'passConfirm',
    'dealerName',
    'phone',
    'city',
    'country',
    'subscriptionType',
    'administrativeAreaName',
    'locality',
    'streetAddress1',
    'streetAddress2',
    'address',
    'addressType',
    'zipCode',
    'colorPrimary',
    'colorSecondary',
    'logo',
    'darkModeLogo',
  ]
);
if (environment === 'development') {
  // to make developing this form easier, we are filling these fileds with dummy informmation in the development environment
  initialRegisterFields = {
    username: new Editable({ value: `test+${new Date().getTime()}@unikey.com`, valid: true, dirty: true }),
    firstName: new Editable({ value: 'Test', valid: true, dirty: true }),
    lastName: new Editable({ value: 'User', valid: true, dirty: true }),
    pass: new Editable({ value: '', valid: false, dirty: false }),
    passConfirm: new Editable({ value: '', valid: false, dirty: false }),
    dealerName: new Editable({ value: 'Test Dealer (Frontend)', valid: true, dirty: true }),
    phone: new Editable({ value: '4077777777', valid: true, dirty: true }),
    city: new Editable({ value: 'Orlando', valid: true, dirty: true }),
    country: new Editable({ value: 'United States of America (USA)', valid: true, dirty: true }),
    subscriptionType: new Editable({ value: 'credential', valid: true, dirty: true }),
    administrativeAreaName: new Editable({ value: 'FL', valid: true, dirty: true }),
    locality: new Editable({ value: '', valid: true, dirty: true }),
    streetAddress1: new Editable({ value: '111 W. Jefferson Street', valid: true, dirty: true }),
    streetAddress2: new Editable({ value: '', valid: true, dirty: true }),
    address: new Editable({ value: '', valid: true, dirty: true }),
    addressType: new Editable({ value: '', valid: true, dirty: true }),
    zipCode: new Editable({ value: '32777', valid: true, dirty: true }),
    colorPrimary: new Editable({ value: '#4433aa', valid: true, dirty: true }),
    colorSecondary: new Editable({ value: '#99aa33', valid: true, dirty: true }),
    logo: new Editable({ value: '', valid: true, dirty: true }),
    darkModeLogo: new Editable({ value: '', valid: true, dirty: true }),
  }
}
initialRegisterFields.country.value = 'United States of America (USA)';
initialRegisterFields.country.valid = true;

const initialDealerRegistrationForm: any = {
  currentStepIndex: 0,
  loading: false,
  ...initialRegisterFields
};

export const dealerRegisterForm = (state = initialDealerRegistrationForm, action: any) => {
  switch (action.type) {
    case registerActions.DEALER_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case registerActions.DEALER_REGISTRATION_SUCCESS:
      return {
        ...state,
        ...initialAdminRegistrationsState,
        loading: api.pendingReqs.getNum(registerActions.DEALER_REGISTRATION_REQUEST) > 0,
      }
    case registerActions.DEALER_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: api.pendingReqs.getNum(registerActions.DEALER_REGISTRATION_REQUEST) > 0,
      }
    case registerActions.UPDATE_DEALER_REGISTRATION_FORM:
      const formContents = {
        ...state,
        username: action.formParts.username || state.username,
        firstName: action.formParts.firstName || state.firstName,
        lastName: action.formParts.lastName || state.lastName,
        subscriptionType: action.formParts.subscriptionType || state.subscriptionType,
        pass: action.formParts.pass || state.pass,
        passConfirm: action.formParts.passConfirm || state.passConfirm,
        dealerName: action.formParts.dealerName || state.dealerName,
        phone: action.formParts.phone || state.phone,
        city: action.formParts.city || state.city,
        administrativeAreaName: action.formParts.administrativeAreaName || state.administrativeAreaName,
        country: action.formParts.country || state.country,
        streetAddress1: action.formParts.streetAddress1 || state.streetAddress1,
        streetAddress2: action.formParts.streetAddress2 || state.streetAddress2,
        zipCode: action.formParts.zipCode || state.zipCode,
        colorPrimary: action.formParts.colorPrimary || state.colorPrimary,
        colorSecondary: action.formParts.colorSecondary || state.colorSecondary,
        // clear selection by passing up empty string for logos
        logo: action.formParts.logo === '' ? '' : action.formParts.logo || state.logo,
        darkModeLogo: action.formParts.darkModeLogo === '' ? '' : action.formParts.darkModeLogo || state.darkModeLogo,
      }
      // mark confirm pass as invalid if they change makes them no longer match
      if (formContents.pass.value !== formContents.passConfirm.value) {
        formContents.passConfirm.valid = false;
      }
      return formContents;
    case registerActions.CHANGE_REGISTRATION_STEP_INDEX:
      return {
        ...state,
        currentStepIndex: action.stepTo
      }
    default:
      return state
  }
}

const initialVerifyEmailForm = {
  loading: false,
  sent: false
};

export const verifyEmailForm = (state = initialVerifyEmailForm, action: any) => {
  switch (action.type) {
    case registerActions.RESEND_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case registerActions.RESEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        sent: true
      }
    case registerActions.RESEND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: action.pending
      }
    default:
      return state
  }
}

