import { Dispatch } from 'redux'

import {
  IAcceptInviteRequestC,
  IDealerC,
  Editable,
  IMultiInputUpdate,
  S10nModelC,
  EMessageType,
  ITokenC,
  ISubscriptionModelResponseC,
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  identityApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  portalRedirect,
  navConfig,
  ENavPages,
  ApiReduxAction,
  IExposeRedux,
  attemptRetrieveAuthUser,
} from '../../internal'

export enum registerActions {

  ADMIN_REGISTRATION_REQUEST = 'ADMIN_REGISTRATION_REQUEST',
  ADMIN_REGISTRATION_SUCCESS = 'ADMIN_REGISTRATION_SUCCESS',
  ADMIN_REGISTRATION_FAILURE = 'ADMIN_REGISTRATION_FAIL',
  CLEAR_ADMIN_REGISTRATION_FORM = 'CLEAR_ADMIN_REGISTRATION_FORM',

  UPDATE_ADMIN_REGISTRATION_FORM = 'UPDATE_ADMIN_REGISTRATION_FORM',

  DEALER_REGISTRATION_REQUEST = 'DEALER_REGISTRATION_REQUEST',
  DEALER_REGISTRATION_SUCCESS = 'DEALER_REGISTRATION_SUCCESS',
  DEALER_REGISTRATION_FAILURE = 'DEALER_REGISTRATION_FAIL',

  UPDATE_DEALER_REGISTRATION_FORM = 'UPDATE_DEALER_REGISTRATION_FORM',

  CHANGE_REGISTRATION_STEP_INDEX = 'CHANGE_REGISTRATION_STEP_INDEX',

  VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE',

  RESEND_VERIFY_EMAIL_REQUEST = 'RESEND_VERIFY_EMAIL_REQUEST',
  RESEND_VERIFY_EMAIL_SUCCESS = 'RESEND_VERIFY_EMAIL_SUCCESS',
  RESEND_VERIFY_EMAIL_FAILURE = 'RESEND_VERIFY_EMAIL_FAILURE',
}

// Admin Registrations
export function updateAdminRegistrationForm(formParts: IMultiInputUpdate) {
  return {
    type: registerActions.UPDATE_ADMIN_REGISTRATION_FORM,
    formParts
  }
}


// // old admin registration / accept invite
// // new is in dealer service
// export function attemptAcceptAdminInviteAsNewUser(): any {
//   return (dispatch: Dispatch<any>, getState: any): any => {
//     const registrationDetails = getState().adminRegisterForm;
//     const inviteDetails = getState().inviteDetails.inviteData;

//     const acceptRegistrationBody: IAcceptInviteRequestC = {
//       token: inviteDetails.token,
//       user: {
//         username: inviteDetails.inviteeEmail,
//         password: registrationDetails.pass.value,
//         password_confirmation: registrationDetails.passConfirm.value,
//         profile: {
//           first_name: registrationDetails.firstName.value,
//           last_name: registrationDetails.lastName.value,
//         }
//       }
//     };
//     dispatch(acceptAdminRegistrationRequest())
//     const tracking = setupJobTrackingFor(registerActions.ADMIN_REGISTRATION_REQUEST);

//     return api.user.acceptUserInvitationForNewUser(acceptRegistrationBody, tracking).then((accepted: any) => {
//       dispatch(acceptAdminRegistrationSuccess(accepted))
//       dispatch(addAlert({
//         id: Date.now(),
//         titleKey: 'successfullyAcceptedInvite',
//         type: EMessageType.success,
//         messageKeys: ['redirectingToLogin'],
//         duration: 2000
//       }));
//       // redirect the user to the login screen
//       setTimeout(() => {
//         dispatch(redirectToLogin({ preventContinue: true }));
//         dispatch(clearAdminRegistrationForm())
//       }, 1000);

//       return accepted;
//     }, (err: any) => {
//       if (err.status === 401) {
//         dispatch(redirectToLogin())
//       } else {
//         dispatch(addAlert({
//           id: Date.now(),
//           titleKey: 'acceptAdminInviteFail',
//           type: EMessageType.error,
//           messageKeys: [err.message]
//         }));
//         dispatch(acceptAdminRegistrationFailure())
//       }
//     })
//   }
// }

export interface IAcceptInviteActionParams {
  token: string,
  dealerId: string
}
// new registration -- existing users -- allows more than one dealer
const acceptAdminInviteAsExistingUser = new ApiReduxAction({
  request: { type: registerActions.ADMIN_REGISTRATION_REQUEST },
  success: {
    type: registerActions.ADMIN_REGISTRATION_SUCCESS,
    title: 'successfullyAcceptedInvite',
    duration: 2000,
  },
  failure: {
    type: registerActions.ADMIN_REGISTRATION_FAILURE,
    title: 'acceptAdminInviteFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, { dealerId, token }: IAcceptInviteActionParams) => {
  const state = dux.getState();

  const inviteToken: ITokenC = {
    token
  };
  return api.deal.acceptDealerAdminInvitation.bind(api.deal, dealerId, inviteToken);
});
export const attemptAcceptAdminInviteAsExistingUser = acceptAdminInviteAsExistingUser.go;


// old registration -- must be new user -- fails on more than one dealer
const acceptAdminInviteAsNewUser = new ApiReduxAction({
  request: { type: registerActions.ADMIN_REGISTRATION_REQUEST },
  success: {
    type: registerActions.ADMIN_REGISTRATION_SUCCESS,
    title: 'successfullyAcceptedInvite',
    message: 'redirectingToLogin',
    duration: 6000,
    after: async (dux: IExposeRedux) => {
      setTimeout(() => {
        dux.dispatch(redirectToLogin({ preventContinue: true }));
        dux.dispatch(clearAdminRegistrationForm())
      }, 4000)
    }
  },
  failure: {
    type: registerActions.ADMIN_REGISTRATION_FAILURE,
    title: 'acceptAdminInviteFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, { dealerId, token }: IAcceptInviteActionParams) => {

  const registrationDetails = dux.getState().adminRegisterForm;
  const inviteDetails = dux.getState().inviteDetails.inviteData;

  const acceptRegistrationBody: IAcceptInviteRequestC = {
    token,
    user: {
      username: inviteDetails.inviteeEmail,
      password: registrationDetails.pass.value,
      password_confirmation: registrationDetails.passConfirm.value,
      profile: {
        first_name: registrationDetails.firstName.value,
        last_name: registrationDetails.lastName.value,
      }
    }
  };
  return api.user.acceptUserInvitationForNewUser.bind(api.user, acceptRegistrationBody);
});
export const attemptAcceptAdminInviteAsNewUser = acceptAdminInviteAsNewUser.go;

export function clearAdminRegistrationForm() {
  return {
    type: registerActions.CLEAR_ADMIN_REGISTRATION_FORM
  }
}

// Dealer Registrations
export function updateDealerRegistrationForm(formParts: Partial<IMultiInputUpdate>) {
  return {
    type: registerActions.UPDATE_DEALER_REGISTRATION_FORM,
    formParts
  }
}
export function attemptDealerRegistration(): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    const registrationDetails = getState().dealerRegisterForm;
    // As of 5/6/2020 the subscription model must be capitalized
    const desiredSubscriptionModel = S10nModelC.getApiNameFromModelType(registrationDetails.subscriptionType.value);
    const dealerRegistrationBody: IDealerC = {
      name: registrationDetails.dealerName.value,
      phone_number: registrationDetails.phone.value,
      subscription_model: desiredSubscriptionModel,
      skin_config: {
        logo: registrationDetails.logo?.value,
        dark_mode_logo: registrationDetails.darkModeLogo?.value,
        primary_color: registrationDetails.colorPrimary.value,
        secondary_color: registrationDetails.colorSecondary.value,
      },
      address_details: {
        country: registrationDetails.country.value,
        administrative_area: {
          name: registrationDetails.administrativeAreaName.value
        },
        locality: registrationDetails.city.value,
        address_lines: [
          {
            address: registrationDetails.streetAddress1.value
          }
        ],
        postal_code: {
          code_number: registrationDetails.zipCode.value
        }
      }
    };
    // if we included a second line, then we should add it to the request body structure
    if (registrationDetails.streetAddress2.value) {
      dealerRegistrationBody.address_details!.address_lines!.push({
        address: registrationDetails.streetAddress2.value
      })
    }

    dispatch(dealerRegistrationRequest())
    const tracking = setupJobTrackingFor(registerActions.DEALER_REGISTRATION_REQUEST);

    return api.deal.createInternationalDealerWithSkin(dealerRegistrationBody, tracking).then((response: any) => {
      dispatch(dealerRegistrationSuccess(response))
      dispatch(addAlert({
        id: Date.now(),
        titleKey: 'successfullyRegisteredDealer',
        type: EMessageType.success,
        messageKeys: ['checkEmailForVerification'],
        duration: 120000
      }));
      dispatch(redirectToLogin({ preventContinue: true }))
      return response;
    }, (err: any) => {
      dispatch(addAlert({
        id: Date.now(),
        titleKey: 'dealerRegistrationFail',
        type: EMessageType.error,
        messageKeys: [err.message]
      }));
      dispatch(dealerRegistrationFailure())
    })
  }
}

export function dealerRegistrationRequest() {
  return {
    type: registerActions.DEALER_REGISTRATION_REQUEST
  }
}

export function dealerRegistrationSuccess(registration: any) {
  return {
    type: registerActions.DEALER_REGISTRATION_SUCCESS,
    registration
  }
}

export function dealerRegistrationFailure() {
  return {
    type: registerActions.DEALER_REGISTRATION_FAILURE,
  }
}

export function changeRegistrationStepIndex(stepTo: number) {
  return {
    type: registerActions.CHANGE_REGISTRATION_STEP_INDEX,
    stepTo
  }
}

// Create Dealer as Authenticated User
const createDealerAsAuthenticatedOwner = new ApiReduxAction({
  request: {
    type: registerActions.DEALER_REGISTRATION_REQUEST,
    title: 'provisioningYourDealer',
    message: '_explainMayTakeAFewExtraSeconds',
    duration: 6000,
  },
  success: {
    type: registerActions.DEALER_REGISTRATION_SUCCESS,
    title: 'success',
    message: 'successfullyRegisteredDealer',
    duration: 12000,
    after: async (dux: IExposeRedux) => {
      // navigate to the dealer details screen for the newly created dealer, 
      // but first re-fetch the user to get the latest permissions including new dealer 
      await dux.dispatch(attemptRetrieveAuthUser(true));
      portalRedirect(navConfig.get(ENavPages.dealer)!.linkTo([]));
    }
  },
  failure: {
    type: registerActions.DEALER_REGISTRATION_FAILURE,
    title: 'dealerRegistrationFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux) => {
  const registrationDetails = dux.getState().dealerRegisterForm;
    const desiredSubscriptionModel = S10nModelC.getApiNameFromModelType(registrationDetails.subscriptionType.value);
    const dealerRegistrationBody: IDealerC = {
      name: registrationDetails.dealerName.value,
      phone_number: registrationDetails.phone.value,
      subscription_model: desiredSubscriptionModel,
      skin_config: {
        logo: registrationDetails.logo?.value,
        dark_mode_logo: registrationDetails.darkModeLogo?.value,
        primary_color: registrationDetails.colorPrimary.value,
        secondary_color: registrationDetails.colorSecondary.value,
      },
      address_details: {
        country: registrationDetails.country.value,
        administrative_area: {
          name: registrationDetails.administrativeAreaName.value
        },
        locality: registrationDetails.city.value,
        address_lines: [
          {
            address: registrationDetails.streetAddress1.value
          }
        ],
        postal_code: {
          code_number: registrationDetails.zipCode.value
        }
      }
    };
    // if we included a second line, then we should add it to the request body structure
    if (registrationDetails.streetAddress2.value) {
      dealerRegistrationBody.address_details!.address_lines!.push({
        address: registrationDetails.streetAddress2.value
      })
    }
  return api.deal.createInternationalDealerWithSkin.bind(api.deal, dealerRegistrationBody);
});
export const attemptRegisterDealerAsAuthenticatedOwner = createDealerAsAuthenticatedOwner.go;


const verifyEmailLegacy = new ApiReduxAction({
  request: {
    type: registerActions.VERIFY_EMAIL_REQUEST,
    title: 'provisioningYourDealer',
    message: '_explainMayTakeAFewExtraSeconds',
    duration: 6000,
  },
  success: {
    type: registerActions.VERIFY_EMAIL_SUCCESS,
    title: 'emailVerified',
    message: 'nowAbleToLogin',
    duration: 24000,
    after: async (dux: IExposeRedux) => {
      setTimeout(() => {
        dux.dispatch(redirectToLogin({ preventContinue: true }))
      }, 200)
    }
  },
  failure: {
    type: registerActions.VERIFY_EMAIL_FAILURE,
    title: 'verifyEmailFail',
    after: async (dux: IExposeRedux) => {
      dux.dispatch(redirectToLogin({ preventContinue: true }))
    }
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, token: string) => {
  return api.user.confirmUserEmail.bind(api.user, token);
});
export const attemptVerifyEmailLegacy = verifyEmailLegacy.go;


// request new verification email
const resendVerifyEmail = new ApiReduxAction({
  request: { type: registerActions.RESEND_VERIFY_EMAIL_REQUEST },
  success: {
    type: registerActions.RESEND_VERIFY_EMAIL_SUCCESS,
    title: 'resentVerifyEmail',
    message: 'checkEmailForVerification',
    duration: 60000
  },
  failure: {
    type: registerActions.RESEND_VERIFY_EMAIL_FAILURE,
    title: 'resendVerifyEmailFailure'
  },
  checkNumPendingReqs: identityApi.pendingReqs.getNum
}, (dux: IExposeRedux) => {
  return identityApi.acct.resendEmailVerificationToken.bind(identityApi.acct);
});
export const attemptResendVerifyEmail = resendVerifyEmail.go;
