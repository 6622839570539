
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import classNames from 'classnames'
import { RouteComponentProps } from 'react-router-dom'
import {
  UniConditionalRender,
  PermissionMapC,
  S10nModelC,
  ES10nModelType
} from '@unikey/unikey-commons/release/comm'

import {
  environment,
  api,
  mockAuth,
  SplashContainer,
  attemptRetrieveDealerDetails,
  attemptRetrieveAvailableSubscriptionModels,
  oidcLoginSuccess,
  wrapWithInsights,
  attemptRetrieveAuthUser
} from '../internal'


import partnerConfig from '@alias-current-partner-customizations'
export interface ILocaleDefinitions {
  [key: string]: any
}

export interface IContainerCustomization {
  props?: any,
  render?(): any,
  aboveRender?(): any,
  belowRender?(): any,
}

export interface ICustomContainerConfigs {
  [key: string]: IContainerCustomization
}

export interface IPartnerConfig {
  assets: {
    [key: string]: any
  },
  locales: {
    en?: ILocaleDefinitions,
    es?: ILocaleDefinitions
  },
  containers: () => ICustomContainerConfigs,
  // captchaSiteKey: string,
  // captchaSecretKey: string
}

const getPartnerOverrides = (containerKey: string): any => {
  const defaultOverride = {};
  const config: any = partnerConfig.containers();
  if (config && containerKey) {
    const containerConfig = config[containerKey];
    return containerConfig || defaultOverride;
  }
  // default override
  return defaultOverride;
}

export interface IPartnerCustomizations extends RouteComponentProps {
  props: any,
  activeDealerId: string,
  activeS10n: S10nModelC,
  reactPluginAppInsights: any,
  permissions?: PermissionMapC,
  currentExpiry?: string,
  tokenExpired?: boolean,
  tokenRefreshAttemptCompleted?: boolean,
  render(props?: any): any
  getAuthUser(ignoreCache: boolean): Promise<void>,
  getDealerDetails(dealerId: string): Promise<void>,
  setUserPermissionMap(permissions: PermissionMapC): void,
}

export interface IPartnerCustomizationOpts { 
  componentName: string,
  waitForToken?: boolean,
  allowWithoutDealer?: boolean,
  unauthenticated?: boolean
}


export const PartnerCustomizations = (WrappedComponent: React.ComponentType<any>, opts: IPartnerCustomizationOpts): any => {

  class CustomizedComponent extends Component<any> {
    partnerContainerConfig: any;
    extendedProps: any;
    
    constructor(props: any) {
      super(props);
      // Minimizinig for production builds affects the WrappedComponent.displayName here.
      // using specified componentName so that this works on obfuscated builds too.
      this.partnerContainerConfig = getPartnerOverrides(opts.componentName);
      this.extendedProps = Object.assign({}, props, this.partnerContainerConfig.props);

      if (!opts.unauthenticated && !props.permissions) {
        props.getAuthUser(false).then(() => {
          if (!props.activeDealerId && !opts.allowWithoutDealer) {
            props.getDealerDetails(api.getDealerId());
          }
        });
      }
      // we need to have fetched the subscriptions before we can reliably show anything
      if (!this.props.subscriptionRetreived) {
        this.props.getSubscriptionModels()
      }

    }

    render() {
      const customizedWrappedComponentClass = classNames({
        // default is {}
        'partner-custom': Object.keys(this.partnerContainerConfig).length !== 0 && environment === 'development'
      });

      const customizedAboveComponentClass = classNames({
        'partner-custom': !!this.partnerContainerConfig.aboveRender && environment === 'development'
      });
      const customizedBelowComponentClass = classNames({
        'partner-custom': !!this.partnerContainerConfig.belowRender && environment === 'development'
      });

      const aboveElems = this.partnerContainerConfig.aboveRender ? (
        <div className={customizedAboveComponentClass}>
          {this.partnerContainerConfig.aboveRender()}
        </div>
      ) : '';

      const belowElems = this.partnerContainerConfig.belowRender ? (
        <div className={customizedBelowComponentClass}>
          {this.partnerContainerConfig.belowRender()}
        </div>
      ) : '';

      const waitingOnToken = opts.waitForToken === true && !this.props.tokenRefreshAttemptCompleted && !mockAuth;
      return (
        <>
          {/* 
            * if the wrapped component should not be rendered until we have a valid token,
            * then show the spalsh screen while token is invalid
            */}
          <UniConditionalRender visible={waitingOnToken}>
            <SplashContainer 
              match={this.props.match}
              history={this.props.history} />
          </UniConditionalRender>

          {/* render wrapped component */}
          <UniConditionalRender visible={!waitingOnToken}>
            <>
              {aboveElems}
              <div className={customizedWrappedComponentClass}>
                <WrappedComponent
                  {...this.extendedProps}
                  render={this.partnerContainerConfig.render}>
                  {this.partnerContainerConfig.children}
                </WrappedComponent>
              </div>
              {belowElems}
            </>
          </UniConditionalRender>
        </>
      )
    }
  }

  const mapStateToProps = (state: any) => {
    return {
      activeDealerId: state.portal.activeDealer.id,
      activeS10n: state.subscriptionInfo.modelLookup.get(state.dealer.dealerData.subscriptionModel),
      permissions: state.authenticatedUser.currentUser?.permissions,
      subscriptionRetreived: !!state.subscriptionInfo.defaultType,
      
      tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted, 
      currentExpiry: state.oidcAuthStatus.currentExpiry,
      tokenExpired: state.oidcAuthStatus.isExpired
    }
  }

  const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    getDealerDetails: attemptRetrieveDealerDetails,
    getSubscriptionModels: attemptRetrieveAvailableSubscriptionModels,
    oidcLoginSuccess,
    getAuthUser: attemptRetrieveAuthUser
  }, dispatch)

  const containerName: string = opts.componentName;
  return connect(mapStateToProps, mapDispatchToProps)(wrapWithInsights(CustomizedComponent, containerName));
}