import { ICredentialC, UniKeyV1CredentialC, ICredentialEncodeAndBuild, IPartnerCredentialFields, ICredentialFieldConfig, ICredentialFieldConfigOptions, IValidationConfig, emailV10n } from '@unikey/unikey-commons/release/comm'

const credDataBitLength = 38;
export class HnyUniKeyV1Credential extends UniKeyV1CredentialC {

  // custom encode for honeywell's UniKeyV1Credential implementation
  static encode(cred: ICredentialEncodeAndBuild): ICredentialEncodeAndBuild {
    const rawData: string[] = [];

    const facilityCodeBits = padLeft(`${integerToDecimal(cred.facilityCode!)}`, 4, '0');
    let i;
    let j;
    for (i = 1, j = 0; i < 5; i++, j++) {
      rawData[i] = facilityCodeBits[j];
    }

    const cardNumberBits = padLeft(`${integerToDecimal(Number(cred.cardNumber!))}`, 32, '0');
    let k;
    let l;
    for (k = 5, l = 0; k < 37; k++, l++) {
      rawData[k] = cardNumberBits[l];
    }

    rawData[0] = calculateParityBit(true, rawData.slice(1, 19));
    rawData[credDataBitLength - 1] =
      calculateParityBit(false, rawData.slice(18, credDataBitLength - 1));

    cred.credentialDataBitLength = credDataBitLength;
    cred.credentialData = convertArrayOfCharsRespresentingBitsToABase64EncodedString(rawData);
    return cred;
  }

  static getCredentialConfig(): Partial<IPartnerCredentialFields> {
    return {
      email: (): Partial<ICredentialFieldConfig> => {
        return {
          validations: emailV10n
        };
      },
      facilityCode: (): Partial<ICredentialFieldConfig> => {
        return {
          value: 15,
          disabled: true,
          validations: {
            min: 15,
            max: 15,
            message: '15'
          }
        }
      },
      cardNumber: (opts: ICredentialFieldConfigOptions = {}): Partial<ICredentialFieldConfig> => {
        let validations: IValidationConfig;
        // hny has special case where they have different
        // allowable card numbers for installer vs "user"
        if (opts.isInstaller) {
          validations = {
            min: 2500000001,
            max: 2500000999,
            message: 'valueBetween2500000001_2500000999'
          }
        } else {
          validations = {
            min: 2500001000,
            max: 4294967295,
            message: 'valueBetween2500001000_4294967295'
          }
        }
        return {
          validations
        };
      },
      requiredFields: () => new Set(['email', 'cardNumber', 'facilityCode'])
    }
  }

  static decode(cred: ICredentialEncodeAndBuild): ICredentialEncodeAndBuild {
    // rest from the generic impl of UniKeyV1 Credentials in the commons section
    const numberOfBytes = Math.ceil(credDataBitLength / 8) + 1;
    let stringOfBits = '';

    for (var i = 0; i < numberOfBytes; i++) {
      stringOfBits += padLeft(atob(cred.credentialData!)[i].charCodeAt(0).toString(2), 8, '0');
    }

    const facilityCodeBits: string = stringOfBits.slice(9, 13);
    const cardNumberBits: string = stringOfBits.slice(13, 45);

    cred.facilityCode = parseInt(facilityCodeBits, 2);

    // wrapped in string for needs
    cred.cardNumber = parseInt(cardNumberBits, 2);
    return cred;
  }

  constructor(cred: ICredentialC) {
    super(cred)
  }

  decode(): HnyUniKeyV1Credential {
    const decoded = HnyUniKeyV1Credential.decode(this as ICredentialEncodeAndBuild);

    this.facilityCode = decoded.facilityCode;
    this.cardNumber = decoded.cardNumber;

    return this;
  }
}

// below stolen from an earlier iteration of the dealer portal
function calculateParityBit(isEven: boolean, arrayOfBits: string[]): string {
  let countOf1s = 0;
  let i;
  for (i = 0; i < arrayOfBits.length; i++) {
    if (arrayOfBits[i] === '1') {
      countOf1s++;
    }
  }

  return countOf1s % 2 === (isEven ? 0 : 1) ? '0' : '1';
}

function padLeft(stringToPad: string, width: number, charToPadWith: string) {
  return (String(charToPadWith).repeat(width) + String(stringToPad)).slice(String(stringToPad).length)
}

function padRight(stringToPad: string, width: number, charToPadWith: string) {
  return (String(stringToPad) + String(charToPadWith).repeat(width)).slice(0, width)
}

function integerToDecimal(dec: number): string {
  return (dec >>> 0).toString(2);
}

function convertArrayOfCharsRespresentingBitsToABase64EncodedString(rawData: any): string {
  const numberOfBytes = Math.ceil(rawData.length / 8);
  const byteBuffer = new Array(numberOfBytes);
  for (let i = 0; i < numberOfBytes; i++) {
    const startIndex = i * 8;
    const endIndex = (i + 1) * 8;

    const stringOfBytes = rawData.slice(startIndex, endIndex).join('');
    const paddedStringOfBytes = padRight(stringOfBytes, 8, '0');

    const parsedByteStringAsInt = parseInt(paddedStringOfBytes, 2);
    byteBuffer[i] = parsedByteStringAsInt;
  }
  return btoa(String.fromCharCode.apply(null, byteBuffer));
}
