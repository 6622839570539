import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom'

import {
  darkThemeName,
  UniWorkflow,
  UniImg,
  UniLocalize,
  UniConditionalRender,
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';
import {
  FooterContainer,
  partnerKey,
} from '../internal';

interface IProps extends WrappedComponentProps, RouteComponentProps {
  darkModeTheme: string,
}

class OopsContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    return (
      <section className='access-container'>
        <section className='oops-container'>
          {/* access flow should conform to max width restrictions for massive screens */}
          <Container>

            <UniImg className={imgClassName} textKey="partner logo" src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />

            <UniWorkflow
              titleKey="whoops"
              titleIcon="sentimentDissatisfied"
              size="wider" >

              <p><strong><UniLocalize translate='_somethingWentWrong' /></strong></p>

              <UniConditionalRender visible={this.props.match.params.reason === "404"} >
                <h2><UniLocalize translate="_error404Code" /></h2>
                <p><UniLocalize translate="_error404Message" /></p>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.match.params.reason === "403"} >
                <h2><UniLocalize translate="_error403Code" /></h2>
                <p><UniLocalize translate="_error403Message" /></p>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.match.params.reason === "emailtemplate"} >
                <h2><UniLocalize translate="_errorEmailCode" /></h2>
                <p><UniLocalize translate="_errorEmailMessage" /></p>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.match.params.reason !== "403" && this.props.match.params.reason !== "404" && this.props.match.params.reason !== "emailtemplate"} >
                <h2><UniLocalize translate="_error404Code" /></h2>
                <p><UniLocalize translate="_error404Message" /></p>
              </UniConditionalRender>

              <div className="below-workflow">
                <a className="link-left" href="/#/login"><UniLocalize translate="goToLogin" /></a>
              </div>
            </UniWorkflow>
          </Container>
        </section>
        <FooterContainer />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    darkModeTheme: state.portal.darkModeTheme,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OopsContainer, { forwardRef: true }))
