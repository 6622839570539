import {
  IPaginationQueryBuilderParamsC,
  IUniTable_Sort,
  IUniTable_UpdatePaginationSummary
} from '@unikey/unikey-commons/release/comm'

import { 
  api
} from '../internal'


const pageSizeCacheKey = 'queryParamsPageSize';
export const setPageSizeInCache = (pageSize?: number) => {
  if (Number.isInteger(pageSize)) {
    api.cacheImpl.set(pageSizeCacheKey, pageSize);
  }
  return;
}

export const getPageSizeFromCache = (): number => {
  const pageSize = api.cacheImpl.get(pageSizeCacheKey);
  const numericPageSize = Number(pageSize);
  return numericPageSize ?? undefined;
}


export const initialPageSize = getPageSizeFromCache() || 10; // default page size

export const getTableSortDirection = (qps: IPaginationQueryBuilderParamsC): 'asc'| 'desc' => {
  if (qps && qps.sortField) {
    return qps.reverse ? 'asc' : 'desc';
  }
  return 'desc';
}

export const getTableParamsFromUpdate = (update: IUniTable_UpdatePaginationSummary): IPaginationQueryBuilderParamsC => {
  const params: IPaginationQueryBuilderParamsC = {};

  // make sure to update the name param even if the string is empty
  if (update.filters) {
    // commercial only allows one search query, so we're only looking at the first filter
    //  and the first value of that filter
    if (update.filters[0] && update.filters[0].value && update.filters[0].value) {
      params.searchText = `in:${update.filters[0].value}`;
      if (update.filters[0] && update.filters[0].filterKey) {
        params.searchField = update.filters[0].filterKey;
      }
    } else if (update.filters.length === 0) {
      params.searchField = '';
      params.searchText = '';
    }
  }

  if (update.sorts) {
    const sort: IUniTable_Sort = update.sorts[0];
    if (sort) {
      params.sortField = sort.column;
      params.reverse = sort.direction === 'asc';
    }
  }

  if (update.currPage) {
    params.pageNum = update.currPage;
  }

  if (update.pageSize) {
    params.pageSize = Number(update.pageSize);
  }

  return params;
}

export type TTableUpdateFunc = (update: IUniTable_UpdatePaginationSummary) => void;
export const buildTableUpdateFunc = (
  fetchTableData: (queryParams: IPaginationQueryBuilderParamsC) => Promise<void>,
  updateTableMeta: (metaSummary: IUniTable_UpdatePaginationSummary) => void, 
  updateQueryParams?: (params: IPaginationQueryBuilderParamsC) => void,
  ): TTableUpdateFunc  => {
    return (update: IUniTable_UpdatePaginationSummary) => {
      if (updateQueryParams) {
        const params = getTableParamsFromUpdate(update);
        updateTableMeta(update);
        updateQueryParams(params);
        return fetchTableData(params);
      }
    }
  };