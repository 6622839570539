import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'
import classNames from 'classnames';
import {
  darkThemeName,
  UniWorkflow,
  UniImg,
  UniInput,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
  Editable,
  IMultiInputUpdate,
  commPasswordRequirementsV10n,
  passwordMatchV10n
} from '@unikey/unikey-commons/release/comm';
import partnerConfig from '@alias-current-partner-customizations';

import {
  FooterContainer,
  attemptChangePassword,
  updatePasswordForm,
  addAlert,
  environment, partnerKey,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  loading: boolean,
  changedSuccessfully: boolean,
  password: Editable,
  confirmPassword: Editable,
  hideRegisterLink?: boolean,
  darkModeTheme: string,
  handleFormChange(formChanges: IMultiInputUpdate): void,
  sendPasswordChangeRequest(): Promise<void>,
}
class LegacyChangePasswordContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  componentDidMount() {
    this.props.handleFormChange({ token: new Editable({ value: this.props.match.params.passwordToken, valid: true }) });
  }
  _sendPasswordChange = () => {
    this.props.sendPasswordChangeRequest()
  }
  render() {
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });
    return (
      <section className='access-container'>
        <section className='changePassword-container'>
          {/* access flow should conform to max width restrictions for massive screens */}
          <Container>
            <UniImg textKey="partner logo" className={imgClassName} src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
            <UniWorkflow
              titleKey="resetPassword"
              titleIcon="fingerprint"
              size="wider">
              <p><UniLocalize translate='_resetPasswordInstructions' /></p>
              <UniInput
                editable={this.props.password}
                type="password"
                labelKey="password"
                placeholderKey="password"
                focusOnInitialRender={true}
                disabled={this.props.changedSuccessfully}
                handleUpdate={(password: Editable) => this.props.handleFormChange({ password })}
                validations={new Map([commPasswordRequirementsV10n])} />
              <UniInput
                editable={this.props.confirmPassword}
                type="password"
                labelKey="confirmPassword"
                placeholderKey="confirmPassword"
                disabled={this.props.changedSuccessfully}
                handleUpdate={(confirmPassword: Editable) => this.props.handleFormChange({ confirmPassword })}
                validations={new Map([passwordMatchV10n(this.props.password.value)])} />
              <UniOverlapGroup foldEarly={true}>
                <UniOverlapButton
                  handleClick={this._sendPasswordChange}
                  textKey="setPassword"
                  icon="send"
                  showLoader={!!this.props.loading}
                  disabled={!this.props.password.valid || !this.props.confirmPassword.valid || this.props.password.value === '' || this.props.changedSuccessfully} />
              </UniOverlapGroup>
              <div className="below-workflow">
                <a className="link-left" href="/#/login"><UniLocalize translate="goToLogin" /></a>
                <UniConditionalRender visible={!this.props.hideRegisterLink}>
                  <a className="link-right" href="/#/register"><UniLocalize translate="createAccount" /></a>
                </UniConditionalRender>
              </div>
            </UniWorkflow>
          </Container>
        </section>
        <FooterContainer />
      </section>
    )
  }
}
function mapStateToProps(state: any) {
  return {
    password: state.forgotForm.password,
    confirmPassword: state.forgotForm.confirmPassword,
    changedSuccessfully: state.forgotForm.changeSuccess,
    loading: state.forgotForm.loading,
    darkModeTheme: state.portal.darkModeTheme
  };
}
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  sendPasswordChangeRequest: attemptChangePassword,
  handleFormChange: updatePasswordForm,
}, dispatch);
export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(LegacyChangePasswordContainer, { forwardRef: true })
  ), { componentName: 'ChangePassword', unauthenticated: true }
)