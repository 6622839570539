import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'pco';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://rlk-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://rlk-stg-dealer-api.unikey.com', 'v5', 'pco'.toUpperCase(), { enableLogging: environment === 'development', preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', '56f7b662-42bd-416f-a12d-10895c76b06a', 'xvkSBLKbMqoCXrueA9SHfuJohjE1Scjp0VO2oaAz5N/4kNvaO9BoA4o6WVdr6TLmKV1jn/qvDJ3z7CDEGvD+vQ==', { enableLogging: environment === 'development' && false, preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint });

export const captchaSiteKey: string = '6LfnD6oUAAAAALMUpjuymx7HVIR6drj2D7y_wa6g';
export const captchaSecretKey: string = '6LfnD6oUAAAAAA0wFppYKFQ-pOt0aXp0jQvwI_Bk';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://www.unikey.com/lets-connect/';
export const deeplinkDomain = 'https://pco-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = '38cf5279-ad2d-4619-b4a0-9852abadcf95';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/SimpleAccess-Staging';
export const googlePlayStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/SimpleAccess-Staging-1';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = '56f7b662-42bd-416f-a12d-10895c76b06a';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://pco-stg-web.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'xvkSBLKbMqoCXrueA9SHfuJohjE1Scjp0VO2oaAz5N/4kNvaO9BoA4o6WVdr6TLmKV1jn/qvDJ3z7CDEGvD+vQ==';
export const oidcPartnerBrandId: string = '5608834d-7a83-40df-99c2-4081fea5ab77';
export const oidcPartnerBrandCode: string = 'PCO';
export const oidcSilentRedirectUri: string = 'https://pco-stg-web.unikey.com/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '33792';
export const releaseId: string = 'Release-279';
export const cdpVersion: string = '5.3.1';
export const commonsVersion: string = '7.5.1';
export const desiredLocales: string = 'en,es,ja,fr,pt';