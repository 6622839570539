import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniConfirm,
  UniLocalize,
  UniKeyVal,
  UniConditionalRender,
  UniOverflowActions,
  IUniConfirm_Config,
  IUniMenu_ItemConfig,
  IUniChips_Chip,
  UserC,
  OrganizationC,
  EOperationCodesC,
  IUniKeyVal_FieldConfig,
  emptyGuid
} from '@unikey/unikey-commons/release/comm';

import {
  portalRedirect,
  canI,
  IRemoveAdminActionParams,
  IGetAdminActionParams,
  attemptRetrieveAdminDetails,
  attemptRetrieveAdminScope,
  attemptRetrieveAdminInvitationScope,
  attemptResendAdminInvite,
  attemptGetAdminInviteById,
  attemptDeleteDealerAdminInvite,
  togglePermissionManagerModal,
  attemptRemoveAdmin,
  openConfirmModal,
  closeConfirmModal,
  PartnerCustomizations, IPartnerCustomizations,
  AdminPermissionsEditContainer,
  navConfig,
  ENavPages,
  IDeleteInviteActionParams
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  loading: boolean,
  adminDetails: UserC,
  resentInviteSuccess: boolean,
  resentInviteLoading: boolean,
  permissionToCreateAdmin: boolean,
  permissionToEditAdminRole: boolean,
  permissionToRemoveAdmin: boolean,
  adminPermissions: any,
  isLimitedAdmin: boolean,
  adminPermissionManagerModalOpen: boolean,
  togglePermissionManagerModal(): void,
  resendInvite(inviteId: string): void,
  getAdminInvite(inviteId: string): void,
  getAdminUserScope(adminId: string): void,
  getAdminInvitationScope(invitationId: string): void,
  getAdmin(params: IGetAdminActionParams): Promise<UserC>,
  removeAdmin(params: IRemoveAdminActionParams): Promise<void>,
  deleteInvite(params: IDeleteInviteActionParams): Promise<void>,
  openRemoveAdminConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
}

class AdminDetailsContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // The `params.adminId` can sometimes be a urlsafe admin email 
    // (due to having an unaccepted admin invite and not knowing the user id)
    // before passing it to the get admin function, we need to url decode
    const decodedIdentifier = (decodeURIComponent(this.props.match.params.adminId) as string);
    var findParams = {};
    if (decodedIdentifier.includes('@')) {
      findParams = { adminEmail: decodedIdentifier };
    } else {
      // if it does not contain an @ symbol, then it is a user id
      // for this case, we fetch using the user guid instead of email.
      findParams = { adminId: decodedIdentifier };
    }
    this.props.getAdmin(findParams).then((currAdmin: UserC) => {
      if (!currAdmin.id || currAdmin.id === emptyGuid) {
        this.props.getAdminInvitationScope(currAdmin.invitationId!);
      } else {
        this.props.getAdminUserScope(currAdmin.id!);
      }

      if (!currAdmin.invitation) {
        this.props.getAdminInvite(currAdmin.invitationId!)
      }
    });
  }

  _resendInvite = async () => {
    const inviteId = this.props.adminDetails.invitationId;
    await this.props.resendInvite(inviteId!);
    await this.props.getAdminInvite(inviteId!);
  }

  _removeAdminAndBackToList = () => {
    return this.props.removeAdmin({
      adminId: this.props.adminDetails.id!,
      dealerId: this.props.activeDealerId
    }).then(() => {
      portalRedirect(navConfig.get(ENavPages.admins)!.linkTo()!)
    })
  }

  _deleteInviteAndBackToList = () => {
    return this.props.deleteInvite({
      inviteId: this.props.adminDetails.invitationId!, 
      dealerId: this.props.activeDealerId
    }).then(() => {
      portalRedirect(navConfig.get(ENavPages.admins)!.linkTo()!)
    })
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const orgChips = new Map<string, IUniChips_Chip<OrganizationC>>();
    this.props.adminPermissions.organizations.forEach((org: OrganizationC) => {
      orgChips.set(`org-$${org.id}`, {
        nameKey: org.name,
        ref: org,
        disabled: false
      });
    });

    // setup the fields for the admin permissions keyVal
    const adminPermissionsFields: IUniKeyVal_FieldConfig[] = [
      {
        keyName: 'permissionLevel',
        value: this.props.isLimitedAdmin ? 'limitedAdmin' : 'fullAdmin',
        type: 'string',
      }
    ];
    // only add the orgs chips if the user is a limitedAdmin
    if (this.props.isLimitedAdmin) {
      adminPermissionsFields.push({
        keyName: 'organizationScope',
        value: 'test',
        collection: orgChips,
        type: 'chips',
      });
    }

    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('managePermissions', {
        textKey: 'managePermissions',
        handleClick: () => this.props.togglePermissionManagerModal(),
        hidden: !this.props.permissionToEditAdminRole
      })
      .set('removeAdmin', {
        textKey: 'removeAdmin',
        theme: 'error',
        disabled: this.props.adminDetails.id === emptyGuid,
        hidden: !this.props.permissionToRemoveAdmin,
        handleClick: () => this.props.openRemoveAdminConfirmDialog({
          titleKey: 'removeAdmin',
          titleIcon: 'delete',
          messageKeys: ['youAreAboutToRemoveThisAdmin', this.props.adminDetails.getFirstName(), 'areYouSure'],
          confirmTextKey: 'remove',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._removeAdminAndBackToList()
            this.props.closeConfirmModal()
          }
        })
      })
      .set('disableInvitation', {
        textKey: 'disableInvitation',
        theme: 'error',
        hidden: !this.props.permissionToRemoveAdmin || (this.props.adminDetails.id !== emptyGuid && this.props.adminDetails.status !== 'Invited'),
        handleClick: () => this.props.openRemoveAdminConfirmDialog({
          titleKey: 'disableInvitation',
          titleIcon: 'delete',
          messageKeys: ['youAreAboutToDisableThisInvitation', 'areYouSure'],
          confirmTextKey: 'disable',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._deleteInviteAndBackToList()
            this.props.closeConfirmModal()
          }
        }),
      });
      
    return (
      <section className='adminDetails-container'>
        <Row justify="between">
          <Col>
            <h3 className="admin-details-title"><UniLocalize translate="adminDetails" /></h3>
          </Col>
          <Col xs={3} lg={2}>
            <UniOverflowActions
              className="title-actions"
              nameKey="accountActions"
              icon="arrowDropDownCircle"
              actions={actionsMenuContents}
              allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
              theme="primary" />
          </Col>
        </Row>

        <UniKeyVal
          label="adminDetails"
          showLoader={this.props.loading}
          preventEdit={true}
          allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
          fields={[
            {
              keyName: 'firstName',
              value:  this.props.adminDetails.firstName ?? 'n/a',
              type: 'string',
              preventTranslate: true
            },
            {
              keyName: 'lastName',
              value: this.props.adminDetails.lastName ?? 'n/a',
              type: 'string',
              preventTranslate: true
            },
            {
              keyName: 'email',
              value: '' + this.props.adminDetails.username,
              type: 'string',
              preventTranslate: true
            },
            {
              keyName: 'status',
              value: '' + this.props.adminDetails.status,
              type: 'string',
            },
          ]} />


        <Row>
          <Col>
            <h3 className="admin-invite-details-title"><UniLocalize translate="invitation" /></h3>
          </Col>
        </Row>

        <UniKeyVal
          key={`${this.props.resentInviteLoading}`}
          label="adminInvite"
          showLoader={this.props.loading}
          preventEdit={this.props.adminDetails.status === 'Active' || this.props.resentInviteSuccess}
          allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
          primaryStateButtonSet={[{
            textKey: 'resendInvite',
            icon: 'send',
            disabled: this.props.resentInviteSuccess, // not working so using preventEdit instead
            showLoader: this.props.resentInviteLoading,
            clickHandler: this._resendInvite,
          }]}
          fields={[
            {
              keyName: 'identifier',
              value: '' + this.props.adminDetails.invitationId,
              type: 'string',
              hidden: true
            },
            {
              keyName: 'sentFromAdmin',
              value: `${this.props.adminDetails?.invitation?.inviterEmail}`,
              type: 'string',
            },
            {
              keyName: 'created',
              value: `${this.props.adminDetails?.invitation?.created}`,
              type: 'date',
            }
          ]} />


        <Row>
          <Col>
            <h3 className="admin-permissions-details-title"><UniLocalize translate="adminPermissions" /></h3>
          </Col>
        </Row>

        <UniKeyVal
          label="adminPermissions"
          showLoader={this.props.adminPermissions.loading}
          primaryStateButtonSet={[
            {
              textKey: 'manageAdminPermissions',
              icon: 'edit',
              clickHandler: () => { this.props.togglePermissionManagerModal(); return Promise.resolve() },
            }
          ]}
          secondaryStateButtonSet={[]}
          preventEdit={!this.props.permissionToEditAdminRole} // TODO: check this permission to make sure it is the right one
          fields={adminPermissionsFields} />

        {/* Workflow Modal */}
        <UniConditionalRender visible={this.props.adminPermissionManagerModalOpen}>
          <AdminPermissionsEditContainer />
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    loading: state.adminDetails.loading,
    adminDetails: state.adminDetails.adminData,
    resentInviteSuccess: state.adminDetails.inviteResend.success,
    resentInviteLoading: state.adminDetails.inviteResend.loading,
    adminPermissions: state.adminDetails.adminPermissions,
    isLimitedAdmin: !state.adminDetails.adminPermissions.isOwner,
    adminPermissionManagerModalOpen: state.adminDetails.permissionsEditor.modalOpen,
    // permissions
    permissionToRemoveAdmin: canI(EOperationCodesC.DeleteAdmin, state.dealer.dealerData.id),
    permissionToEditAdminRole: canI(EOperationCodesC.CreateRoleUser, state.dealer.dealerData.id) || canI(EOperationCodesC.DeleteRoleUser, state.dealer.dealerData.id)
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => bindActionCreators({
  getAdmin: attemptRetrieveAdminDetails,
  getAdminInvite: attemptGetAdminInviteById,
  getAdminUserScope: attemptRetrieveAdminScope,
  getAdminInvitationScope: attemptRetrieveAdminInvitationScope,
  resendInvite: attemptResendAdminInvite,
  deleteInvite: attemptDeleteDealerAdminInvite,
  removeAdmin: attemptRemoveAdmin,
  openRemoveAdminConfirmDialog: openConfirmModal,
  togglePermissionManagerModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminDetailsContainer)
  ), { componentName: 'AdminDetails' })
