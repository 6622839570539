import { Path, LocationDescriptor } from 'history'

import {
  storeHistory,
  devLogger
} from "../internal";

export const getCurrentBaseDomain = () => {
  // split by any query params and hashes 
  // only return the first leading part (the domain)
  return window.location.href.split(/[\?\#]/)?.[0];
}

type IHistoryPushArgs = Path | LocationDescriptor<any>;
export const portalRedirect = (config: IHistoryPushArgs) => {
  devLogger(`portal redirect to: ${JSON.stringify(config)}`);
  storeHistory.push(config);
}