import * as jwt from 'jose'

import {
  IUniToast_Alert,
  EMessageType
} from '@unikey/unikey-commons/release/comm'

import {
  devLogger
} from '../internal'

type IAlerter = (config: IUniToast_Alert) => void;

export const checkJWTExpired = (decodedToken: any, alerter?: IAlerter): boolean => {
  const nowMs = Date.now();
  // ensure token is still within the pre-expiration period
  const isExpired = (decodedToken.exp * 1000) < nowMs;
  if (isExpired && alerter) {
    alerter({
      id: Date.now(),
      type: EMessageType.warn,
      titleKey: 'tokenExpired',
      messageKeys: ['_tokenExpiredExplination']
    });
  }
  return isExpired;
}

export const getJWTExpiryString = (decodedToken: any) => {
  return new Date(decodedToken.exp * 1000).toLocaleString();
}

// in memory map of parsed jwt so that we dont re-parse each time we need it
const decodedJwtMap = new Map();
export const decodeJWT = (rawJwt: string): any => {
  devLogger(' - decoding token: ' + rawJwt.slice(-10));
  if (decodedJwtMap.has(rawJwt))  {
    devLogger(' -- found decoded token already in memory.')
    return decodedJwtMap.get(rawJwt);
  }
  else {
    const decoded = jwt.decodeJwt(rawJwt);
    if (decoded) {
      decodedJwtMap.set(rawJwt, decoded);
      return decoded;
    } 
    else {
      decodedJwtMap.set(rawJwt, null);
      return null;
    }

  }
}