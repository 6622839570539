import React, { Component } from 'react'
import { IContainerCustomization } from '../PartnerCustomizations'
import { PdkUniKeyV1Credential } from './models/pdkU1Credential'
import {
  UniKeyV1CredentialC,
  RawCredentialC,
  WiegandCredentialC,
  Corp1000CredentialC,
  TSBnFCredentialC,
  TSBwFCredentialC,
  ECredentialType,
  IPartnerCredImpls
} from '@unikey/unikey-commons/release/comm'

export default () => {

  const dealerSkinningOffered = false;

  const partnerCredImpls: IPartnerCredImpls = {
    PartnerRCred: RawCredentialC,
    PartnerWCred: WiegandCredentialC,
    PartnerU1Cred: PdkUniKeyV1Credential,
    PartnerC1000Cred: Corp1000CredentialC,
    Partner37BwFCred: TSBwFCredentialC,
    Partner37BnFCred: TSBnFCredentialC,
  };

  const partnerCredImplAvailability: ECredentialType[] = [
    ECredentialType.unikeyV1,
    ECredentialType.raw,
  ];

  const CredentialCreate: IContainerCustomization = {
    props: {
      partnerCredImplAvailability,
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.unikeyV1,
      partnerCsvExampleTemplate: (
        <>
          <p>EMAIL,CARD_NUMBER,TYPE</p>
          <p>address1@example.com,12345,UnikeyV1</p>
          <p>address2@example.com,54321,</p>
        </>
      )
    }
  }

  const CredentialList: IContainerCustomization = {
    props: {
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.unikeyV1,
    }
  }

  const CredentialDetails: IContainerCustomization = {
    props: {
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.unikeyV1
    }
  }

  const DeviceList: IContainerCustomization = {
    props: {
      partnerCredImpls
    }
  }
  const DealerRegisterForm: IContainerCustomization = {
    props: {
      dealerSkinningOffered
    }
  }
  
  const DealerDetails: IContainerCustomization = {
    props: {
      dealerSkinningOffered
    }
  }

  const Portal: IContainerCustomization = {
    props: {
      dealerSkinningOffered
    }
  }

  return {
    Portal,
    CredentialCreate,
    CredentialList,
    CredentialDetails,
    DealerRegisterForm,
    DealerCreate: DealerRegisterForm,
    DeviceList,
    DealerDetails,
  }
}
