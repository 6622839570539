import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Container, Row, Col } from 'react-grid-system'

import {
  DealerC,
  EMessageType,
  darkThemeName,
  UniMessage,
  UniButton,
  UniImg,
  UniWorkflow,
  UniInput,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
  InviteC,
  Editable,
  EInviteStatusC,
  AuthUserC,
  IUniToast_Alert,
  notBlankV10n, commPasswordRequirementsV10n, passwordMatchV10n,
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';
import {
  api,
  addAlert,
  FooterContainer,
  attemptRetrieveAuthUser,
  attemptRetrieveAuthUserOptional,
  attemptAcceptAdminInviteAsExistingUser,
  attemptRetrieveAdminInviteByToken,
  setActivePortalDealer,
  PartnerCustomizations, IPartnerCustomizations,
  portalRedirect,
  partnerKey,
  redirectToLogin,
  navConfig,
  ENavPages,
  IAcceptInviteActionParams,
  requestRefreshToken,
  oidcRefreshSuccess,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  firstName: Editable<string>,
  lastName: Editable<string>,
  password: Editable<string>,
  passwordConfirm: Editable<string>,
  firstNameValid: boolean,
  lastNameValid: boolean,
  passwordValid: boolean,
  passwordConfirmValid: boolean,
  loading: boolean,
  inviteData: InviteC,
  validCaptcha: boolean,
  darkModeTheme: string,
  authUser: AuthUserC,
  authUserLoading?: boolean,
  getCurrentUserOptional(ignoreCache: boolean): Promise<AuthUserC>,
  refreshSuccess(refreshedUserToken: any): void,
  redirectToPortal(): void,
  redirectToLoginWithContinue(): void,
  acceptInvite(params: IAcceptInviteActionParams): Promise<void>,
  handlePasswordChange(change: Editable): void,
  handlePasswordConfirmChange(change: Editable): void,
  handleFirstNameChange(change: Editable): void,
  handleLastNameChange(change: Editable): void,
  getInvitationDetails(token: string): void,
  setActivePortalDealer(dealer: DealerC, switchingDealers?: boolean): void,
  addAlert(config: IUniToast_Alert): void,
}

class AdminInvitationAcceptForm extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getCurrentUserOptional(true);
    if (!this.props.inviteData.inviterEmail) {
      this.props.getInvitationDetails(decodeURIComponent(this.props.match.params.inviteToken));
    }
    if (this.props.inviteData.status === EInviteStatusC.expired) {
      this.props.addAlert({
        id: Date.now(),
        titleKey: 'invitationExpired',
        type: EMessageType.error,
        messageKeys: ['invitationHasExpired']
      })
    } else if (this.props.inviteData.status === EInviteStatusC.revoked || this.props.inviteData.status === EInviteStatusC.accepted || this.props.inviteData.status === EInviteStatusC.declined) {
      this.props.addAlert({
        id: Date.now(),
        titleKey: 'inviteNotAvailable',
        type: EMessageType.error,
        messageKeys: ['inviteNoLongerValid']
      })
    }
  }

  _acceptInvite = async () => {
    await this.props.acceptInvite({
      token: this.props.match.params.inviteToken,
      dealerId: this.props.inviteData.dealer!.id!
    })
    // then refresh the access token and reload the user after the dealer is set
    await requestRefreshToken(true, this.props.refreshSuccess);
    const authUser = await this.props.getCurrentUserOptional(true);
    // load the dealer into redux and redirect to the portal landing page with that dealer set as the active dealer.
    const dealer = authUser.getPreferredDealer(api.cacheImpl);
    if (dealer) {
      this.props.setActivePortalDealer(dealer, true);
    }
    portalRedirect(navConfig.get(ENavPages.portalLanding)!.linkTo([]));

  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    const authUserBelongsToInvitedDealer = this.props.authUser?.dealers?.some(d => d?.id === this.props.inviteData.dealer?.id) ?? false;
    const formValid: boolean = this.props.inviteData.status < EInviteStatusC.accepted && this.props.firstNameValid && this.props.lastNameValid && this.props.passwordValid && this.props.passwordConfirmValid;
    return (
      <section className='access-container'>
        <section className='admin-register-container'>
          {/* access flow should conform to max width restrictions for massive screens */}
          <Container>
            <UniImg textKey="partner logo" className={imgClassName} src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
            <UniWorkflow
              className="accept-invite-workflow"
              titleKey="acceptAdminInvite"
              titleIcon="accountCircle"
              size="wider">
              <UniConditionalRender visible={this.props.inviteData.status < EInviteStatusC.accepted} >
                {/* not logged in */}
                <UniConditionalRender visible={!this.props.authUser?.id}>
                  <UniLocalize translate="signInToYourAccountToAcceptEKeys"/>
                </UniConditionalRender>
                
                {/* accept message */}
                <UniConditionalRender visible={!!this.props.authUser?.id}>
                  <p><UniLocalize translate="_adminAcceptMessage" /></p>
                </UniConditionalRender>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.inviteData.status >= EInviteStatusC.accepted}>
                <UniMessage 
                  type={EMessageType.warn}
                  titleKey="inviteInvalid"
                  messageKeys={['_adminInviteInvalidMessage']} />
              </UniConditionalRender>

              {/* Ok to not use editable since this is disabled always */}
              <UniInput
                value={this.props.inviteData.inviterEmail}
                labelKey="invitedBy"
                placeholderKey="email"
                disabled={true} />
              <UniInput
                value={this.props.inviteData.inviteeEmail}
                labelKey="sentTo"
                placeholderKey="sentTo"
                disabled={true} />

              <UniConditionalRender visible={!!this.props.inviteData.dealer?.name}>
                <UniInput
                  value={this.props.inviteData.dealer?.name?.value}
                  labelKey="dealerName"
                  placeholderKey="dealerName"
                  disabled={true} />
              </UniConditionalRender>

            </UniWorkflow>

            <UniConditionalRender visible={!this.props.authUserLoading}>
              <UniConditionalRender visible={this.props.inviteData.status < EInviteStatusC.accepted && !authUserBelongsToInvitedDealer}>
                    
                {/* user is not logged in */}
                <UniConditionalRender visible={!this.props.authUser?.id}>
                  <Row>
                    <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                      <UniButton
                        className="admin-invite-accept-button"
                        handleClick={() => this.props.redirectToLoginWithContinue()}
                        textKey="signInToAccept"
                        tooltipPosition="top" />
                    </Col>
                  </Row>
                
                  <Row>
                    <Col xs={12}>
                      <br/>
                      <p className="text-center"><UniLocalize translate="dontHaveAnAccount" /></p>
                    </Col>

                    <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                      <UniButton
                        className="admin-invite-accept-button"
                        handleClick={() => this.props.redirectToLoginWithContinue()}
                        textKey="registerToAccept"
                        theme="secondary"
                        tooltipPosition="top" />
                    </Col>
                  </Row>
                </UniConditionalRender>

                {/* user is logged in and not the sender */}
                <UniConditionalRender visible={!!this.props.authUser?.id}>
                  <Row>
                    <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                      <UniButton
                        className="admin-invite-accept-button"
                        icon="check"
                        handleClick={this._acceptInvite}
                        textKey={this.props.intl.formatMessage(
                          { id: '_acceptInvitationAs[userFullName]' }, 
                          { 'userFullName': this.props.authUser?.getFullName?.() }
                        )}
                        // disabled={this.props.authUser?.id === this.props.eKeyToken?.grantor?.id}
                        disabledReasonKeys={['cannotAcceptFromTheSenderAccount']}
                        tooltipPosition="top" >
                          <br/>
                          ({this.props.authUser?.username})
                      </UniButton>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                      <UniButton
                        className="admin-invite-accept-button"
                        textKey={this.props.intl.formatMessage(
                          { id: '_not[userFullName]acceptInDifferentAccount' }, 
                          { 'userFullName': this.props.authUser?.getFullName?.() }
                        )}
                        theme="secondary"
                        handleClick={() => this.props.redirectToLoginWithContinue()} />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                      <UniButton
                        className="admin-invite-accept-button"
                        handleClick={() => this.props.redirectToPortal()}
                        textKey="_continueToPortalWithoutAccepting"
                        theme="inverted" />
                    </Col>
                  </Row>
                </UniConditionalRender>

              </UniConditionalRender>

              <UniConditionalRender visible={this.props.inviteData.status === EInviteStatusC.expired}>
                <h4 className="text-center">
                  <UniLocalize translate="invitationExpiredContactSenderToResend" />
                </h4>

                <Row>
                  <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                    <UniButton
                      className="admin-invite-accept-button"
                      handleClick={this.props.acceptInvite}
                      textKey="acceptNewEKeys"
                      disabled={true}
                      disabledReasonKeys={['sharedEkeysExpired', 'cannotAcceptExpiredEKeys']}
                      tooltipPosition="right" />
                  </Col>
                </Row>
              </UniConditionalRender>

              <UniConditionalRender visible={authUserBelongsToInvitedDealer}>
                <h4 className="text-center">
                  <UniLocalize translate="alreadyMemberOfInvitedDealer" />
                </h4>

                <Row>
                  <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                    <UniButton
                      className="admin-invite-accept-button"
                      textKey="acceptInDifferentAccount"
                      theme="secondary"
                      handleClick={() => this.props.redirectToLoginWithContinue()} />
                  </Col>
                </Row>

                <Row>
                    <Col xs={12} md={8} xl={6} push={{md: 2, xl: 3}}>
                      <UniButton
                        className="admin-invite-accept-button"
                        handleClick={() => this.props.redirectToPortal()}
                        textKey="_continueToPortalWithoutAccepting"
                        theme="inverted" />
                    </Col>
                  </Row>
              </UniConditionalRender>

            </UniConditionalRender>
            {/* new stuff */}

          </Container>
        </section>
        <FooterContainer />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    authUserLoading: state.authenticatedUser.loading,
    authUser: state.authenticatedUser.currentUser,
    inviteData: state.inviteDetails.inviteData,
    loading: state.adminRegisterForm.loading,
    darkModeTheme: state.portal.darkModeTheme
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCurrentUserOptional: attemptRetrieveAuthUserOptional,
  getInvitationDetails: attemptRetrieveAdminInviteByToken,
  acceptInvite: attemptAcceptAdminInviteAsExistingUser,
  redirectToLoginWithContinue: redirectToLogin,
  refreshSuccess: oidcRefreshSuccess,
  setActivePortalDealer,
  addAlert,
  redirectToPortal: () => portalRedirect(navConfig.get(ENavPages.organizations)!.linkTo()),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminInvitationAcceptForm, { forwardRef: true }))
