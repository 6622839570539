import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {

  UniInput,
  UniConditionalRender,
  UniLocalize,
  UniKeyVal,
  UniOverflowActions,
  Editable,
  ICredentialC,
  CredentialC,
  Device,
  IUniChips_Chip,
  IUniMenu_ItemConfig,
  IUniConfirm_Config,
  ICapabilityC,
  IPartnerCredImpls,
  ECredentialStatus,
  ECredentialType,
  EOperationCodesC,
  ECredentialInvitationStatus,
  ES10nSettings,
  emailV10n
} from '@unikey/unikey-commons/release/comm';

import {
  portalRedirect,
  ENavPages,
  navConfig,
  openConfirmModal,
  closeConfirmModal,
  handleCopyCredToEmailChange,
  attemptRetrieveCredentialById,
  attemptRevokeCredential,
  attemptDisableCredential,
  attemptEnableCredential,
  attemptReissueCredential,
  attemptCopyCredential,
  attemptDeleteCredential,
  attemptToggleCredentialPermissions,
  IGetPartnerCredsByIdActionParams,
  ICopyCredentialActionParams,
  s10n, canI,
  PartnerCustomizations, IPartnerCustomizations,

  DeviceListContainer,
  DeviceDetailsContainer
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  credentialData: CredentialC,
  credentialDevices: Device[],
  loading: boolean,
  copyCredToEmail: Editable<string>,

  partnerDefaultCredentialType: ECredentialType,
  partnerCredImpls: IPartnerCredImpls,

  preventToggleUserInstaller?: boolean,
  permissionToCreateCredential: boolean,
  permissionToDisableCredential: boolean,
  permissionToEnableCredential: boolean,
  permissionToRevokeCredential: boolean,
  permissionToReissueCredential: boolean,
  permissionToDeleteCredential: boolean,
  permissionToUpdateCredential: boolean,
  subDisplayDeviceList: boolean,
  subFreeInstallerCredentials: boolean,
  subAllowEnabledCredReissue: boolean,
  subCreditCountRelevant: boolean,

  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
  updateCopyCredentialTo(email: Editable<string>): void,
  getCredential(): Promise<void>,
  toggleCredentialPermissions(existingCredential: CredentialC): Promise<void>,
  revokeCredential(): Promise<void>,
  reissueCredential(): Promise<void>,
  copyCredential(copyActionParams: ICopyCredentialActionParams): Promise<CredentialC>,
  enableCredential(): Promise<void>,
  disableCredential(): Promise<void>,
  deleteCredential(): Promise<void>,
}

class CredentialDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  componentDidMount() {
    this.props.getCredential();
  }

  _revokeCredential = () => {
    return this.props.revokeCredential()
      .then(() => this.props.getCredential());
  }

  _disableCredential = () => {
    return this.props.disableCredential()
      .then(() => this.props.getCredential());
  }

  _enableCredential = () => {
    return this.props.enableCredential()
      .then(() => this.props.getCredential());
  }

  _reissueCredential = () => {
    return this.props.reissueCredential()
      .then(() => this.props.getCredential());
  }

  _copyCredential = (email?: string) => {
    return this.props.copyCredential({ email, credId: this.props.match.params.credentialId })
      // after copied successfully, go to the new credential details page
      .then((newCred: CredentialC) => portalRedirect(
        navConfig.get(ENavPages.credentialDetails)!.linkTo([
          this.props.match.params.organizationId,
          newCred.id
        ])!)
      );
  }

  _deleteCredential = () => {
    return this.props.deleteCredential()
      .then(() => portalRedirect(
        navConfig.get(ENavPages.credentials)!.linkTo([ this.props.match.params.organizationId ])!)
      );
  }

  _toggleCredentialPermissions = () => {
    return this.props.toggleCredentialPermissions(this.props.credentialData)
      .then(() => this.props.getCredential());
  }


  render() {
    if (this.props.render) {
      return this.props.render();
    }

    var copyCredSecondaryMessage: string | undefined;
    var reissueCredCostMessage: string | undefined;
    // if we are in a subscription that cares about the credit counts, we should display the cost too
    if (this.props.subCreditCountRelevant) {
      copyCredSecondaryMessage = 'credentialActionWillConsume1Credit';
      if (this.props.subFreeInstallerCredentials && this.props.credentialData.isInstaller) {
        // this credential does not consume a credit since it is an installer credential 
        // inside a subscription model that allows free installer creds 
        copyCredSecondaryMessage = 'installerCredentialsConsume0Credits';
      }
      reissueCredCostMessage = copyCredSecondaryMessage;
      if ((this.props.subAllowEnabledCredReissue && this.props.credentialData.status === ECredentialStatus.enabled) 
        || this.props.credentialData.status === ECredentialStatus.invited) {
        // if we're doing a multi device reissue, it should be not consume a credit
        reissueCredCostMessage = 'credentialActionWillConsume0Credits';
      }
    }
    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('reissue', {
        textKey: 'reissue',
        handleClick: () => this.props.openConfirmDialog({
          titleKey: 'reissue',
          titleIcon: 'send',
          messageKeys: ['reissueCredentialMessage', reissueCredCostMessage, 'areYouSure'],
          confirmTextKey: 'reissue',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._reissueCredential(),
            this.props.closeConfirmModal()
          }
        }),
        hidden: !this.props.permissionToReissueCredential || this.props.subAllowEnabledCredReissue ? false : this.props.credentialData.status > ECredentialStatus.invited,
        disabled: this.props.subAllowEnabledCredReissue ? false : this.props.credentialData.status !== ECredentialStatus.invited,
      })
      .set('copy', {
        textKey: 'copy',
        handleClick: () => this.props.openConfirmDialog({
          titleKey: 'copy',
          titleIcon: 'contentCopy',
          messageKeys: ['youAreAboutToCopyThisCredential', copyCredSecondaryMessage],
          confirmTextKey: 'copy',
          cancelHandler: this.props.closeConfirmModal,
          customContentRender: () => (
            <UniInput
              editable={this.props.copyCredToEmail}
              type="string"
              placeholderKey={this.props.credentialData.email}
              labelKey="copyCredentialTo"
              validateOnInitialRender={true}
              handleUpdate={(email: Editable<string>) => this.props.updateCopyCredentialTo(email)}
              validations={new Map([emailV10n])} />
          ),
          confirmDisabled: () => false, // TODO: !this.props.copyCredToEmail.valid,
          confirmHandler: () => {
            this._copyCredential(this.props.copyCredToEmail.value),
              this.props.closeConfirmModal()
          }
        }),
        hidden: !this.props.permissionToReissueCredential,
        // disabled: // TODO: disabled when available credits 0
      })
      .set('enable', {
        textKey: 'enable',
        handleClick: () => this._enableCredential(),
        hidden: !this.props.permissionToEnableCredential || this.props.credentialData.status === ECredentialStatus.enabled || this.props.credentialData.status === ECredentialStatus.revoked,
        disabled: this.props.credentialData.status === ECredentialStatus.invited || this.props.credentialData.status === ECredentialStatus.deleted
      })
      .set('disable', {
        textKey: 'disable',
        handleClick: () => this._disableCredential(),
        hidden: !this.props.permissionToDisableCredential || this.props.credentialData.status === ECredentialStatus.disabled || this.props.credentialData.status === ECredentialStatus.revoked,
        disabled: this.props.credentialData.status === ECredentialStatus.invited || this.props.credentialData.status === ECredentialStatus.deleted
      })
      .set('revoke', {
        textKey: 'revoke',
        theme: 'error',
        handleClick: () => this.props.openConfirmDialog({
          titleKey: 'revokeCredential',
          titleIcon: 'delete',
          messageKeys: ['youAreAboutToRevokeThisCredential', 'cannotBeUndone', 'areYouSure'],
          confirmTextKey: 'yes',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._revokeCredential();
            this.props.closeConfirmModal()
          }
        }),
        hidden: !this.props.permissionToRevokeCredential || this.props.credentialData.status === ECredentialStatus.revoked,
        disabled: this.props.credentialData.status === ECredentialStatus.deleted
      })
      .set('delete', {
        textKey: 'delete',
        theme: 'error',
        handleClick: () => this.props.openConfirmDialog({
          titleKey: 'deleteCredential',
          titleIcon: 'deleteForever',
          messageKeys: ['youAreAboutToPermanentlyDeleteThisCredential', 'cannotBeUndone', 'areYouSure'],
          confirmTextKey: 'yes',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._deleteCredential();
            this.props.closeConfirmModal()
          }
        }),
        hidden: !this.props.permissionToDeleteCredential || this.props.credentialData.status !== ECredentialStatus.revoked,
      })
      .set('addInstallerPermissions', {
        textKey: 'addInstallerPermissions',
        handleClick: () => this._toggleCredentialPermissions(),
        // if the subscirption offers free installer credentials you can abuse the system 
        // by creating as installer and updating to regular credential. In this case,
        // the add/remove installer permissions is prevented on the backend and hidden here
        hidden: this.props.subFreeInstallerCredentials || !this.props.permissionToUpdateCredential || this.props.credentialData.isInstaller || this.props.credentialData.status === ECredentialStatus.revoked,
      })
      .set('removeInstallerPermissions', {
        textKey: 'removeInstallerPermissions',
        handleClick: () => this._toggleCredentialPermissions(),
        // if the subscirption offers free installer credentials you can abuse the system 
        // by creating as installer and updating to regular credential. In this case,
        // the add/remove installer permissions is prevented on the backend and hidden here
        hidden: this.props.subFreeInstallerCredentials || !this.props.permissionToUpdateCredential || !this.props.credentialData.isInstaller || this.props.credentialData.status === ECredentialStatus.revoked,
      });

    const capabilityChipCollection = [...this.props.credentialData.capabilityMap?.values?.() || []].reduce((capMap: Map<string, IUniChips_Chip>, cap: ICapabilityC, index: number) => {
      if (cap.status === 'available') {
        capMap.set(`capability-${index}`, {
          nameKey: cap.type
        });
      }
      return capMap;
    }, new Map<string, IUniChips_Chip>())

    const isCredInviteExpired: boolean = this.props.credentialData.invitationStatus === ECredentialInvitationStatus.expired;

    return (
      <section className='credentialDetails-container'>
        <Row justify="between">
          <Col>
            <h3 className="credential-details-title"><UniLocalize translate="credentialDetails" /></h3>
          </Col>
          <Col xs={3} lg={2}>
            <UniOverflowActions
              className="title-actions"
              nameKey="credentialActions"
              icon="arrowDropDownCircle"
              actions={actionsMenuContents}
              theme="primary" />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <UniKeyVal
              label={`credDetails`}
              showLoader={this.props.loading || !this.props.credentialData.id}
              preventEdit={true}
              stacked={true}
              fields={[
                {
                  keyName: 'email',
                  value: `${this.props.credentialData.email}`,
                  type: 'string',
                  preventTranslate: true,
                  placeholderKey: 'email',
                  disabled: true
                },
                {
                  // the credential status enum doesnt have expired, 
                  // only the credential invitation enum, 
                  // so we have to do some screwy stuff here
                  // to show the important exipred vs invited
                  keyName: 'credentialStatus',
                  value: isCredInviteExpired ? `${this.props.credentialData.invitationStatus}` : `${this.props.credentialData.status}`,
                  type: 'enum',
                  enumType: isCredInviteExpired ? ECredentialInvitationStatus : ECredentialStatus,
                  disabled: true,
                  keyInfo: isCredInviteExpired ? {
                    icon: 'warning',
                    textKeys: ['expiredCredentialInvite', '_explainExpiredCredential'],
                  } : undefined
                },
                {
                  keyName: 'label',
                  value: `${this.props.credentialData.label}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'installerPermissions',
                  value: this.props.credentialData.isInstaller,
                  type: 'boolean',
                  disabled: true
                },
                {
                  keyName: 'identifier',
                  value: `${this.props.credentialData.id}`,
                  type: 'string',
                  disabled: true
                },
              ]} />
          </Col>

          <Col md={6}>
            <UniKeyVal
              label={`credData`}
              showLoader={this.props.loading || !this.props.credentialData.id}
              preventEdit={true}
              stacked={true}
              fields={[
                {
                  keyName: 'credentialType',
                  value: this.props.credentialData.type,
                  type: 'enum',
                  enumType: ECredentialType,
                  disabled: true
                },
                {
                  keyName: 'facilityCode',
                  value: `${this.props.credentialData.facilityCode}`,
                  type: 'string',
                  disabled: true,
                  hidden: this.props.credentialData.type === ECredentialType.unikeyV1 || this.props.credentialData.type === ECredentialType.raw
                },
                {
                  keyName: 'cardNumber',
                  value: `${this.props.credentialData.cardNumber}`,
                  type: 'string',
                  disabled: true,
                  hidden: this.props.credentialData.type === ECredentialType.unikeyV1 || this.props.credentialData.type === ECredentialType.raw
                },
                {
                  keyName: 'credentialDataBitLength',
                  value: `${this.props.credentialData.credentialDataBitLength}`,
                  type: 'string',
                  disabled: true,
                  hidden: !(this.props.credentialData.type === ECredentialType.unikeyV1 || this.props.credentialData.type === ECredentialType.raw)
                },
                {
                  keyName: 'credentialDataHex',
                  value: `${this.props.credentialData.credentialDataHex}`,
                  type: 'string',
                  disabled: true,
                  hidden: !(this.props.credentialData.type === ECredentialType.unikeyV1 || this.props.credentialData.type === ECredentialType.raw)
                },
                {
                  keyName: 'credentialCapabilities',
                  value: 'test',
                  type: 'chips',
                  disabled: true,
                  collection: capabilityChipCollection
                }
              ]} />
          </Col>
        </Row>


        <Row justify="between">
          <Col>
            <h3 className="credential-invitation-title"><UniLocalize translate="credentialInvitation" /></h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <UniKeyVal
              label={`credDetails`}
              showLoader={this.props.loading || !this.props.credentialData.id}
              preventEdit={true}
              fields={[
                {
                  keyName: 'expirationDate',
                  value: `${this.props.credentialData.invitationExpiration}`,
                  type: 'date',
                  disabled: true
                },
                {
                  keyName: 'status',
                  value: `${this.props.credentialData.invitationStatus}`,
                  type: 'enum',
                  enumType: ECredentialInvitationStatus,
                }
              ]} />
          </Col>
        </Row>

        <UniConditionalRender visible={this.props.subDisplayDeviceList && !!this.props.credentialDevices}>
          <DeviceListContainer
            match={this.props.match}
            history={this.props.history}
            loading={this.props.loading} />
        </UniConditionalRender>

        <UniConditionalRender visible={!this.props.subDisplayDeviceList && this.props.credentialDevices.length > 0}>
          <DeviceDetailsContainer
            loading={this.props.loading}
            match={this.props.match}
            history={this.props.history}
            deviceData={this.props.credentialDevices[0]} />
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    credentialData: state.credentialDetails.credentialData,
    credentialDevices: state.credentialDetails.credentialDevices,
    loading: state.credentialDetails.loading,
    copyCredToEmail: state.credentialDetails.copyToEmail,
    // permissions
    permissionToCreateCredential: canI(EOperationCodesC.CreateCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToDisableCredential: canI(EOperationCodesC.DisableCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToEnableCredential: canI(EOperationCodesC.EnableCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToRevokeCredential: canI(EOperationCodesC.DeleteCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId), // yes its a mismatched name - operation
    permissionToReissueCredential: canI(EOperationCodesC.CreateCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId), // yes its a mismatched name - operation
    permissionToDeleteCredential: canI(EOperationCodesC.DeleteCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToUpdateCredential: canI(EOperationCodesC.UpdateCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    // subscription
    subDisplayDeviceList: s10n(ES10nSettings.MultiDeviceCredentials),
    subAllowEnabledCredReissue: s10n(ES10nSettings.MultiDeviceCredentials),
    subFreeInstallerCredentials: s10n(ES10nSettings.FreeInstallerCredentials),
    subCreditCountRelevant: s10n(ES10nSettings.CredCountRelevant)
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  const credId = ownProps.match.params.credentialId;
  const credIdAndPartnerImpls:IGetPartnerCredsByIdActionParams = {
    credId,
    partnerCredImpls: ownProps.partnerCredImpls
  }
  return bindActionCreators({
    copyCredential: attemptCopyCredential,
    toggleCredentialPermissions: attemptToggleCredentialPermissions,
    deleteCredential: attemptDeleteCredential.bind(null, credId),
    reissueCredential: attemptReissueCredential.bind(null, credId),
    revokeCredential: attemptRevokeCredential.bind(null, credId),
    enableCredential: attemptEnableCredential.bind(null, credId),
    disableCredential: attemptDisableCredential.bind(null, credId),

    updateCopyCredentialTo: handleCopyCredToEmailChange,
    getCredential: attemptRetrieveCredentialById.bind(null, credIdAndPartnerImpls),

    openConfirmDialog: openConfirmModal,
    closeConfirmModal,
  }, dispatch);
}

export default PartnerCustomizations(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CredentialDetailsContainer)), { componentName: 'CredentialDetails' })
