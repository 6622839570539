
import {
  tokenActions,
  inputInitializer
} from '../../internal'



const initialSetPasswordState = {
  ...inputInitializer('', ['password', 'confirmPassword'])
};

export const setPasswordTokenForm = (state = initialSetPasswordState, action: any) => {
  switch (action.type) {
    case tokenActions.UPDATE_SET_PASSWORD_TOKEN_FORM:
      return {
        ...state,
        password: action.formChanges.password ?? state.password,
        confirmPassword: action.formChanges.confirmPassword ?? state.confirmPassword,
      }
    default:
      return state
  }
};

const initialActionTokenState = {
  data: {},
  loading: false
};

export function actionTokens(state = initialActionTokenState, action: any) {
  switch (action.type) {
    case tokenActions.USER_TOKEN_ACTION_REQUEST:
      return {
        ...state,
        loading: action.loading,
      }
    case tokenActions.USER_TOKEN_ACTION_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value,
      }
    case tokenActions.USER_TOKEN_ACTION_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}