
import {
  api,
  ApiReduxAction,
  IExposeRedux
} from '../../internal'

export enum subscriptionActions {

  RETRIEVE_SUBSCRIPTION_MODELS_REQUEST = 'RETRIEVE_SUBSCRIPTION_MODELS_REQUEST',
  RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS = 'RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS',
  RETRIEVE_SUBSCRIPTION_MODELS_FAILURE = 'RETRIEVE_SUBSCRIPTION_MODELS_FAILURE',
}

// Subscription Setup
const getAvailableSubscriptionModels = new ApiReduxAction({
  request: { type: subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_REQUEST },
  success: { type: subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS },
  failure: {
    type: subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_FAILURE,
    title: 'failedToRetrieveSubscriptionModelSummary'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux) => {
  return api.subs.getAvailableSubscriptionModels.bind(api.subs);
});
export const attemptRetrieveAvailableSubscriptionModels = getAvailableSubscriptionModels.go;

