import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { loadUser, OidcProvider } from 'redux-oidc';

import { configureStore, AppContainer, userManager } from '../internal'

export const store = configureStore();

export default class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <OidcProvider userManager={userManager} store={store}>
          <AppContainer />
        </OidcProvider>
      </Provider>
    )
  }
}