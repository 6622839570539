import { Dispatch } from 'redux'

import {
  EMessageType,
  IPaginatedResponseC
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  mockAuth,
  addAlert,
  redirectToLogin,
  checkJobStatusOnInterval,
  setupJobTrackingFor,
  verifyLandingKey,
  portalRedirect,
} from '../internal';

export interface IApiActions {
  request: () => void
  success: () => void
  failure: () => void
}

export interface IActionBuilderCustomizations {
  type: string,
  title?: string,
  message?: string,
  duration?: number,
  after?: (dux: IExposeRedux, errCode?: any) => void
}

export interface IApiActionSetNames {
  request: IActionBuilderCustomizations,
  success: IActionBuilderCustomizations,
  failure: IActionBuilderCustomizations,
  tableMetaUpdate?: IActionBuilderCustomizations,
  edit?: IActionBuilderCustomizations,
  onInterval?: IActionBuilderCustomizations,
  handle404?: (dux: IExposeRedux, error?: any) => void,
  handle401?: (dux: IExposeRedux, error?: any) => void,
  checkNumPendingReqs: (requestName: string) => number,
}

export interface IExposeRedux {
  getState: () => any,
  dispatch: any,
}

export class ApiReduxAction<T> {
  requestAction: IActionBuilderCustomizations;
  successAction: IActionBuilderCustomizations;
  failureAction: IActionBuilderCustomizations;
  tableMetaAction?: IActionBuilderCustomizations;
  actionOnInterval?: IActionBuilderCustomizations;
  request?: (dux: IExposeRedux, requestParams?: any) => (...a: any) => Promise<T>;
  raiseAction?: Dispatch<any>;
  getState?: any;
  dux?: IExposeRedux;
  handle404?: (dux: IExposeRedux, error?: any) => void;
  handle401?: (dux: IExposeRedux, error?: any) => void;
  getNumPendingReqs?:(requestName: string) => number;
  clearJobStatusInterval?: () => void;

  constructor(actions: IApiActionSetNames, apiCall?: (dux: IExposeRedux, params?: any) => (...input: any) => Promise<T>) {

    this.requestAction = actions.request;
    this.successAction = actions.success;
    this.failureAction = actions.failure
    this.tableMetaAction = actions.tableMetaUpdate;
    this.handle404 = actions.handle404;
    this.handle401 = actions.handle401;
    this.request = apiCall;

  }

  setApiRequest = (req: any) => {
    this.request = req;
  }

  // invokes the action cycle
  go = (requestParams: any = {}): any => {
    return (dispatch: Dispatch<any>, getState: any): Promise<any> => {
      
      this.raiseAction = dispatch;
      this.getState = getState;
      this.dux = { dispatch, getState };
      
      const tracking = setupJobTrackingFor(this.requestAction.type);
      // add the tracking argument as the final argument and send the request
      // earlier arguments are bound in the actions file 
      // when creating this ApiReduxAction instance
      const outgoing = this.request!({ dispatch, getState }, requestParams)(tracking)
        .then(this.success, this.failure);

      if (this.requestAction.title) {
        this.raiseAction!(addAlert({
          id: Date.now(),
          type: EMessageType.info,
          titleKey: this.requestAction.title,
          messageKeys: [...[this.requestAction.message]],
          duration: this.requestAction.duration,
        }));
      }

      this.raiseAction!({
        type: this.requestAction.type,
        pending: api.pendingReqs.getNum(this.requestAction.type) > 0,
        requestParams,
      })

       // start the interval tracking if the request config references one
       if (this.actionOnInterval) {
        this.clearJobStatusInterval = checkJobStatusOnInterval(
          this.raiseAction.bind(null, {
            ...this.actionOnInterval,
            numPending: this.getNumPendingReqs?.(this.requestAction.type) || 0
          }),
          this.actionOnInterval?.duration);
      }
      return outgoing;
    };
  }

  success = (value: T) => {
    this.raiseAction!({
      value,
      type: this.successAction.type,
      pending: (this.getNumPendingReqs?.(this.requestAction.type) || 0) > 0,
    })
    this.clearJobStatusInterval?.();

    if (this.successAction.title) {

      this.raiseAction!(addAlert({
        id: Date.now(),
        type: EMessageType.success,
        titleKey: this.successAction.title ?? '_genericSuccessMessage',
        messageKeys: [...[this.successAction.message]],
        duration: this.successAction.duration,
      }));
    }

    if (this.tableMetaAction) {
      const tableResults = value as unknown as IPaginatedResponseC<any, any>;
      this.raiseAction!({
        type: this.tableMetaAction.type,
        totalCount: tableResults?.paginationSummary?.totalCount,
        currPage: tableResults?.paginationSummary?.currPage,
        pageSize: tableResults?.paginationSummary?.pageSize
      })
    }

    // if there is a promise setup to chain on success execute it.
    if (this.successAction.after) {
      this.successAction.after(this.dux!);
    }

    return Promise.resolve(value);
  }

  failure = (err: any) => {
    if (err?.error === 'frontend_stale_request') {
      return;
    }
    
    if (!mockAuth) {
      if (err.status === 401) {
        if (this.handle401) {
          this.handle401(this.dux!, err);
        } else {
          this.raiseAction!(redirectToLogin())
        }
      } else if (err.status === 404) {
        if (this.handle404) {
          this.handle404(this.dux!, err);
        }
      } else {
        this.clearJobStatusInterval?.();

        this.raiseAction!({
          type: this.failureAction.type,
          pending: (this.getNumPendingReqs?.(this.requestAction.type) || 0) > 0
        })
      }
    }
    
    
    // TODO: Remove the status=500 case here once tumbler is updated.
    // Requests with expired tokens are returning 500 currently. 
    // Eventually this should be 401 error codes with messages attached. 
    if (err.status === 403 || err.status === 401 || err.status === 500 && !mockAuth) {
      // TODO: restore? new pattern of waiting for refresh to be in place. 
      // this redirect may not be needed here any longer...
      // this.raiseAction!(redirectToLogout())
    }
    if (this.failureAction.title) {
      this.raiseAction!(addAlert({
        id: Date.now(),
        type: EMessageType.error,
        titleKey: this.failureAction.title,
        messageKeys: [...[this.failureAction.message] || err.error_description || err.message || ''],
        duration: this.failureAction.duration,
      }));
    }

    // if there is a promise setup to chain on failiurer execcute it.
    if (this.failureAction.after) {
      this.failureAction.after(this.dux!, err.status);
    }

    return Promise.reject(err);
  }
}