import {
  api,
  authActions,
  inputInitializer,
  userActions,
} from '../../internal'

const initialChangePasswordFormState = {
  open: false,
}

export const changePasswordForm = (state = initialChangePasswordFormState, action: any) => {
  switch (action.type) {
    case userActions.TOGGLE_CHANGE_PASSWORD_WORKFLOW:
      return {
        ...state,
        open: !state.open
      }
    // case userActions.RESET_CHANGE_PASSWORD_WORKFLOW:
    //   return {
    //     ...state,
    //   }
    default:
      return state
  }
};

const initialAddPasswordModalState = {
  open: false,
  alreadySent: false,
  loading: false,
}

export const addPasswordModal = (state = initialAddPasswordModalState, action: any) => {
  switch (action.type) {
    case userActions.TOGGLE_ADD_PASSWORD_WORKFLOW:
      return {
        ...state,
        open: !state.open
      }
    case userActions.ADD_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        loading: action.pending
      }
    case userActions.ADD_PASSWORD_EMAIL_SUCCESS:
    case userActions.ADD_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        loading: action.pending,
        alreadySent: true
      }
    default:
      return state
  }
};




