import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classNames from 'classnames';

import {
  darkThemeName,
  UniLocalize,
  UniKeyVal,
  UniImg,
  UniWorkflow,
  UniOverlapGroup,
  UniOverlapButton
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';

import {
  FooterContainer,
  attemptResendVerifyEmail,
  partnerKey
} from '../internal'

interface IProps extends WrappedComponentProps {
  resending: boolean,
  sent: boolean,
  darkModeTheme: string,
  attemptResendVerifyEmail(): void
}

class VerifyResend extends Component<IProps> {

  constructor(props: IProps) {
    super(props);

  }

  render() {
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    return (
      <section className='access-container'>
        <section className='verify-resend-container'>

          <UniImg textKey="partner logo" className={imgClassName} src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
          <UniWorkflow
            titleKey="verifyEmail"
            titleIcon="email">

            <p><strong><UniLocalize translate='emailVerificiationRequired' /></strong></p>
            <p><UniLocalize translate='mustVerifyEmailBeforeAccessing' /></p>
            <p><UniLocalize translate='requestNewVerificationEmail' /></p>

            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={this.props.attemptResendVerifyEmail}
                textKey="resendVerification"
                icon="send"
                showLoader={this.props.resending}
                disabled={this.props.sent} />
            </UniOverlapGroup>

          </UniWorkflow>
        </section>
        <FooterContainer />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    resending: state.verifyEmailForm.loading,
    sent: state.verifyEmailForm.sent,
    darkModeTheme: state.portal.darkModeTheme
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  attemptResendVerifyEmail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VerifyResend, { forwardRef: true }))