
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc';
import { RouteComponentProps } from 'react-router-dom'

import {
  decodeJwt
} from '@unikey/unikey-commons/release/comm';

import {
  api,
  userManager,
  ENavPages,
  navConfig,
  SplashContainer,
  oidcForgotPasswordRedirect,
  oidcLoginSuccess,
  oidcLoginFailure,
  addAlert,
  verifyLandingKey,
  portalRedirect,
  loginKey,
  getCurrentBaseDomain,
  handleNewOrRefreshedToken
} from '../internal'

interface IProps extends RouteComponentProps {
  loginSuccess(user: any, canUserCreateDealer: boolean): void,
  loginFailure(err: any): void
}

class OidcContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  successCallback = async (user: any) => {
    await handleNewOrRefreshedToken(user, this.props.loginSuccess);

    // if we have not yet been redirected to verify account
    // or create dealer or other, redirect accordingly
    if (/\/#\/token/.test(window.location.href)) {
      // send the user to the portal default page
      // using this instead of history since history is not able to
      // remove the query parameters returned by the OIDC response.
      const continueTo = api.cacheImpl.get('continueTo');
      api.cacheImpl.remove('continueTo');
      var goToLoc = '';
      if (continueTo && continueTo !== 'null') {
        const continueHashRoute = decodeURIComponent(continueTo);
        goToLoc = `${getCurrentBaseDomain()}#${continueHashRoute}`;
        window.location.href = goToLoc;
      } else {
        portalRedirect(navConfig.get(ENavPages.organizations)!.linkTo());
      }
    }
    
    return;
  };

  errorCallback = (error: any) => {
    api.cacheImpl.remove('continueTo');
    if (error.message === 'No matching state found in storage') {
      window.location.href = `${getCurrentBaseDomain()}#${loginKey}`;
    } else if (/User\+does\+not\+exist/.test(error.error_description)) {
      // User Needs to Register
      this.props.loginFailure('userNotRegistered');
      portalRedirect(loginKey);
    } else if (/The\+user\+has\+forgotten\+their\+password/.test(error.error_description)) {
      // Need to redirect to reset password
      window.location.href = oidcForgotPasswordRedirect;
    } else if (error.error_description) {
      // Unknown Problem
      this.props.loginFailure('unknownError')
      portalRedirect(loginKey);
    } else {
      portalRedirect(loginKey);
    }
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}>
        <SplashContainer
          match={this.props.match}
          history={this.props.history} />
      </CallbackComponent>
    );
  }
}

function mapStateToProps(state: any) {
  return {

  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  loginSuccess: oidcLoginSuccess,
  loginFailure: oidcLoginFailure
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OidcContainer)