
import { INavConfig_FlowPage, INavConfig_NestedPage, EOperationCodesC } from '@unikey/unikey-commons/release/comm'

import { api, environment } from '@alias-environment';
import {
  canI,
  OrganizationListContainer,
  OrganizationDetailsContainer,
  DealerDetailsContainer,
  ReaderListContainer,
  ReaderDetailsContainer,
  AdminDetailsContainer,
  CredentialListContainer,
  CredentialDetailsContainer,
  DeviceDetailsContainer,
  AdminListContainer,
  VersionContainer,
  RedeemContainer,
  MyAccountContainer,
  DealerCreateContainer,
  SetPasswordTokenContainer,
  AdminInvitationAcceptContainer,
  MyLinkedAccountsContainer,
  AdminRegisterContainer,
  ForwardToAccountDetailsContainer
} from './internal'

export enum ENavPages {
  organizations = 'organizations',
  organizationDetails = 'organizationDetails',
  readers = 'readers',
  readerDetails = 'readerDetails',
  credentials = 'credentials',
  credentialDetails = 'credentialDetails',
  deviceDetails = 'deviceDetails',
  admins = 'admins',
  adminDetails = 'adminDetails',
  dealer = 'dealer',
  myAccount = 'myAccount',
  myProfile = 'myProfile',
  myLinkedAccounts = 'myLinkedAccounts',
  version = 'version',
  redeem = 'redeem',
  oidcToken = 'token',
  oidcRenew = 'renew',
  setPassword = 'setPassword',
  portalLanding = 'organizations',
  dealerCreate = 'dealerCreate',
  adminInviteAccept = 'adminInviteAccept',
  adminInviteRegister = 'adminInviteRegister',
}

const navConfig = new Map<string, INavConfig_FlowPage | INavConfig_NestedPage>();


// Organizations
navConfig.set(ENavPages.organizations, {
  icon: 'business',
  nameKey: ENavPages.organizations,
  flowMatch: /portal\/organizations\/?$/,
  component: OrganizationListContainer,
  linkTo: (r: any = []) => '/portal/organizations',
  evalHidden: () => !api.getDealerId(),
});
navConfig.set(ENavPages.organizationDetails, {
  icon: 'removeRedEye',
  nameKey: ENavPages.organizationDetails,
  pageMatch: /portal\/organizations\/([^\/]{1,})\/?$/,
  component: OrganizationDetailsContainer,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}`,
});

// Readers
navConfig.set(ENavPages.readers, {
  icon: 'settingsRemote',
  nameKey: 'organizationReaders',
  component: ReaderListContainer,
  pageMatch: /portal\/organizations\/([^\/]{1,})\/readers\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}/readers`,
});
navConfig.set(ENavPages.readerDetails, {
  icon: 'settingsRemote',
  nameKey: ENavPages.readerDetails,
  component: ReaderDetailsContainer,
  pageMatch: /portal\/organizations\/([^\/]{1,})\/readers\/([^\/]{1,})\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!, navConfig.get(ENavPages.readers)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}/readers/${r[1] ? r[1] : ':readerId'}`,
  evalHidden: () => true
});

// Credentials
navConfig.set(ENavPages.credentials, {
  icon: 'vpnKey',
  nameKey: 'organizationCredentials',
  component: CredentialListContainer,
  pageMatch: /portal\/organizations\/([^\/]{1,})\/credentials\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}/credentials`,
});
navConfig.set(ENavPages.credentialDetails, {
  icon: 'vpnKey',
  nameKey: ENavPages.credentialDetails,
  component: CredentialDetailsContainer,
  pageMatch: /portal\/organizations\/([^\/]{1,})\/credentials\/([^\/]{1,})\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!, navConfig.get(ENavPages.credentials)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}/credentials/${r[1] ? r[1] : ':credentialId'}`,
  evalHidden: () => true
});
navConfig.set(ENavPages.deviceDetails, {
  icon: 'vpnKey',
  nameKey: ENavPages.deviceDetails,
  component: DeviceDetailsContainer,
  pageMatch: /portal\/organizations\/([^\/]{1,})\/credentials\/([^\/]{1,})\/devices\/([^\/]{1,})\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!, navConfig.get(ENavPages.credentials)!, navConfig.get(ENavPages.credentialDetails)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}/credentials/${r[1] ? r[1] : ':credentialId'}/devices/${r[2] ? r[2] : ':deviceId'}`,
  evalHidden: () => true
});


// Admins
navConfig.set(ENavPages.admins, {
  icon: 'supervisorAccount',
  nameKey: ENavPages.admins,
  component: AdminListContainer,
  flowMatch: /portal\/admins\/?$/,
  linkTo: (r: any = []) => '/portal/admins',
  evalHidden: () => !canI(EOperationCodesC.ReadAdmin, api.getDealerId()),
});
navConfig.set(ENavPages.adminDetails, {
  icon: 'removeRedEye',
  nameKey: ENavPages.adminDetails,
  component: AdminDetailsContainer,
  pageMatch: /portal\/admins\/([^\/]{1,})\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.admins)!],
  linkTo: (r: any = []) => `/portal/admins/${r[0] ? r[0] : ':adminId'}`,
});

navConfig.set(ENavPages.dealer, {
  icon: 'locationCity',
  nameKey: ENavPages.dealer,
  component: DealerDetailsContainer,
  flowMatch: /portal\/dealer\/?$/,
  linkTo: (r: any = []) => '/portal/dealer',
  evalHidden: () => !canI(EOperationCodesC.ReadDealer, api.getDealerId())
});

// -- MY ACCOUNT --
navConfig.set(ENavPages.myAccount, {
  icon: 'personPin',
  nameKey: ENavPages.myAccount,
  component: ForwardToAccountDetailsContainer,
  flowMatch: /portal\/account\/?$/,
  linkTo: (r: any = []) => '/portal/account',
  evalHidden: () => false,
});
navConfig.set(ENavPages.myProfile, {
  icon: 'removeRedEye',
  nameKey: ENavPages.myProfile,
  component: MyAccountContainer,
  pageMatch: /portal\/account\/profile\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.myAccount)!],
  linkTo: (r: any = []) => '/portal/account/profile',
  evalHidden: () => false,
})

navConfig.set(ENavPages.version, {
  icon: 'about',
  nameKey: ENavPages.version,
  component: VersionContainer,
  flowMatch: /portal\/version\/?$/,
  linkTo: (r: any = []) => '/portal/version',
  evalHidden: () => true, // hidden from left nav always
});

navConfig.set(ENavPages.redeem, {
  icon: 'about',
  nameKey: ENavPages.redeem,
  component: RedeemContainer,
  flowMatch: /portal\/redeem\/?$/,
  linkTo: (r: any = []) => '/redeem',
  evalHidden: () => true, // hidden from left nav always
});

// action token pages
navConfig.set(ENavPages.setPassword, {
  icon: 'about',
  nameKey: ENavPages.setPassword,
  component: SetPasswordTokenContainer,
  flowMatch: /account\/password\/([^\/]{1,})\/?$/,
  linkTo: (r: any = []) => `/account/password/${r[0] ? r[0] : ':actionToken'}`,
  evalHidden: () => true, // hidden from left nav always
});


navConfig.set(ENavPages.dealerCreate, {
  icon: 'about',
  nameKey: ENavPages.dealerCreate,
  component: DealerCreateContainer,
  flowMatch: /createdealer\/?$/,
  linkTo: (r: any = []) => '/createdealer',
  evalHidden: () => true, // hidden from left nav always
});

navConfig.set(ENavPages.adminInviteAccept, {
  icon: 'about',
  nameKey: ENavPages.adminInviteAccept,
  component: AdminInvitationAcceptContainer,
  flowMatch: /invites\/admins\/([^\/]{1,})\/accept\/?$/,
  linkTo: (r: any = []) => `/invites/admins/${r[0] ? r[0] : ':inviteToken'}/accept`,
  evalHidden: () => true, // hidden from left nav always
});
navConfig.set(ENavPages.adminInviteRegister, {
  icon: 'about',
  nameKey: ENavPages.adminInviteRegister,
  component: AdminRegisterContainer,
  flowMatch: /invites\/admins\/([^\/]{1,})\/register\/?$/,
  linkTo: (r: any = []) => `/invites/admins/${r[0] ? r[0] : ':inviteToken'}/register`,
  evalHidden: () => true, // hidden from left nav always
});

const loginKey = '/login';
const logoutKey = '/logout';
const verifyLandingKey = '/verify';
const noDealerLandingKey = '/account';
const legalCenterKey = '/legal';
const oops404Key = '/oops/404';
const oopsKey = '/oops';

export {
  navConfig,
  loginKey,
  logoutKey,
  verifyLandingKey,
  legalCenterKey,
  oopsKey,
  oops404Key,
  noDealerLandingKey
};
