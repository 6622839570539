import partnerConfig from '@alias-current-partner-customizations';
import { desiredLocales } from '@alias-environment';

const messages: Map<string, any> = new Map()

// add the desired language translations
desiredLocales.split(',').forEach((localeCode: string) => {
    const currLocale = require(`./translations/${localeCode}.json`);
    messages.set(localeCode, Object.assign({}, currLocale, (partnerConfig.locales as any)[localeCode] || {}))
});

export default messages;
