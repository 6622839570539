import { default as localeCustomizations } from './locales'
import { default as containerCustomizations } from './customizations'
import { IPartnerConfig } from '../../internal'

// must export all of these for each partner. Empty if using defaults
const partnerCustomizations: IPartnerConfig = {
  assets: {
    logoOnBackground: require('./assets/blue-key-logo.png'),
    logoOnSecondary: require('./assets/blue-key-logo.png'),
    logoOnPrimary: require('./assets/blue-key-logo.png'),
    logoOnDark: require('./assets/blue-key-logo.png')
  },
  locales: localeCustomizations,
  containers: containerCustomizations,
}
export default partnerCustomizations;