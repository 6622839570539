import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  AuthUserC,
  UniWorkflow,
  UniInput,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  Editable,
  notBlankV10n, passwordMatchV10n, resiPasswordRequirementsV10n
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';

import {
  IChangePasswordActionParams,
  attemptUpdateCurrentUser,
  handleModifyAuthUser,
  attemptResetPasswordDirect,
  toggleChangePasswordWorkflow,
  partnerKey,
  PartnerCustomizations, IPartnerCustomizations,
  noop,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  currentUser: AuthUserC,
  userUpdater: number,
  saving?: boolean,
  formValid?: boolean,
  attemptChangePassword(data: IChangePasswordActionParams): Promise<void>,
  handleModifyAuthUser(user: AuthUserC): void,
  toggleModal(): void,
}

class ChangePasswordContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);

  }

  _submitPasswordChange() {
    this.props.currentUser.setSaving(true);
    this.props.handleModifyAuthUser(this.props.currentUser);

    return this.props.attemptChangePassword({
      currPass: this.props.currentUser.currPassword?.value,
      newPass: this.props.currentUser.newPassword?.value

    }).then(() => {
      this._toggleAndResetForm()
    }).finally(() => {
      this.props.currentUser.setSaving(false);
      this.props.handleModifyAuthUser(this.props.currentUser);
    })
  }

  _toggleAndResetForm() {
    this.props.currentUser.newPassword = new Editable({ value: '' });
    this.props.currentUser.confirmNewPassword = new Editable({ value: '' });
    this.props.currentUser.currPassword = new Editable({ value: '' });
    this.props.toggleModal();
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }
    return (
      <section>
        <UniWorkflow
          inModal
          titleKey="changeMyPassword"
          titleIcon="outlineShield"
          handleClose={this._toggleAndResetForm}>

          <p><UniLocalize translate="_changePasswordInstructions" /></p>

          <UniInput
            type="password"
            editable={this.props.currentUser?.currPassword}
            labelKey="currentPassword"
            placeholderKey="yourCurrentPassword"
            validations={new Map([notBlankV10n])}
            handleUpdate={(pass: Editable<string>) => { this.props.currentUser.currPassword = pass; this.props.handleModifyAuthUser(this.props.currentUser); }}
            handleEnter={() => { this.props.formValid ? this._submitPasswordChange() : noop() }} />

          <br />

          <UniInput
            type="password"
            editable={this.props.currentUser?.newPassword}
            labelKey="newPassword"
            placeholderKey="password"
            validations={new Map([resiPasswordRequirementsV10n])}
            handleUpdate={(pass: Editable<string>) => { this.props.currentUser.newPassword = pass; this.props.handleModifyAuthUser(this.props.currentUser); }}
            handleEnter={() => { this.props.formValid ? this._submitPasswordChange() : noop() }} />

          <UniInput
            type="password"
            editable={this.props.currentUser?.confirmNewPassword}
            labelKey="confirmNewPassword"
            placeholderKey="confirmPassword"
            validations={new Map([passwordMatchV10n(this.props.currentUser?.newPassword?.value)])}
            triggerV10nCheck={this.props.currentUser.newPassword.value}
            handleUpdate={(pass: Editable<string>) => { this.props.currentUser.confirmNewPassword = pass; this.props.handleModifyAuthUser(this.props.currentUser); }}
            handleEnter={() => { this.props.formValid ? this._submitPasswordChange() : noop() }} />

          <UniOverlapGroup>
            <UniOverlapButton
              handleClick={() => this._submitPasswordChange()}
              textKey="save"
              icon="save"
              showLoader={this.props.saving}
              disabled={!this.props.formValid}
              tooltipPosition="right" />
            <UniOverlapButton
              handleClick={() => { this._toggleAndResetForm(); }}
              textKey="cancel"
              icon="close"
              secondary={true}
              tooltipPosition="right" />
          </UniOverlapGroup>

        </UniWorkflow>
      </section >
    )
  }
}

function mapStateToProps(state: any) {
  const currentUser = state.authenticatedUser.currentUser;
  return {
    currentUser,
    userUpdater: currentUser.updater,
    saving: state.authenticatedUser.currentUser?.saving,
    formValid: currentUser.newPassword?.isValidAndDirty() && currentUser.confirmNewPassword?.isValidAndDirty() && currentUser.currPassword?.isValidAndDirty()
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateUserData: attemptUpdateCurrentUser,
  attemptChangePassword: attemptResetPasswordDirect,
  toggleModal: toggleChangePasswordWorkflow,
  handleModifyAuthUser,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ChangePasswordContainer)
  ), { componentName: 'ChangePassword', unauthenticated: false })
