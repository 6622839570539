import {
  api,
  authActions,
  inputInitializer
} from '../../internal'

const initialAuthenticatedUser = {
  loading: false,
  currentUser: {}
};

export const authenticatedUser = (state = initialAuthenticatedUser, action: any) => {
  switch (action.type) {
    // case authActions.LOGIN_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // case authActions.LOGIN_SUCCESS:
    //   return {
    //     ...state,
    //     loading: api.pendingReqs.getNum(authActions.LOGIN_REQUEST) > 0,
    //     // currentUser: action.user,
    //     canCreateDealer: action.user
    //   }
    // case authActions.LOGIN_FAILURE:
    //   return {
    //     ...state,
    //     loading: api.pendingReqs.getNum(authActions.LOGIN_REQUEST) > 0,
    //   }
    case authActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case authActions.LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: {},
        loading: api.pendingReqs.getNum(authActions.LOGOUT_REQUEST) > 0,
      }
    case authActions.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case authActions.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        currentUser: action.value
      }
    case authActions.GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: action.pending,
        currentUser: {}
      }
    case authActions.MODIFY_AUTH_USER:
      return {
        ...state,
        currentUser: action.user ?? state.currentUser
      }
    default:
      return state
  }
};

const initialForgotPasswordForm: any = {
  loading: false,
  requestSuccess: false,
  changeSuccess: false,
  ...inputInitializer('', ['email', 'password', 'confirmPassword', 'token'])
};

export const forgotForm = (state = initialForgotPasswordForm, action: any) => {
  switch (action.type) {
    case authActions.UPDATE_FORGOT_FORM:
      return {
        ...state,
        email: action.emailChange ? action.emailChange : state.email,
      }
    case authActions.PASSWORD_RESET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case authActions.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        requestSuccess: true,
        loading: false
      }
    case authActions.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        requestSuccess: false,
        loading: false
      }
    case authActions.UPDATE_PASSWORD_FORM:
      return {
        ...state,
        token: action.formChanges.token ? action.formChanges.token : state.token,
        password: action.formChanges.password ? action.formChanges.password : state.password,
        confirmPassword: action.formChanges.confirmPassword ? action.formChanges.confirmPassword : state.confirmPassword,
      }
    case authActions.PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case authActions.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        loading: api.pendingReqs.getNum() > 0,
        changeSuccess: true,
        ...inputInitializer('', ['email', 'password', 'confirmPassword', 'token'])
      }
    case authActions.PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        loading: api.pendingReqs.getNum() > 0,
        changeSuccess: false,
        ...inputInitializer('', ['email', 'password', 'confirmPassword', 'token'])
      }
    default:
      return state;
  }
};


const initialLoginForm: any = {
  ...inputInitializer('', ['username', 'password'])
};

export const loginForm = (state = initialLoginForm, action: any) => {
  switch (action.type) {
    case authActions.UPDATE_LOGIN_FORM:
      return {
        ...state,
        username: action.formParts.username ? action.formParts.username : state.username,
        password: action.formParts.password ? action.formParts.password : state.password
      }
    default:
      return state;

  }
};

const initialIdentityState: any = {
  linkedAccounts: {
    loading: true, // true so that we dont flash the no-linked-accts message initially
    identities: []
  },
  authIdentity: {
    loading: false,
    acct: {}
  },
};

export const identity = (state = initialIdentityState, action: any) => {
  switch (action.type) {
    case authActions.GET_LINKED_ACCOUNTS_REQUEST:
      return {
        ...state,
        linkedAccounts: {
          identities: [],
          loading: true
        }
      }
    case authActions.GET_LINKED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        linkedAccounts: {
          identities: action.value,
          loading: api.pendingReqs.getNum(authActions.GET_LINKED_ACCOUNTS_REQUEST) > 0,
        }
      }
    case authActions.GET_LINKED_ACCOUNTS_FAILURE:
      return {
        ...state,
        linkedIdentities: {
          identities: [],
          loading: api.pendingReqs.getNum(authActions.GET_LINKED_ACCOUNTS_REQUEST) > 0,
        }
      }
    case authActions.GET_IDENTITY_AUTH_ACCOUNT_REQUEST:
      return {
        ...state,
        authIdentity: {
          loading: false,
          acct: {}
        }
      }
    case authActions.GET_IDENTITY_AUTH_ACCOUNT_SUCCESS:
      return {
        ...state,
        authIdentity: {
          loading: api.pendingReqs.getNum(authActions.GET_IDENTITY_AUTH_ACCOUNT_REQUEST) > 0,
          acct: action.value,
        }
      }
    case authActions.GET_IDENTITY_AUTH_ACCOUNT_FAILURE:
      return {
        ...state,
        authIdentity: {
          loading: api.pendingReqs.getNum(authActions.GET_IDENTITY_AUTH_ACCOUNT_REQUEST) > 0,
          acct: {}
        }
      }
    default:
      return state;

  }
};


