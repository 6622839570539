import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniWorkflow,
  UniInput,
  UniToggle,
  UniTable,
  UniChips,
  UniSteps,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  UniConditionalRender,
  OrganizationC,
  IUniTable_UpdatePaginationSummary,
  IPaginationQueryBuilderParamsC,
  IUniSteps_StepConfig,
  IUniTable_Column,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  Editable,
  IUniChips_Chip,
  IUniTable_Filter,
  EOperationCodesC,
  emailV10n
} from '@unikey/unikey-commons/release/comm';

import {
  toggleAdminInviteModal,
  attemptCreateDealerAdminInvitation,
  handleNewAdminChange,
  attemptRetrieveOrganizations,
  updateOrgListQueryParams,
  clearNewAdminForm,
  updateOrganizationsTableMeta,
  updateNewAdminInviteWorkflowStep,
  getTableSortDirection, getTableParamsFromUpdate, canI,
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  limitedModalOpen: boolean,
  history: any,
  inviteEmail: Editable,
  isLimitedAdmin: boolean,
  limitedOrgs: Map<string, IUniChips_Chip<OrganizationC>>,
  numLimitedOrgs: number,
  loading?: boolean,
  currentStepIndex: number,

  // stuff for the organizations list
  organizations: OrganizationC[],
  orgListQueryParams: IPaginationQueryBuilderParamsC,
  appliedFilters: IUniTable_Filter[],
  appliedSort: IUniTable_Sort,
  paginationSummary: IUniTable_PaginationSummary,
  listLoading: boolean,
  permissionToRedeemCredits: boolean,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginationQueryBuilderParamsC): void,
  getOrgs(): Promise<void>,

  changeWorkflowStep(stepTo: number): void,
  updateNewAdmin(emailChange?: Editable, orgs?: Map<string, IUniChips_Chip<OrganizationC>>, limited?: boolean): void
  toggleModal(): void,
  clearAdminInviteWorkflow(): void,
  createNewAdminInvite(email: string): void,
}

class AdminInviteContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 
  steps: IUniSteps_StepConfig[];

  constructor(props: IProps) {
    super(props);

    this.steps = [
      { nameKey: 'adminEmail' },
      { nameKey: 'adminScope' },
      { nameKey: 'review' }
    ];

    this._updateTable = buildTableUpdateFunc(
      props.getOrgs,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getOrgs();
  }

  _inviteAdminAndReloadList() {
    this.props.createNewAdminInvite(this.props.inviteEmail.value);
  }

  _handleEmailChange = (email: Editable) => {
    this.props.updateNewAdmin(email);
  }

  _handleLimitedChange = (isLimited: boolean) => {
    this.props.updateNewAdmin(undefined, undefined, isLimited);
  }

  _toggleSelectedOrg = (org: OrganizationC): any => {
    // dont want to modify the redux data here, 
    // so we need to clone the map and
    // modify the clone rather than the original
    const mapClone = new Map(this.props.limitedOrgs);
    if (mapClone.has(org.id)) {
      mapClone.delete(org.id);
    } else {
      mapClone.set(org.id, {
        nameKey: org.name,
        id: org.id,
      });
    }
    this.props.updateNewAdmin(undefined, mapClone);
  }

  _buildColumnsAndActions() {
    const sortDirection = getTableSortDirection(this.props.orgListQueryParams);
    const columns: Map<string, IUniTable_Column> = new Map()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        sortDirection,
        size: 10
      });

    const actions = new Map();

    return { columns, actions };
  }

  _clearAndToggleModal = () => {
    this.props.clearAdminInviteWorkflow();
    this.props.toggleModal();
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='admin-invite-container'>
        <UniWorkflow
          titleKey="inviteNewAdmin"
          titleIcon="personAdd"
          size="wider"
          inModal={true}
          handleClose={this.props.toggleModal}>

          <UniSteps
            steps={this.steps}
            activeStepIndex={this.props.currentStepIndex}
            allStepsUnlocked={false}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          {/* First Step - Email */}
          <UniConditionalRender visible={this.props.currentStepIndex === 0}>

            <p><UniLocalize translate="_adminInviteEmailStepMessage" /></p>

            <UniInput
              editable={this.props.inviteEmail}
              placeholderKey="adminEmail"
              labelKey="adminEmail"
              handleUpdate={this._handleEmailChange}
              focusOnInitialRender={true}
              validations={new Map([emailV10n])} />

            {/* step actions */}
            <UniOverlapGroup foldEarly={true}>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={!this.props.inviteEmail.valid}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleModal}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End First Step */}

          {/* Second Step - LimitedAdmin */}
          <UniConditionalRender visible={this.props.currentStepIndex === 1}>

            <p><UniLocalize translate="_adminInviteScopeStepMessage" /></p>

            <UniToggle
              mini={true}
              options={[
                {
                  value: false,
                  nameKey: 'fullAdmin'
                },
                {
                  value: true,
                  nameKey: 'limitedAdmin'
                }
              ]}
              value={this.props.isLimitedAdmin}
              handleUpdate={this._handleLimitedChange} />

            <UniConditionalRender visible={!this.props.isLimitedAdmin} >
              <p><UniLocalize translate="_adminInviteFullAdminExplination" /></p>
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.isLimitedAdmin} >
              <p><UniLocalize translate="_adminInviteLimitedAdminExplination" /></p>
              {/* <UniChips
                    labelKey="asLimitedAdminOfTheFollowingOrganizations"
                    collection={this.props.limitedOrgs}
                    handleRemoveChip={this._toggleSelectedOrg}/> */}

              {/* selectable list of organizations to make user a limited admin of */}
              <UniTable
                selectable={true}
                searchable={true}
                nameKey="limitedOrgs"
                newItemNameKey="organization"
                selectedItems={this.props.limitedOrgs}
                handleRowItemSelectToggle={this._toggleSelectedOrg}
                handleUpdate={this._updateTable}
                data={this.props.organizations}
                paginationSummary={this.props.paginationSummary}
                columnConfig={columns}
                actionsConfig={actions}
                activeSort={this.props.appliedSort}
                activeFilters={this.props.appliedFilters}
                showLoader={this.props.listLoading} />
            </UniConditionalRender>

            {/* step actions */}
            <UniOverlapGroup foldEarly={true}>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={this.props.isLimitedAdmin && this.props.limitedOrgs.size === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this._clearAndToggleModal}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>

          </UniConditionalRender>
          {/* End step 2 */}

          {/* Step 3 - Review */}
          <UniConditionalRender visible={this.props.currentStepIndex === 2}>

            <p><UniLocalize translate="_adminInviteReviewStepMessage" /></p>
            <h4><UniLocalize translate="adminEmail" /></h4>
            <p>{this.props.inviteEmail.value}</p>

            <UniConditionalRender visible={!this.props.isLimitedAdmin}>
              <h4><UniLocalize translate="asFullAdmin" /></h4>
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.isLimitedAdmin}>
              <UniChips
                labelKey="asLimitedAdminOfTheFollowingOrganizations"
                collection={this.props.limitedOrgs}
                handleRemoveChip={this._toggleSelectedOrg}
                disabled={true}
                maxCount={6} />
            </UniConditionalRender>

            {/* step actions */}
            <UniOverlapGroup foldEarly={true}>
              <UniOverlapButton
                handleClick={() => this._inviteAdminAndReloadList()}
                textKey="save"
                icon="save"
                disabled={!this.props.inviteEmail.valid && this.props.isLimitedAdmin && this.props.limitedOrgs.size === 0}
                showLoader={!!this.props.loading} />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleModal}
                textKey="cancel"
                icon="close"
                secondary={true} />
            </UniOverlapGroup>

          </UniConditionalRender>
          {/* End step 3 */}

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    loading: state.createAdmin.loading,
    limitedModalOpen: state.createAdmin.modalOpen,
    inviteEmail: state.createAdmin.newInvite.email,
    isLimitedAdmin: state.createAdmin.newInvite.isLimited,
    limitedOrgs: state.createAdmin.newInvite.limitedOrgs,
    numLimitedOrgs: state.createAdmin.newInvite.limitedOrgs?.size, // needed to trigger this component to re-render as the map size changes

    currentStepIndex: state.createAdmin.newInvite.workflowStepIndex,

    // stuff for the list of organizations
    organizations: state.organizations.data.models,
    orgListQueryParams: state.organizations.queryParams,
    appliedFilters: state.organizations.tableFilters,
    appliedSort: state.organizations.tableSorts[0],
    paginationSummary: state.organizations.paginationSummary,
    listLoading: state.organizations.loading,

    // permissions
    permissionToRedeemCredits: canI(EOperationCodesC.RedeemCredits, state.dealer.dealerData.id),
    permissionToUpdateDealer: canI(EOperationCodesC.UpdateDealer, state.dealer.dealerData.id),
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  createNewAdminInvite: attemptCreateDealerAdminInvitation,
  updateNewAdmin: handleNewAdminChange,
  toggleModal: toggleAdminInviteModal,
  clearAdminInviteWorkflow: clearNewAdminForm,

  changeWorkflowStep: updateNewAdminInviteWorkflowStep,


  // stuff for the list of organizations
  getOrgs: attemptRetrieveOrganizations,
  updateQueryParams: updateOrgListQueryParams,
  updateTableMeta: updateOrganizationsTableMeta,

}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminInviteContainer)
  ), { componentName: 'AdminInvite' })
