import * as Oidc from 'oidc-client'
import UAParser from 'ua-parser-js'

import {
  keepUiTheme,
  darkThemeName
} from '@unikey/unikey-commons/release/comm';

import {
  api,
  cdpVersion,
  oidcClientId,
  oidcPartnerBrandId,
  oidcPartnerBrandCode,
} from '../internal';

export const buildOidcAcrValues = () => {
  // OIDC Authorization configured with redux by following the guide here:
  // https://github.com/maxmantz/redux-oidc/blob/HEAD/docs/API.md
  const userAgent = new UAParser();
  Oidc.Log.logger = console;
  const browserInfo = userAgent.getBrowser();
  const osInfo = userAgent.getOS();
  const currUiTheme = keepUiTheme();

  const acrVals = `
    appId:${oidcClientId}
    tenant:${oidcPartnerBrandId}
    tenantCode:${oidcPartnerBrandCode}
    tenantClientId:${oidcClientId}
    loginContext:Web
    deviceType:Browser
    deviceName:${browserInfo.name?.replace(/\s/g, '-')},(${osInfo.name?.replace(/\s/g, '-')})
    deviceMake:${browserInfo.name?.replace(/\s/g, '-')},${browserInfo.version?.replace(/\s/g, '-')}
    deviceModel:${osInfo.name?.replace(/\s/g, '-')},${osInfo.version?.replace(/\s/g, '-')}
    deviceVersion:cdp-${cdpVersion}
    isDark:${currUiTheme === darkThemeName}
    `;

  return acrVals;
}