import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  UniTable,
  UniConfirm,
  UniConditionalRender,
  IUniTable_Column,
  IPaginatedResponseC,
  IPaginatedParamsC,
  IUserC,
  IUniConfirm_Config,
  UserC,
  IUniTable_Sort,
  IUniTable_Filter,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IPaginationQueryBuilderParamsC,
  EOperationCodesC,
  EUserStatusCS,
  emptyGuid
} from '@unikey/unikey-commons/release/comm';

import {
  portalRedirect,
  canI,
  AdminInviteContainer,
  getTableSortDirection, getTableParamsFromUpdate, 
  buildTableUpdateFunc, TTableUpdateFunc,
  attemptRetrieveDealerAdmins,
  toggleAdminInviteModal,
  updateAdminListQueryParams,
  updateAdminsTableMeta,
  attemptRemoveAdmin,
  openConfirmModal,
  closeConfirmModal,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig,
  ENavPages,
  IRemoveAdminActionParams
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  admins: UserC[],
  dealerId: string,
  modalOpen: boolean,
  adminListQueryParams: IPaginationQueryBuilderParamsC,
  appliedFilters: IUniTable_Filter[],
  appliedSort: IUniTable_Sort,
  paginationSummary: IUniTable_PaginationSummary,
  listLoading: boolean,
  permissionToCreateAdmin: boolean,
  permissionToEditAdminRole: boolean,
  permissionToRemoveAdmin: boolean,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginationQueryBuilderParamsC): void,
  toggleCreateModal(): void,
  removeAdmin(params: IRemoveAdminActionParams): Promise<void>,
  openRemoveAdminConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
  getDealerAdmins(): Promise<void>,
}

class AdminListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this._updateTable = buildTableUpdateFunc(
      props.getDealerAdmins,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getDealerAdmins();
  }

  _removeAdminAndRefreshList(adminId: string): void {
    this.props.removeAdmin({ adminId, dealerId: this.props.dealerId }).then(() => {
      this.componentDidMount();
    })
  }

  _buildColumnsAndActions() {
    const columns= new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: true,
        sortDirection: getTableSortDirection(this.props.adminListQueryParams),
        isPrimaryFilter: true,
        size: 4
      })
      .set('firstName', {
        nameKey: 'firstName',
        isSortable: false,
        size: 2
      })
      .set('lastName', {
        nameKey: 'lastName',
        isSortable: false,
        size: 2
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        sortDirection: getTableSortDirection(this.props.adminListQueryParams),
        size: 2
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 2
      });

    const actions = new Map();
    actions.set('viewEditAccess', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      // TODO: since we dont have a way to look up an admin by id directly, we have to pass the email and look them up by querying the list
      // because of this, we are passing the urlEncoded email here instead of the `rowItem.id` (what we should be able to do)
      // Once we have a route for `dealers/:dId/users/:uId` we'll be able to remove this "encode email" part.
      func: (rowItem: UserC) => {
        const adminIdentifier = rowItem.id === emptyGuid ? encodeURIComponent(rowItem.email!) as string : rowItem.id;
        portalRedirect(navConfig.get(ENavPages.adminDetails)!.linkTo([adminIdentifier])!)
      },
      evalDisabled: (rowItem: UserC) => false,
      evalVisible: (rowItem: UserC) => true,
    })
    // .set('remove', {
    //   nameKey: 'remove',
    //   icon: 'delete',
    //   func: (rowItem: UserC) => this.props.openRemoveAdminConfirmDialog({
    //     titleKey: 'removeAdmin',
    //     messageKeys: ['youAreAboutToRemoveThisAdmin', rowItem.email!],
    //     confirmTextKey: 'remove',
    //     cancelHandler: this.props.closeConfirmModal,
    //     confirmHandler: () => {
    //       this._removeAdminAndRefreshList(rowItem.id!);
    //       this.props.closeConfirmModal()
    //     }
    //   }),
    //   evalDisabled: (rowItem: UserC) => !this.props.permissionToRemoveAdmin,
    //   evalVisible: (rowItem: UserC) => this.props.permissionToRemoveAdmin,
    // });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='adminList-container'>
        <UniTable
          searchable={true}
          nameKey="adminsList"
          newItemNameKey="inviteAdmin"
          handleUpdate={this._updateTable}
          handleCreateClick={this.props.permissionToCreateAdmin ? this.props.toggleCreateModal : undefined}
          data={this.props.admins}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          actionsConfig={actions}
          activeSort={this.props.appliedSort}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />

        {/* Workflow Modal */}
        <UniConditionalRender visible={this.props.modalOpen}>
          <AdminInviteContainer />
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    admins: state.admins.data.models,
    modalOpen: state.createAdmin.modalOpen,
    adminListQueryParams: state.admins.queryParams,
    appliedFilters: state.admins.tableFilters,
    appliedSort: state.admins.tableSorts[0],
    paginationSummary: state.admins.paginationSummary,
    dealerId: state.dealer.dealerData.id,
    listLoading: state.admins.loading,
    // permissions
    permissionToCreateAdmin: canI(EOperationCodesC.CreateAdmin, state.dealer.dealerData.id),
    permissionToRemoveAdmin: canI(EOperationCodesC.DeleteAdmin, state.dealer.dealerData.id),
    permissionToEditAdminRole: canI(EOperationCodesC.CreateRoleUser, state.dealer.dealerData.id) || canI(EOperationCodesC.DeleteRoleUser, state.dealer.dealerData.id)
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleCreateModal: toggleAdminInviteModal,
  getDealerAdmins: attemptRetrieveDealerAdmins,
  removeAdmin: attemptRemoveAdmin,
  updateQueryParams: updateAdminListQueryParams,
  updateTableMeta: updateAdminsTableMeta,
  openRemoveAdminConfirmDialog: openConfirmModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminListContainer)
  ), { componentName: 'AdminList' })
