import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { PermissionMapC } from '@unikey/unikey-commons/release/comm'

import { api } from '../internal'
interface IProps {
  oidcUpdater: number,
  currentExpiry?: string,
  tokenExpired?: boolean,
  tokenRefreshAttemptCompleted?: boolean,
  userPermissions?: PermissionMapC,
  activeDealerId?: string
}

class AuthExpiryContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
        <>
          <div className="auth-expiry-countdown-container">
            dealerId: {this.props.activeDealerId?.slice(0,5)} | {this.props.userPermissions?.availablePermissionsMap?.size ?? '?'}ps | {this.props.oidcUpdater}rfsh | Current Token Expires: {this.props?.currentExpiry}
          </div>
        </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    activeDealerId: state.portal.activeDealer?.id,
    tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted, 
    currentExpiry: state.oidcAuthStatus.currentExpiry,
    tokenExpired: state.oidcAuthStatus.isExpired,
    oidcUpdater: state.oidcAuthStatus.updater,
    userPermissions: state.authenticatedUser.currentUser?.permissions,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthExpiryContainer)
