import { stat } from 'fs';
import {
  api,

  adminActions,
  inputInitializer,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState,
} from '../../internal'

const map = new Map();
const initialAdminListState = populateInitialReduxPaginatedListState('email', 'forward');

export const admins = (state = initialAdminListState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMINS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case adminActions.GET_ADMINS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialAdminListState.data
      }
    case adminActions.UPDATE_ADMINS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case adminActions.UPDATE_ADMINS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const freshNewInviteFields = () => {
  return {
    ...inputInitializer('', ['email']),
    isLimited: false,
    limitedOrgs: map,
    workflowStepIndex: 0
  };
}
const initialNewAdminState: any = {
  loading: false,
  newInvite: freshNewInviteFields(),
  modalOpen: false
};

export const createAdmin = (state = initialNewAdminState, action: any) => {
  switch (action.type) {
    case adminActions.HANDLE_NEW_ADMIN_UPDATE:
        return {
          ...state,
          newInvite: {
            isLimited: action.isLimited !== undefined ? action.isLimited : state.newInvite.isLimited,
            limitedOrgs: action.limitedOrgs || state.newInvite.limitedOrgs,
            email: action.emailChange ? action.emailChange : state.newInvite.email,
            workflowStepIndex: state.newInvite.workflowStepIndex
          }
        }
      case adminActions.CREATE_ADMIN_INVITE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case adminActions.CREATE_ADMIN_INVITE_SUCCESS:
        return {
          ...state,
          loading: api.pendingReqs.getNum(adminActions.CREATE_ADMIN_INVITE_REQUEST) > 0,
          modalOpen: false,
          newInvite: {
            ...inputInitializer('', ['email']),
            isLimited: false,
            limitedOrgs: map,
            workflowStepIndex: 0
          },
        }
      case adminActions.CREATE_ADMIN_INVITE_FAILURE:
        return {
          ...state,
          loading: api.pendingReqs.getNum(adminActions.CREATE_ADMIN_INVITE_REQUEST) > 0
        }
      case adminActions.UPDATE_NEW_ADMIN_INVITE_WORKFLOW_STEP:
        return {
          ...state,
          newInvite: Object.assign(state.newInvite, {
            workflowStepIndex: action.stepTo
          })
        }
      case adminActions.TOGGLE_ADMIN_INVITE_MODAL:
        return {
          ...state,
          modalOpen: !state.modalOpen
        }
      case adminActions.CLEAR_NEW_ADMIN_FORM:
        return {
          ...state,
          newInvite: freshNewInviteFields()
        }
      default:
        return state;
      }
}

const initialAdminDetailsState: any = {
  loading: true,
  adminData: {},
  inviteResend: {
    success: false,
    loading: false
  },
  adminPermissions: {
    success: false,
    loading: false,
    organizations: [],
    currentRoleOrgsCombo: [],
    isOwner: false,
  },
  permissionsEditor: {
    jobReqsOutstanding: 0,
    jobReqsTotal: 1,
    loading: false,
    modalOpen: false,
    isLimited: true,
    limitedOrgs: [],
    initialRoleOrgs: [],
    initialIsOwner: false,
  }
};

export const adminDetails = (state = initialAdminDetailsState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        adminData: action.value,
        inviteResend: initialAdminDetailsState.inviteResend,
      }
    case adminActions.GET_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        adminData: {}
      }
    case adminActions.GET_ADMIN_DETAILS_INVITE_REQUEST:
      return {
        ...state,
        loading: action.pending,
      }
    case adminActions.GET_ADMIN_DETAILS_INVITE_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        adminData: state.adminData?.associateDealerInvitation(action.value)
      }
    case adminActions.GET_ADMIN_DETAILS_INVITE_FAILURE:
      return {
        ...state,
        loading: action.pending
      }
    case adminActions.RESEND_ADMIN_INVITE_REQUEST:
      return {
        ...state,
        inviteResend: {
          success: false,
          loading: true
        }
      }
    case adminActions.RESEND_ADMIN_INVITE_SUCCESS:
      return {
        ...state,
        inviteResend: {
          success: true,
          loading: action.pending
        }
      }
    case adminActions.RESEND_ADMIN_INVITE_FAILURE:
      return {
        ...state,
        inviteResend: {
          success: false,
          loading: action.pending
        }
      }
    case adminActions.GET_ADMIN_SCOPE_REQUEST:
      return {
        ...state,
        adminPermissions: {
          ...initialAdminDetailsState.adminPermissions,
          loading: true
        }
      }
    case adminActions.GET_ADMIN_SCOPE_SUCCESS:
      return {
        ...state,
        adminPermissions: {
          success: true,
          loading: api.pendingReqs.getNum(adminActions.GET_ADMIN_SCOPE_REQUEST) > 0,
          organizations: action.limitedAdminOrgs,
          initialRoleOrgs: action.currentRoleOrgsCombo,
          isOwner: action.isOwner
        }
      }
    case adminActions.GET_ADMIN_SCOPE_FAILURE:
      return {
        ...state,
        adminPermissions: initialAdminDetailsState.adminPermissions,
      }
    case adminActions.RESTORE_INITIAL_ADMIN_PERMISSIONS_EDIT_STATE:
      return {
        ...state,
        permissionsEditor: {
          loading: false,
          modalOpen: state.permissionsEditor.modalOpen,
          initialRoleOrgs: action.currentRoleOrgsCombo,
          initialIsOwner: action.isOwner,
          isLimited: !action.isOwner,
          limitedOrgs: action.limitedAdminOrgMap
        },
      }
    case adminActions.TOGGLE_ADMIN_PERMISSIONS_MODAL:
      return {
        ...state,
        permissionsEditor: {
          ...state.permissionsEditor,
          modalOpen: !state.permissionsEditor.modalOpen
        }
      }
    case adminActions.HANDLE_ADMIN_PERMISSIONS_UPDATE:
      return {
        ...state,
        permissionsEditor: {
          loading: state.permissionsEditor.loading,
          modalOpen: state.permissionsEditor.modalOpen, // doesnt ever change here
          initialIsOwner: state.permissionsEditor.initialIsOwner, // doesnt ever change
          initialRoleOrgs: state.permissionsEditor.initialRoleOrgs, // doesnt ever change here
          isLimited: action.isLimited !== undefined ? action.isLimited : state.permissionsEditor.isLimited,
          limitedOrgs: action.limitedOrgs || state.permissionsEditor.limitedOrgs
        }
      }
    case adminActions.UPDATE_ADMIN_PERMISSIONS_JOB_STATUS:
      const outstandingReqs = api.pendingReqs.getNum(adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST);
      return {
        ...state,
        permissionsEditor: {
          ...state.permissionsEditor,
          jobReqsTotal: (outstandingReqs > state.permissionsEditor.jobReqsTotal) ? outstandingReqs : state.permissionsEditor.jobReqsTotal,
          jobReqsOutstanding: outstandingReqs
        }
      }
    case adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST:
      return {
        ...state,
        permissionsEditor: {
          ...state.permissionsEditor,
          loading: true,
          jobReqsTotal: 1,
          jobReqsOutstanding: 0,
        }
      }
    case adminActions.UPDATE_ADMIN_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsEditor: {
          ...state.permissionsEditor,
          loading: api.pendingReqs.getNum(adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST) > 0,
        }
      }
    case adminActions.UPDATE_ADMIN_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissionsEditor: {
          ...state.permissionsEditor,
          loading: api.pendingReqs.getNum(adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST) > 0,
        }
      }
    default:
      return state
  }
}

const initialInviteDetailsState = {
  loading: true,
  inviteData: {},
};

export const inviteDetails = (state = initialInviteDetailsState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMIN_INVITE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMIN_INVITE_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        inviteData: action.value
      }
    case adminActions.GET_ADMIN_INVITE_FAILURE:
      return {
        ...state,
        loading: action.pending
      }
    default:
      return state
  }
}