import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames';

import {
  UniButton,
  UniFooter,
  UniLocalize,
  UniConditionalRender,
  UniImg,
  UniThemeToggle
} from '@unikey/unikey-commons/release/comm';

import {
  partnerSupportLink,
  privacyPolicyDocument,
  termsOfServiceDocument
} from '@alias-environment';

import {
  portalRedirect,
  logoutKey,
  navConfig,
  ENavPages,
  setDarkModeTheme,
  toggleFeedbackModal,
  toggleAvatarMenu,
} from '../internal'

import poweredByUniKeyLogo from '../assets/powered-by-unikey-small-grey.png';
interface IProps {
  history?: any,
  match?: any,
  logo?: string,
  activePath: string,
  inPortal?: boolean,
  canUserCreateDealer?: boolean,
  toggleFeedbackModal(): void,
  changeDarkModeTheme(theme: string): void,
}

class FooterContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const isInsidePortal: boolean = /portal\//.test(this.props.activePath);

    const footerClass = classNames('footer-container', {
      'access-footer': !isInsidePortal
    });

    return (
      <section className={footerClass}>
        <UniFooter>
          <Container>
            <Row>
              {/* in portal layout */}
              <UniConditionalRender visible={this.props.inPortal}>
                <Col md={12} lg={3} className="links">
                  <Row justify="center">
                    <Col sm={4} lg={12}>
                      <UniThemeToggle
                        className="footer-toggle"
                        onThemeChange={this.props.changeDarkModeTheme} />
                    </Col>

                    <Col sm={4} lg={12}>
                      <UniButton
                        className="footer-toggle"
                        icon="star"
                        textKey="feedback"
                        theme="inverted"
                        hideText={false}
                        handleClick={this.props.toggleFeedbackModal} />
                    </Col>
                    <Col sm={4} lg={12}>
                      <UniButton
                        className="footer-toggle"
                        icon="logout"
                        textKey="logout"
                        theme="inverted"
                        hideText={false}
                        handleClick={() => portalRedirect(logoutKey)} />
                    </Col>
                  </Row>
                </Col>
              
              
                <Col sm={4} lg={3} className="links">
                  <Col xs={12}>
                    <h4 className="link-heading"><UniLocalize translate="sitePages" /></h4>
                  </Col>
                  {/* internal links */}
                  <NavLink to={navConfig.get(ENavPages.dealer)!.linkTo([])}><UniLocalize translate={ENavPages.dealer} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.admins)!.linkTo([])}><UniLocalize translate={ENavPages.admins} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.organizations)!.linkTo([])}><UniLocalize translate={ENavPages.organizations} /></NavLink>
                </Col>

                <Col sm={4} lg={3} className="links">
                  <Col xs={12}>
                    <h4 className="link-heading"><UniLocalize translate="sitePages" /></h4>
                  </Col>
                  {/* internal links */}
                  <NavLink to={navConfig.get(ENavPages.myAccount)!.linkTo([])}><UniLocalize translate={ENavPages.myAccount} /></NavLink>
                  <UniConditionalRender visible={this.props.canUserCreateDealer}>
                    <NavLink to={navConfig.get(ENavPages.dealerCreate)!.linkTo([])}><UniLocalize translate="registerNewDealer" /></NavLink>
                  </UniConditionalRender>
                  <NavLink to={navConfig.get(ENavPages.version)!.linkTo([])}><UniLocalize translate={ENavPages.version} /></NavLink>
                </Col>

                {/*  about & legal links */}
                <Col sm={4} lg={3} className="links">
                    <Col xs={12}>
                      <h4 className="link-heading"><UniLocalize translate="legalCenter" /></h4>
                    </Col>
                    <a href={termsOfServiceDocument}><UniLocalize translate="termsOfUse" /></a>
                    <a href={privacyPolicyDocument}><UniLocalize translate="privacyPolicy" /></a>
                    <a href={partnerSupportLink}><UniLocalize translate="support" /></a>
                </Col>
              </UniConditionalRender>

              {/* out of portal layout */}
              <UniConditionalRender visible={!this.props.inPortal}>
                {/*  about & legal links */}
                <Col sm={12} className="links non-portal">
                  <a href={termsOfServiceDocument}><UniLocalize translate="termsOfUse" /></a>
                  <a href={privacyPolicyDocument}><UniLocalize translate="privacyPolicy" /></a>
                  <a href={partnerSupportLink}><UniLocalize translate="support" /></a>
                  <NavLink to={navConfig.get(ENavPages.version)!.linkTo([])}><UniLocalize translate={ENavPages.version} /></NavLink>
                </Col>

                <Col sm={12}>
                  <UniThemeToggle
                    className="footer-toggle non-portal"
                    onThemeChange={this.props.changeDarkModeTheme} />
                </Col>
              </UniConditionalRender>
            </Row>


            <Row>
              <Col>
                <div className="copyright-notice">{`Copyright © 2016-${new Date().getFullYear()} UniKey Technologies, Inc., All rights reserved.`}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <a href="http://unikey.com">
                  <UniImg className="powered-by-unikey-logo" src={poweredByUniKeyLogo} textKey="UniKey logo" />
                </a>
              </Col>
            </Row>
          </Container>
        </UniFooter>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  const activePath = state.router.location.pathname;
  return {
    activePath,
    inPortal: activePath?.indexOf('/portal') !== -1,
    // is the user allowed to Create Dealer?
    canUserCreateDealer: state.oidcUser.canUserCreateDealer,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleAvatarMenu,
  toggleFeedbackModal,
  changeDarkModeTheme: setDarkModeTheme,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer)