import { Dispatch } from 'redux'

import {
  AuthUserC,
  UserC,
  RoleC,
  OrganizationC,
  IPaginationQueryBuilderParamsC,
  INonPaginatedResponseC,
  IRoleC,
  IUserInviteRequestC,
  IUniTable_UpdatePaginationSummary,
  InviteC,
  IUniChips_Chip,
  Editable,
  EInviteStatusC,
  EMessageType,
  ITrackedRequest,
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  checkJobStatusOnInterval,
  ApiReduxAction,
  IExposeRedux,
  portalRedirect,
  oops404Key
} from '../../internal'

export enum adminActions {
  GET_ADMINS_REQUEST = 'GET_ADMIN_REQUEST',
  GET_ADMINS_SUCCESS = 'GET_ADMIN_SUCCESS',
  GET_ADMINS_FAILURE = 'GET_ADMIN_FAIL',

  UPDATE_ADMINS_QUERY_PARAMS = 'UPDATE_ADMIN_QUERY_PARAMS',
  UPDATE_ADMINS_TABLE_META = 'UPDATE_ADMIN_TABLE_META',

  GET_ADMIN_DETAILS_REQUEST = 'GET_ADMIN_DETAILS_REQUEST',
  GET_ADMIN_DETAILS_SUCCESS = 'GET_ADMIN_DETAILS_SUCCESS',
  GET_ADMIN_DETAILS_FAILURE = 'GET_ADMIN_DETAILS_FAILURE',

  GET_ADMIN_DETAILS_INVITE_REQUEST = 'GET_ADMIN_DETAILS_INVITE_REQUEST',
  GET_ADMIN_DETAILS_INVITE_SUCCESS = 'GET_ADMIN_DETAILS_INVITE_SUCCESS',
  GET_ADMIN_DETAILS_INVITE_FAILURE = 'GET_ADMIN_DETAILS_INVITE_FAILURE',

  GET_ADMIN_INVITE_REQUEST = 'GET_ADMIN_INVITE_REQUEST',
  GET_ADMIN_INVITE_SUCCESS = 'GET_ADMIN_INVITE_SUCCESS',
  GET_ADMIN_INVITE_FAILURE = 'GET_ADMIN_INVITE_FAILURE',

  DELETE_ADMIN_INVITE_REQUEST = 'DELETE_ADMIN_INVITE_REQUEST',
  DELETE_ADMIN_INVITE_SUCCESS = 'DELETE_ADMIN_INVITE_SUCCESS',
  DELETE_ADMIN_INVITE_FAILURE = 'DELETE_ADMIN_INVITE_FAILURE',

  RESEND_ADMIN_INVITE_REQUEST = 'RESEND_ADMIN_INVITE_REQUEST',
  RESEND_ADMIN_INVITE_SUCCESS = 'RESEND_ADMIN_INVITE_SUCCESS',
  RESEND_ADMIN_INVITE_FAILURE = 'RESEND_ADMIN_INVITE_FAILURE',

  REMOVE_ADMIN_REQUEST = 'REMOVE_ADMIN_REQUEST',
  REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS',
  REMOVE_ADMIN_FAILURE = 'REMOVE_ADMIN_FAILURE',

  UPDATE_NEW_ADMIN_INVITE_WORKFLOW_STEP = 'UPDATE_NEW_ADMIN_INVITE_WORKFLOW_STEP',

  CREATE_ADMIN_INVITE_REQUEST = 'CREATE_ADMIN_INVITE_REQUEST',
  CREATE_ADMIN_INVITE_SUCCESS = 'CREATE_ADMIN_INVITE_SUCCESS',
  CREATE_ADMIN_INVITE_FAILURE = 'CREATE_ADMIN_INVITE_FAILURE',

  GET_ADMIN_SCOPE_REQUEST = 'GET_ADMIN_SCOPE_REQUEST',
  GET_ADMIN_SCOPE_SUCCESS = 'GET_ADMIN_SCOPE_SUCCESS',
  GET_ADMIN_SCOPE_FAILURE = 'GET_ADMIN_SCOPE_FAILURE',

  HANDLE_NEW_ADMIN_UPDATE = 'HANDLE_NEW_ADMIN_UPDATE',

  TOGGLE_ADMIN_INVITE_MODAL = 'TOGGLE_ADMIN_INVITE_MODAL',
  CLEAR_NEW_ADMIN_FORM = 'CLEAR_NEW_ADMIN_FORM',

  TOGGLE_ADMIN_PERMISSIONS_MODAL = 'TOGGLE_ADMIN_PERMISSIONS_MODAL',
  HANDLE_ADMIN_PERMISSIONS_UPDATE = 'HANDLE_ADMIN_PERMISSIONS_UPDATE',
  RESTORE_INITIAL_ADMIN_PERMISSIONS_EDIT_STATE = 'RESTORE_INITIAL_ADMIN_PERMISSIONS_EDIT_STATE',

  UPDATE_ADMIN_PERMISSIONS_REQUEST = 'UPDATE_ADMIN_PERMISSIONS_REQUEST',
  UPDATE_ADMIN_PERMISSIONS_SUCCESS = 'UPDATE_ADMIN_PERMISSIONS_SUCCESS',
  UPDATE_ADMIN_PERMISSIONS_FAILURE = 'UPDATE_ADMIN_PERMISSIONS_FAILURE',
  UPDATE_ADMIN_PERMISSIONS_JOB_STATUS = 'UPDATE_ADMIN_PERMISSIONS_JOB_STATUS',
}

// ADMINS
const getAdminListAction = new ApiReduxAction({
  request: { type: adminActions.GET_ADMINS_REQUEST },
  success: { type: adminActions.GET_ADMINS_SUCCESS },
  failure: {
    type: adminActions.GET_ADMINS_FAILURE,
    title: 'getAdminsFail',
  },
  handle404: (dux: IExposeRedux, error: any) => {
    portalRedirect(oops404Key);
  },
  tableMetaUpdate: {
    type: adminActions.UPDATE_ADMINS_TABLE_META
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux) => {
  const dealerId = dux.getState().portal.activeDealer?.id;
  const params = dux.getState().admins.queryParams;
  return api.deal.getDealerUsers.bind(api.deal, dealerId, params);
});
export const attemptRetrieveDealerAdmins = getAdminListAction.go;

export function updateAdminsTableMeta(metaUpdate: IUniTable_UpdatePaginationSummary) {
  return {
    type: adminActions.UPDATE_ADMINS_TABLE_META,
    ...metaUpdate
  }
}

export function updateAdminListQueryParams(queryParams: IPaginationQueryBuilderParamsC) {
  return {
    type: adminActions.UPDATE_ADMINS_QUERY_PARAMS,
    queryParams
  }
}


export function updateNewAdminInviteWorkflowStep(stepTo: number) {
  return {
    type: adminActions.UPDATE_NEW_ADMIN_INVITE_WORKFLOW_STEP,
    stepTo
  };
}

export interface IRemoveAdminActionParams {
  dealerId: string,
  adminId: string
}
// remove admin
const removeAdminAction = new ApiReduxAction({
  request: { type: adminActions.REMOVE_ADMIN_REQUEST },
  success: {
    type: adminActions.REMOVE_ADMIN_SUCCESS,
    title: 'removeAdminSuccess',
    message: 'successfullyRemovedAdmin'
  },
  failure: {
    type: adminActions.REMOVE_ADMIN_FAILURE,
    title: 'removeAdminFail',
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, params: IRemoveAdminActionParams) => {
  return api.user.removeUserFromDealer.bind(api.user, params.adminId, params.dealerId);
});
export const attemptRemoveAdmin = removeAdminAction.go;

export interface IGetAdminActionParams {
  adminId?: string,
  adminEmail?: string
}
// get admin details
const getAdminDetailsAction = new ApiReduxAction({
  request: { type: adminActions.GET_ADMIN_DETAILS_REQUEST },
  success: { type: adminActions.GET_ADMIN_DETAILS_SUCCESS },
  failure: {
    type: adminActions.GET_ADMIN_DETAILS_FAILURE,
    title: 'getAdminDetailsFail',
  },
  handle404: (dux: IExposeRedux, error: any) => {
    portalRedirect(oops404Key);
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, params: IGetAdminActionParams) => {
  const dealerId = dux.getState().portal.activeDealer?.id;
  // preferred to get the admin details by userId
  if (params.adminId) {
    return api.user.getDealerUser.bind(api.user, dealerId, params.adminId);
  }
  else if (params.adminEmail) {
    // if we only have the email, then get the admin by email
    return api.deal.getDealerUser.bind(api.user, dealerId, params.adminEmail);
  }
  return Promise.reject('neither adminId nor adminEmail provided');
});
export const attemptRetrieveAdminDetails = getAdminDetailsAction.go;

// change
export function handleNewAdminChange(emailChange?: Editable, orgMap?: Map<string, IUniChips_Chip<OrganizationC>>, limited?: boolean) {
  return {
    type: adminActions.HANDLE_NEW_ADMIN_UPDATE,
    emailChange,
    limitedOrgs: orgMap,
    isLimited: limited
  };
}

// change
export function clearNewAdminForm() {
  return {
    type: adminActions.CLEAR_NEW_ADMIN_FORM
  };
}

// export function createAdminInviteRequest() {
//   return {
//     type: adminActions.CREATE_ADMIN_INVITE_REQUEST
//   }
// }

// export function createAdminInviteSuccess() {
//   return {
//     type: adminActions.CREATE_ADMIN_INVITE_SUCCESS,
//   }
// }

// export function createAdminInviteFailure() {
//   return {
//     type: adminActions.CREATE_ADMIN_INVITE_FAILURE
//   }
// }

// // before we can send the request with the email we need to retrieve the appropriate roles for the admin
// // Need to query for dealer roles & roles for each desired organziation
// // then send off the request with the filled in body
// export async function attemptCreateNewAdminInvite() {
//   return (dispatch: Dispatch<any>, getState: any): any => {
    
//   //   return inviteBody;
//   // }).then((body: IUserInviteRequestC) => {
//     return api.user.createUserInvitation(activeDealerId, inviteBody, tracking).then(() => {
//       dispatch(createAdminInviteSuccess())
//       // after successful add, reload the list of admins
//       dispatch(attemptRetrieveDealerAdmins(api.getDealerId()))
//       dispatch(addAlert({
//         id: Date.now(),
//         titleKey: 'sentAdminInvite',
//         type: EMessageType.success,
//         messageKeys: ['adminInviteHasBeenSentSuccessfully']
//       }))
//       return;
//     }, (err: any) => {
//       if (err.status === 401) {
//         dispatch(redirectToLogin())
//       } else {
//         dispatch(addAlert({
//           id: Date.now(),
//           titleKey: 'inviteAdminFail',
//           type: EMessageType.error,
//           messageKeys: [err.message || err]
//         }));
//         dispatch(createAdminInviteFailure())
//       }
//     });
//     // });
//   }
// }


// Create Admin Invite
const createDealerAdminInvitation = new ApiReduxAction({
  request: { type: adminActions.CREATE_ADMIN_INVITE_REQUEST },
  success: {
    type: adminActions.CREATE_ADMIN_INVITE_SUCCESS,
    title: 'adminInvitationSent',
    message: '_expectDelayReceivingInvite',
    after: (dux: IExposeRedux) => dux.dispatch(attemptRetrieveDealerAdmins(api.getDealerId()))
  },
  failure: {
    type: adminActions.CREATE_ADMIN_INVITE_FAILURE,
    title: 'inviteAdminFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux) => {
  const state = dux.getState();
  const dealerId = state.portal.activeDealer?.id;
  const inviteDetails: any = state.createAdmin.newInvite;

  // before we can send the request with the email we need to retrieve the appropriate roles for the admin
  // Need to query for dealer roles & roles for each desired organziation
  // then send off the request with the filled in body
  return (tracking: ITrackedRequest) => {
  
    const inviteBody: IUserInviteRequestC = {
      email: inviteDetails.email.value,
      organization_roles: inviteDetails.dealer_roles,
      dealer_roles: []
    };
  
    const orgRoleRequests: Array<Promise<INonPaginatedResponseC<IRoleC, RoleC>>> = [...inviteDetails.limitedOrgs.values()].map((org: OrganizationC): Promise<any> => {
      return api.role.getOrganizationRoles(org.id, tracking);
    })
    const dealerRolesRequest: Promise<INonPaginatedResponseC<IRoleC, RoleC>> = api.role.getDealerRoles(dealerId, tracking);
    // get appropriate dealer role
    return Promise.all([
      dealerRolesRequest,
      ...orgRoleRequests
    ]).then(([dealerRoles, ...rolesPerOrg] ) => {
  
      // setup the dealer_roles
      // find the dealer role with the name "Admins" and add its ID to the list of dealer_roles for the outgoing request
      dealerRoles.models.some((currDealerRole: RoleC) => {
        if (inviteDetails.isLimited && currDealerRole.name === 'Admins') {
          // if the user IS a limited admin, and the current role is the Admins role then add it and break out of loop
          inviteBody.dealer_roles = [currDealerRole.id];
          return true;
        } else if (!inviteDetails.isLimited && currDealerRole.name === 'Owners') {
          // if the user is not a limited admin, and the current role is the Owner role then add it and break out of loop
          inviteBody.dealer_roles = [currDealerRole.id];
          return true;
        }
        return false;
      });
  
      if (inviteDetails.isLimited) {
        // setup the organization_roles
        // loop through every organization and add their roles to a single organization_roles list for the outgoing request
        inviteBody.organization_roles = rolesPerOrg.reduce((rollupOrgRoles: string[], orgRoles: INonPaginatedResponseC<IRoleC, RoleC>) => {
          // add each role in the desired org to the total list of organization_roles
          orgRoles.models.forEach((role: RoleC) => {
            rollupOrgRoles.push(role.id);
          });
          return rollupOrgRoles;
        }, []);
      }
      return inviteBody;
    }).then((createdInviteBody: IUserInviteRequestC) => {
      return api.deal.createDealerAdminInvitation(dealerId, createdInviteBody, tracking);
    });
  }  
});
export const attemptCreateDealerAdminInvitation = createDealerAdminInvitation.go;


export interface IDeleteInviteActionParams {
  inviteId: string,
  dealerId: string
}
// new registration -- existing users -- allows more than one dealer
const deleteDealerAdminInvite = new ApiReduxAction({
  request: { type: adminActions.DELETE_ADMIN_INVITE_REQUEST },
  success: {
    type: adminActions.DELETE_ADMIN_INVITE_SUCCESS,
    title: 'successfullyDisabledInvite'
  },
  failure: {
    type: adminActions.DELETE_ADMIN_INVITE_FAILURE,
    title: 'disableAdminInviteFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, { dealerId, inviteId }: IDeleteInviteActionParams) => {
  return api.deal.deleteDealerAdminInvitation.bind(api.deal, dealerId, inviteId);
});
export const attemptDeleteDealerAdminInvite = deleteDealerAdminInvite.go;


export interface IRoleOrgPerm {
  roleId: string,
  org: OrganizationC,
}

export interface IAdminScopeC {
  limitedAdminOrgs?: OrganizationC[],
  currentRoleOrgsCombo?: IRoleOrgPerm[],
  isOwner: boolean
}

export function attemptRetrieveAdminScope(adminId: string): any {
  return (dispatch: Dispatch<any>, getState: any): Promise<void | IAdminScopeC> => {
    const dealerId = getState().portal.activeDealer?.id;
    dispatch(getAdminScopeRequest())
    const tracking = setupJobTrackingFor(adminActions.GET_ADMIN_SCOPE_REQUEST);
    return api.role.getUserRoles(dealerId, adminId, tracking).then((userRoles: INonPaginatedResponseC<IRoleC, RoleC>): Promise<void | IAdminScopeC> => {
      const adminScope: IAdminScopeC = {
        limitedAdminOrgs: [],
        currentRoleOrgsCombo: [],
        isOwner: false
      };
      const limitedOrgRequests: Array<Promise<IRoleOrgPerm>> = (userRoles.models as RoleC[]).reduce((orgRequests: Array<Promise<IRoleOrgPerm>>, role: RoleC) => {
        // if this role is an admin of an organization, retrieve the org details
        // note: dealer-admin comes back too, so skip that one
        if (role.name === 'Admins' && role.contextId !== dealerId) {
          const orgRoleBundleRequest = api.orgz.getOrg(role.contextId, tracking).then((org: OrganizationC): IRoleOrgPerm => {
            return {
              roleId: role.id,
              org
            };
          });
          orgRequests.push(orgRoleBundleRequest);
        } else if (role.name === 'Owners' && role.contextId === dealerId) {
          adminScope.isOwner = true;
        }
        return orgRequests;
      }, []);
      return Promise.all(limitedOrgRequests).then((roleOrgs: IRoleOrgPerm[]): IAdminScopeC => {
        const builtScope: IAdminScopeC = {
          limitedAdminOrgs: roleOrgs.map(rO => rO.org),
          currentRoleOrgsCombo: roleOrgs,
          isOwner: adminScope.isOwner
        };
        dispatch(getAdminScopeSuccess(builtScope));
        // sets a baseline for the currently set permissions on the user so we dont have to re-fetch it later
        dispatch(restoreInitialAdminPermissionsManager())
        return builtScope;
      });
    }, (err: any) => {

      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'retrieveAdminScopeFail',
          type: EMessageType.error,
          messageKeys: [err.message || err]
        }));
        dispatch(getAdminScopeFailure())
      }
    });
  }
}

export function attemptRetrieveAdminInvitationScope(invitationId: string): any {
  return (dispatch: Dispatch<any>, getState: any): Promise<void | IAdminScopeC> => {
    const dealerId = getState().portal.activeDealer?.id;
    dispatch(getAdminScopeRequest())
    const tracking = setupJobTrackingFor(adminActions.GET_ADMIN_SCOPE_REQUEST);
    return api.role.getUserInvitationRoles(dealerId, invitationId, tracking).then((userRoles: INonPaginatedResponseC<IRoleC, RoleC>): Promise<void | IAdminScopeC> => {
      const adminScope: IAdminScopeC = {
        limitedAdminOrgs: [],
        currentRoleOrgsCombo: [],
        isOwner: false
      };
      const limitedOrgRequests: Array<Promise<IRoleOrgPerm>> = (userRoles.models as RoleC[]).reduce((orgRequests: Array<Promise<IRoleOrgPerm>>, role: RoleC) => {
        // if this role is an admin of an organization, retrieve the org details
        // note: dealer-admin comes back too, so skip that one
        if (role.name === 'Admins' && role.contextId !== dealerId) {
          const orgRoleBundleRequest = api.orgz.getOrg(role.contextId, tracking).then((org: OrganizationC): IRoleOrgPerm => {
            return {
              roleId: role.id,
              org
            };
          });
          orgRequests.push(orgRoleBundleRequest);
        } else if (role.name === 'Owners' && role.contextId === dealerId) {
          adminScope.isOwner = true;
        }
        return orgRequests;
      }, []);
      return Promise.all(limitedOrgRequests).then((roleOrgs: IRoleOrgPerm[]): IAdminScopeC => {
        const builtScope: IAdminScopeC = {
          limitedAdminOrgs: roleOrgs.map(rO => rO.org),
          currentRoleOrgsCombo: roleOrgs,
          isOwner: adminScope.isOwner
        };
        dispatch(getAdminScopeSuccess(builtScope));
        // sets a baseline for the currently set permissions on the user so we dont have to re-fetch it later
        dispatch(restoreInitialAdminPermissionsManager())
        return builtScope;
      });
    }, (err: any) => {

      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'retrieveAdminScopeFail',
          type: EMessageType.error,
          messageKeys: [err.message || err]
        }));
        dispatch(getAdminScopeFailure())
      }
    });
  }
}


export function getAdminScopeRequest() {
  return {
    type: adminActions.GET_ADMIN_SCOPE_REQUEST
  }
}

export function getAdminScopeSuccess(scope: IAdminScopeC) {
  return {
    type: adminActions.GET_ADMIN_SCOPE_SUCCESS,
    ...scope
  }
}

export function getAdminScopeFailure() {
  return {
    type: adminActions.GET_ADMIN_SCOPE_FAILURE,
  }
}

export function toggleAdminInviteModal() {
  return {
    type: adminActions.TOGGLE_ADMIN_INVITE_MODAL
  }
}

export function togglePermissionManagerModal() {
  return {
    type: adminActions.TOGGLE_ADMIN_PERMISSIONS_MODAL
  }
}

export function handleAdminPermissionsChange(limited?: boolean, orgMap?: Map<string, OrganizationC>) {
  return {
    type: adminActions.HANDLE_ADMIN_PERMISSIONS_UPDATE,
    limitedOrgs: orgMap,
    isLimited: limited
  };
}

export function restoreInitialAdminPermissionsManager() {
  return (dispatch: Dispatch<any>, getState: any) => {
    const permissions = getState().adminDetails.adminPermissions;
    return dispatch({
      type: adminActions.RESTORE_INITIAL_ADMIN_PERMISSIONS_EDIT_STATE,
      currentRoleOrgsCombo: permissions.initialRoleOrgs,
      isOwner: permissions.isOwner,
      limitedAdminOrgMap: permissions.organizations.reduce((map: Map<string, OrganizationC>, org: OrganizationC) => {
        map.set(org.id, org);
        return map;
      }, new Map())
    });
  }
}

// When an owner wants to change a user's access level, they select/deselect a list of desired orgs
// in order to give the admin the desired permissions,
// we need to associated them to the roles cooresponding to the desired organizations the owner has selected
// The function below, adds and removes the admin from the appropriate roles
// to reconcile their roles with what the owner has designated for them.
export function attemptUpdateAdminPermissions(): any {
  return async (dispatch: Dispatch<any>, getState: any) => {

    const state = getState();
    const userId = state.adminDetails.adminData.id;
    const dealerId = state.portal.activeDealer?.id;
    const desiredPermissions = state.adminDetails.permissionsEditor;
    const existingRoles = desiredPermissions.initialRoleOrgs as IRoleOrgPerm[];
    const desiredOrgsMap = desiredPermissions.limitedOrgs as Map<string, OrganizationC>;
    const initiallyOwner = desiredPermissions.initialIsOwner;

    dispatch(updateAdminPermissionsRequest())

    const tracking = setupJobTrackingFor(adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST);
    const stopJobStatusCheck = checkJobStatusOnInterval(dispatch.bind(null, peekUpdateAdminPermissionsJobStatus()));
    try {
      // get the dealer roles
      const dealerRoles = await api.role.getDealerRoles(dealerId, tracking);
      // setup an interval to check for the job status

      var dealerOwnerRoleId: string;
      var dealerAdminRoleId: string;
      for (const role of dealerRoles.models) {
        // there's not a very sophisticated way of identifying the owner/admin role...
        if (role.name === 'Admins') {
          dealerAdminRoleId = role.id;
        }
        else if (role.name === 'Owners') {
          dealerOwnerRoleId = role.id;
        }
      }

      // reconcile the differences between permissions scope and requested permissions scope
      const roleRequests: Array<Promise<any>> = [];
      const existingRoleIdsSet = new Set<string>();
      if (desiredPermissions.isLimited) {
        // // console.log('rup: updating as limited admin')
        // going to be a limited admin
        if (initiallyOwner) {
          // if we're changing from an owner to a limited admin
          // we need to remove the dealer-owner role and add the dealer-admin role
          roleRequests.push(api.role.addUserToDealerRole(userId, dealerId, dealerAdminRoleId!, tracking));
          roleRequests.push(api.role.removeUserFromDealerRole(userId, dealerId, dealerOwnerRoleId!, tracking));
          // console.log(`rup: changing from an owner to a limited admin`);
        } else {
          // Need to reconcile the roles we should add/remove for this case.
          // EX: Admin of orgs [A,B] is getting moved to be an admin of orgs [A,C,D]
          // should add the role of orgs [C,D] and remove the roles of org [B]
          // REMOVE org-roles
          existingRoles.map(async (currExistingOrgRole: IRoleOrgPerm) => {
            existingRoleIdsSet.add(currExistingOrgRole.roleId);
            if (currExistingOrgRole.roleId !== dealerOwnerRoleId && currExistingOrgRole.roleId !== dealerAdminRoleId) {
              // if role does not existing in the desired, remove it
              if (!desiredOrgsMap.has(currExistingOrgRole.org.id)) {
                roleRequests.push(api.role.removeUserFromOrgRole(userId, currExistingOrgRole.org.id, currExistingOrgRole.roleId, tracking));
                // console.log(`rup: removing org role from user: \n[${currExistingOrgRole.org.name} | ${currExistingOrgRole.roleId}]`);
              }
            }
          });

          // ADD org-roles
          for (const [orgId, desiredOrg] of desiredOrgsMap) {
            // we might not know the role for the organization we're attempting to assocaite with the user,
            // so we first need to look it up
            const desiredOrgRoles: INonPaginatedResponseC<IRoleC, RoleC> = await api.role.getOrganizationRoles(desiredOrg.id, tracking);

            // desiredOrgRoles.models.forEach(async (desiredOrgRole: RoleC) => {
            for (const desiredOrgRole of desiredOrgRoles.models) {
              if (!existingRoleIdsSet.has(desiredOrgRole.id)) {
                roleRequests.push(api.role.addUserToOrgRole(userId, desiredOrg.id, desiredOrgRole.id, tracking));
              }
            }
          }
        }
      } else {
        // console.log('rup: updating as full admin')
        // going to be an owner
        if (!initiallyOwner) {
          // if we're changing from a limited admin to an owner
          // we need to remove the dealer-owner role and addd the dealer-admin role
          roleRequests.push(api.role.addUserToDealerRole(userId, dealerId, dealerOwnerRoleId!, tracking));
          roleRequests.push(api.role.removeUserFromDealerRole(userId, dealerId, dealerAdminRoleId!, tracking));
          // console.log(`rup: changing from a limited admin to an owner`);
        }
        // since the user will be an owner, they dont need individual organization roles. Delete them!
        // DELETE org-roles
        for (const currExistingOrgRole of existingRoles) {
          existingRoleIdsSet.add(currExistingOrgRole.roleId);
          if (currExistingOrgRole.roleId !== dealerOwnerRoleId! && currExistingOrgRole.roleId !== dealerAdminRoleId!) {
            // if role does not existing in the desired, remove it
            if (!desiredOrgsMap.has(currExistingOrgRole.org.id)) {
              roleRequests.push(api.role.removeUserFromOrgRole(userId, currExistingOrgRole.org.id, currExistingOrgRole.roleId, tracking));
              // console.log(`rup: removing org role from user: \n[${currExistingOrgRole.org.name} | ${currExistingOrgRole.roleId}]`);
            }
          }
        }
      }

      await Promise.all(roleRequests);

      // console.log(`rup: FINISHED EDITING`);
      dispatch(updateAdminPermissionsSuccess())
      dispatch(addAlert({
        id: Date.now(),
        titleKey: 'updatedPermissions',
        type: EMessageType.success,
        messageKeys: ['adminPermissionScopeUpdated']
      }))
    } catch(err: any) {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'updatePermissionsFailure',
          type: EMessageType.error,
          messageKeys: [err.message || err]
        }));
        dispatch(updateAdminPermissionsFailure())
      }
      
    } finally {
      stopJobStatusCheck()
    }
  }
}


export function updateAdminPermissionsRequest() {
  return {
    type: adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST
  }
}

export function updateAdminPermissionsSuccess() {
  return {
    type: adminActions.UPDATE_ADMIN_PERMISSIONS_SUCCESS,
  }
}

export function updateAdminPermissionsFailure() {
  return {
    type: adminActions.UPDATE_ADMIN_PERMISSIONS_FAILURE
  }
}

export function peekUpdateAdminPermissionsJobStatus() {
  return {
    type: adminActions.UPDATE_ADMIN_PERMISSIONS_JOB_STATUS
  }
}


// When an owner wants to change an admin invitation's access level, they select/deselect a list of desired orgs
// in order to give the admin the desired permissions,
// we need to associated them to the roles cooresponding to the desired organizations the owner has selected
// The function below, adds and removes the admin from the appropriate roles
// to reconcile their roles with what the owner has designated for them.
export function attemptUpdateAdminInvitationPermissions(): any {
  return async (dispatch: Dispatch<any>, getState: any) => {

    const state = getState();
    const invitationId = state.adminDetails.adminData.invitationId;
    const dealerId = state.portal.activeDealer?.id;
    const desiredPermissions = state.adminDetails.permissionsEditor;
    const existingRoles = desiredPermissions.initialRoleOrgs as IRoleOrgPerm[];
    const desiredOrgsMap = desiredPermissions.limitedOrgs as Map<string, OrganizationC>;
    const initiallyOwner = desiredPermissions.initialIsOwner;

    dispatch(updateAdminPermissionsRequest())

    const tracking = setupJobTrackingFor(adminActions.UPDATE_ADMIN_PERMISSIONS_REQUEST);
    const stopJobStatusCheck = checkJobStatusOnInterval(dispatch.bind(null, peekUpdateAdminPermissionsJobStatus()));
    try {
      // get the dealer roles
      const dealerRoles = await api.role.getDealerRoles(dealerId, tracking);
      // setup an interval to check for the job status

      var dealerOwnerRoleId: string;
      var dealerAdminRoleId: string;
      for (const role of dealerRoles.models) {
        // there's not a very sophisticated way of identifying the owner/admin role...
        if (role.name === 'Admins') {
          dealerAdminRoleId = role.id;
        }
        else if (role.name === 'Owners') {
          dealerOwnerRoleId = role.id;
        }
      }

      // reconcile the differences between permissions scope and requested permissions scope
      const roleRequests: Array<Promise<any>> = [];
      const existingRoleIdsSet = new Set<string>();
      if (desiredPermissions.isLimited) {
        // console.log('rup: updating as limited admin')
        // going to be a limited admin
        if (initiallyOwner) {
          // if we're changing from an owner to a limited admin
          // we need to remove the dealer-owner role and add the dealer-admin role
          roleRequests.push(api.role.addRolesToInvitation(dealerId, invitationId, [dealerAdminRoleId!], tracking));
          roleRequests.push(api.role.removeRolesFromInvitation(dealerId, invitationId, [dealerOwnerRoleId!], tracking));
          // console.log(`rup: changing from an owner to a limited admin`);
        } else {
          // Need to reconcile the roles we should add/remove for this case.
          // EX: Admin of orgs [A,B] is getting moved to be an admin of orgs [A,C,D]
          // should add the role of orgs [C,D] and remove the roles of org [B]
          // REMOVE org-roles
          existingRoles.map(async (currExistingOrgRole: IRoleOrgPerm) => {
            existingRoleIdsSet.add(currExistingOrgRole.roleId);
            if (currExistingOrgRole.roleId !== dealerOwnerRoleId && currExistingOrgRole.roleId !== dealerAdminRoleId) {
              // if role does not existing in the desired, remove it
              if (!desiredOrgsMap.has(currExistingOrgRole.org.id)) {
                roleRequests.push(api.role.removeRolesFromInvitation(dealerId, invitationId, [currExistingOrgRole.roleId], tracking));
                // console.log(`rup: removing org role from user: \n[${currExistingOrgRole.org.name} | ${currExistingOrgRole.roleId}]`);
              }
            }
          });

          // ADD org-roles
          for (const [orgId, desiredOrg] of desiredOrgsMap) {
            // we might not know the role for the organization we're attempting to assocaite with the user,
            // so we first need to look it up
            const desiredOrgRoles: INonPaginatedResponseC<IRoleC, RoleC> = await api.role.getOrganizationRoles(desiredOrg.id, tracking);

            // desiredOrgRoles.models.forEach(async (desiredOrgRole: RoleC) => {
            for (const desiredOrgRole of desiredOrgRoles.models) {
              if (!existingRoleIdsSet.has(desiredOrgRole.id)) {
                roleRequests.push(api.role.addRolesToInvitation(dealerId, invitationId, [desiredOrgRole.id], tracking));
              }
            }
          }
        }
      } else {
        // console.log('rup: updating as full admin')
        // going to be an owner
        if (!initiallyOwner) {
          // if we're changing from a limited admin to an owner
          // we need to remove the dealer-owner role and addd the dealer-admin role
          roleRequests.push(api.role.addRolesToInvitation(dealerId, invitationId, [dealerOwnerRoleId!], tracking));
          roleRequests.push(api.role.removeRolesFromInvitation(dealerId, invitationId, [dealerAdminRoleId!], tracking));
          // console.log(`rup: changing from a limited admin to an owner`);
        }
        // since the user will be an owner, they dont need individual organization roles. Delete them!
        // DELETE org-roles
        for (const currExistingOrgRole of existingRoles) {
          existingRoleIdsSet.add(currExistingOrgRole.roleId);
          if (currExistingOrgRole.roleId !== dealerOwnerRoleId! && currExistingOrgRole.roleId !== dealerAdminRoleId!) {
            // if role does not existing in the desired, remove it
            if (!desiredOrgsMap.has(currExistingOrgRole.org.id)) {
              roleRequests.push(api.role.removeRolesFromInvitation(dealerId, invitationId, [currExistingOrgRole.roleId], tracking));
              // console.log(`rup: removing org role from user: \n[${currExistingOrgRole.org.name} | ${currExistingOrgRole.roleId}]`);
            }
          }
        }
      }

      await Promise.all(roleRequests);

      // console.log(`rup: FINISHED EDITING`);
      dispatch(updateAdminPermissionsSuccess())
      dispatch(addAlert({
        id: Date.now(),
        titleKey: 'updatedPermissions',
        type: EMessageType.success,
        messageKeys: ['adminPermissionScopeUpdated']
      }))
    } catch(err: any) {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'updatePermissionsFailure',
          type: EMessageType.error,
          messageKeys: [err.message || err]
        }));
        dispatch(updateAdminPermissionsFailure())
      }
      
    } finally {
      stopJobStatusCheck()
    }
  }
}

const retrieveAdminInviteByToken = new ApiReduxAction({
  request: { type: adminActions.GET_ADMIN_INVITE_REQUEST },
  success: { type: adminActions.GET_ADMIN_INVITE_SUCCESS },
  failure: {
    type: adminActions.GET_ADMIN_INVITE_FAILURE,
    title: 'getAdminInviteFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, token: string) => {
  return api.user.getUserInvitationByToken.bind(api.user, token);
});
export const attemptRetrieveAdminInviteByToken = retrieveAdminInviteByToken.go;


const getAdminInviteById = new ApiReduxAction({
  request: { type: adminActions.GET_ADMIN_DETAILS_INVITE_REQUEST },
  success: { type: adminActions.GET_ADMIN_DETAILS_INVITE_SUCCESS },
  failure: {
    type: adminActions.GET_ADMIN_DETAILS_INVITE_FAILURE,
    title: 'getAdminInviteFail'
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, invitationId: string) => {
  const state = dux.getState();
  const dealerId: string = state.portal.activeDealer?.id;
  return api.user.getUserInvitationForDealerById.bind(api.user, dealerId, invitationId);
});
export const attemptGetAdminInviteById = getAdminInviteById.go;


const resendAdminInvite = new ApiReduxAction({
  request: { type: adminActions.RESEND_ADMIN_INVITE_REQUEST },
  success: { 
    type: adminActions.RESEND_ADMIN_INVITE_SUCCESS,
    title: 'resentInvite',
    message: 'inviteWasSuccessfullyResent'
  },
  failure: {
    type: adminActions.RESEND_ADMIN_INVITE_FAILURE,
    title: 'resendAdminInviteFail',
  },
  checkNumPendingReqs: api.pendingReqs.getNum
}, (dux: IExposeRedux, invitationId: string) => {
  return api.user.resendUserInvitation.bind(api.user, invitationId);
});
export const attemptResendAdminInvite = resendAdminInvite.go;