import { S10nModelC, ES10nModelType } from '@unikey/unikey-commons/release/comm'

import {
  api,
  subscriptionActions,
  inputInitializer,
} from '../../internal'

const initialSubscriptionInfoState: any = {
  loading: false,
  defaultType: undefined, // to be filled by api response
  modelLookup: new Map<ES10nModelType, S10nModelC>(),
  availableModels: []
};

export const subscriptionInfo = (state = initialSubscriptionInfoState, action: any) => {
  switch (action.type) {
    case subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS:
      return {
        ...state,
        defaultType: action.value.defaultModel,
        availableModels: action.value.availableModels,
        modelLookup: action.value.availableModels.reduce((lookupMap: Map<ES10nModelType, S10nModelC>, model: S10nModelC) => lookupMap.set(model.type, model), new Map<ES10nModelType, S10nModelC>()),
        loading: action.pending
      };
    case subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_FAILURE:
      return {
        ...state,
        ...initialSubscriptionInfoState,
        loading: api.pendingReqs.getNum(subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_REQUEST) > 0,
      }
    default:
      return state
  }
}