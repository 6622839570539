import { compose, createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { createHashHistory } from 'history'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

import { environment, partnerKey, cdpVersion, commonsVersion }  from '@alias-environment';

import {
  createRootReducer,
  appInsightsInstrumentationKey
} from '../../internal'

export const storeHistory = createHashHistory();
// application insights setup
const reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appInsightsInstrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: storeHistory }
    }
  }
});
appInsights.loadAppInsights();
// add common items to the 
const telemetryFeedbackInitializer = (envelope: any) => {
  envelope.data.environment = environment;
  envelope.data.partner = partnerKey;
  envelope.data.portalVersion = cdpVersion;
  envelope.data.commonsVersion = commonsVersion;
};
appInsights.addTelemetryInitializer(telemetryFeedbackInitializer);

// This allows us to use the redux chrome extension when we are in developemnt mode.
// Extendion found here: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
const composeEnhancers = environment === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export const configureStore = (preloadedState?: any) => {
  return createStore(
    createRootReducer(storeHistory),
    preloadedState,
    composeEnhancers(applyMiddleware(
      routerMiddleware(storeHistory),
      thunkMiddleware
      // ...others...
    ))
  )
}
// export const store  = configureStore();

export const wrapWithInsights = (withAITracking.bind(null, reactPlugin) as any);

