import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UniOverlapButton } from '@unikey/unikey-commons/release/comm';

import { PartnerCustomizations, IPartnerCustomizations } from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {

}

class NotFoundContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    if (this.props.render) {
      return this.props.render()
    }
    return (
      <section className='notFound-container'>
        Page Not Found
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {}
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotFoundContainer, { forwardRef: true }))
