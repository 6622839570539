import { Dispatch } from 'redux'

import {  
  EMessageType,
  IMultiInputUpdate
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  addAlert,
  wipeAllAlerts,
  clearApplicationState,
  decodeJWT,
  redirectToLogin,
  portalRedirect,
  // oops403Key
} from '../../internal'

export enum oidcActions {
  UPDATE_USERNAME = 'UPDATE_USERNAME',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',

  SET_ACTOR_TYPE = 'SET_ACTOR_TYPE'
}

export function oidcLoginRequest() {
  return {
    type: oidcActions.LOGIN_REQUEST
  }
}

export function oidcLoginSuccess(user: any, canUserCreateDealer: boolean) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(wipeAllAlerts())
    dispatch({
      type: oidcActions.LOGIN_SUCCESS,
      user,
      canUserCreateDealer
    })
  }
}

export function oidcRefreshSuccess(user: any) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    // same as login, but dont wipe alerts
    dispatch({
      type: oidcActions.LOGIN_SUCCESS,
      user
    })
  }
}

export function oidcLoginFailure(errMessage: string) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(addAlert({
      id: Date.now(),
      titleKey: 'loginFailure',
      type: EMessageType.error,
      messageKeys: [errMessage]
    }));
    dispatch(logoutRequest())
    dispatch({
      type: oidcActions.LOGIN_FAILURE,
      errMessage
    })
  }
}

export function logoutRequest() {
  return {
    type: oidcActions.LOGOUT_REQUEST
  }
}

export function logoutSuccess() {
  return {
    type: oidcActions.LOGOUT_SUCCESS
  }
}