import {
  ES10nModelType,
  Editable,
  DynamicSkinConfig
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  dealerActions,
  portalActions,
  updateScratchValues,
  inputInitializer
} from '../../internal'

const initialDealerDetailsState: any = {
  loading: false,
  addCreditsModalOpen: false,
  dealerData: {
    id: '',
    subscriptionModel: ES10nModelType.unknown,
    address: {
      country: '',
      locality: '',
      administrative_area: '',
      postal_code: '',
      address_lines: [{
        address: ''
      }]
    }
  },
  // dont initialize edit data so that we can extend correctly
  editData: {
    subscriptionModel: { value: ES10nModelType.unknown, valid: true }
  },
  scratch: {
    workflowStepIndex: 0,
    loading: false,
    modalOpen: false,
    ...inputInitializer('', ['serial', 'auth'])
  }
};

export const dealer = (state = initialDealerDetailsState, action: any) => {
  switch (action.type) {
    case dealerActions.GET_DEALER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        dealerData: action.value
      }
    case dealerActions.GET_DEALER_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        dealerData: {}
      }
    case dealerActions.TOGGLE_DEALER_CREDITS_MODAL:
      return {
        ...state,
        scratch: Object.assign(state.scratch, {
          modalOpen: !state.scratch.modalOpen
        })
      }
    case dealerActions.UPDATE_SCRATCH_VALUES:
      return {
        ...state,
        scratch: Object.assign(state.scratch, {
          serial: action.serial ? action.serial : state.scratch.serial,
          auth: action.auth ? action.auth : state.scratch.auth,
        })
      }
    case dealerActions.DEALER_REDEEM_SCRATCH_REQUEST:
      return {
        ...state,
        scratch: {
          ...state.scratch, 
          loading: true
        }
      }
    case dealerActions.DEALER_REDEEM_SCRATCH_SUCCESS:
      return {
        ...state,
        scratch: {
          ...state.scratch,
          loading: action.pending,
          modalOpen: false
        }
      }
    case dealerActions.DEALER_REDEEM_SCRATCH_FAILURE:
      return {
        ...state,
        scratch: {
          ...state.scratch,
          loading: action.pending
        }
      }
    case dealerActions.UPDATE_REDEEM_CREDITS_WORKFLOW_STEP:
      return {
        ...state,
        scratch: {
          ...state.scratch,
          workflowStepIndex: action.stepTo
        }
      }
    case dealerActions.UPDATE_DEALER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.UPDATE_DEALER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        updateData: action.value
      }
    case dealerActions.UPDATE_DEALER_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        updateData: {}
      }
    case dealerActions.HANDLE_DEALER_DETAILS_CHANGE:
      return {
        ...state,
        editData: {
          phone: action.phoneNumber ? action.phoneNumber : state.editData.phone,
          administrativeArea: action.administrativeArea ? action.administrativeArea : state.editData.administrativeArea,
          postalCode: action.postalCode ? action.postalCode : state.editData.postalCode,
          streetAddressL1: action.streetAddressL1 ? action.streetAddressL1 : state.editData.streetAddressL1,
          streetAddressL2: action.streetAddressL2 ? action.streetAddressL2 : state.editData.streetAddressL2,
          subscriptionModel: action.subscriptionModel ? action.subscriptionModel : state.editData.subscriptionModel,
        }
      }
    case portalActions.DEALER_CONTEXT_SET: 
      return {
        ...initialDealerDetailsState
      }
    default:
      return state
  }
}
  
const skinToForm = (skinConfig?: DynamicSkinConfig) => {
  return {
    primaryColor: new Editable({ value: skinConfig?.primaryColor }),
    secondaryColor: new Editable({ value: skinConfig?.secondaryColor }),
    logoUri: skinConfig?.logoUri,
    logo: new Editable({ value: '', valid: true }),
    darkModeLogoUri: skinConfig?.darkModeLogoUri,
    darkModeLogo: new Editable({ value: '', valid: true }),
  };
}

const initialDealerSkinState: any = {
  attempted: false,
  loading: false,
  saving: false,
  skin: {},
  form: skinToForm({}),
};

export const dealerSkin = (state = initialDealerSkinState, action: any) => {
  switch (action.type) {
    case dealerActions.GET_DEALER_SKIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALER_SKIN_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        attempted: true,
        skin: action.value,
        form: skinToForm(action.value)
      }
    case dealerActions.GET_DEALER_SKIN_FAILURE:
      return {
        ...state,
        loading: action.pending,
        attempted: true,
        skin: {}
      }
    case dealerActions.UPDATE_DEALER_SKIN_FORM:
      const logoToUse = action.logo ? action.logo : state.form.logo;
      return {
        ...state,
        form: {
          ...state.form,
          primaryColor: action.primaryColor ? action.primaryColor : state.form.primaryColor,
          secondaryColor: action.secondaryColor ? action.secondaryColor : state.form.secondaryColor,
          logo: logoToUse,
          darkModeLogo: (action.darkModeLogo ? action.darkModeLogo : state.form.darkModeLogo) ?? logoToUse,
        }
      }
    case dealerActions.UPDATE_DEALER_SKIN_REQUEST:
      return {
        ...state,
        saving: true
      }
    case dealerActions.UPDATE_DEALER_SKIN_SUCCESS:
      return {
        ...state,
        saving: api.pendingReqs.getNum(dealerActions.UPDATE_DEALER_SKIN_REQUEST) > 0,
        skin: action.value,
        form: skinToForm(action.value)
        
      }
    case dealerActions.UPDATE_DEALER_SKIN_FAILURE:
      return {
        ...state,
        saving: api.pendingReqs.getNum(dealerActions.UPDATE_DEALER_SKIN_REQUEST) > 0,
      }
    default:
      return state
  }
}